import React, { useContext, useEffect, useState } from "react";
import { TextField, Button, Avatar } from "@mui/material";
import { Card } from "../../../shares/card/Card";
import useFetch from "../../../../hooks/useFetch";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import Loading from "../../../../utils/Loading";
import { useForm } from "react-hook-form";

const Books = () => {
  const [books, setBooks] = useState("");

  const userId = Cookies.get("userId");

  const getBooks = useFetch({
    method: "GET",
    url: `management/users/${userId}/user_books/`,
    noHeader: false,
    trigger: true,
    setter: setBooks,
  });

  return (
    <div className="min-h-screen flex items-center justify-center w-full">
      <Card className="h-full w-full">
        <div className="flex flex-col w-full py-12 px-10 items-center gap-x-8"></div>
      </Card>
    </div>
  );
};

export default Books;
