import { ToastContainer, toast } from "react-toastify";
import { AuthProvider } from "./contexts/AuthContext";
import Router from "./routes/Router";
import "./styles/main.scss";
import { ThemeProvider, createTheme } from "@mui/material";
import MuiRTLThemeProvider from "./MUIThemProvider";
import ErrorBoundary from "./ErrorBoundary";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMomentJalaali } from "@mui/x-date-pickers/AdapterMomentJalaali";
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";

import { faIR } from "@mui/x-date-pickers/locales";

function App() {
  const THEME = createTheme({
    direction: "rtl",
    typography: {
      fontFamily: `"Sans-Web", "Sans-Mobile", "sans-serif"`,
      fontSize: 20,
    },
  });

  return (
    <>
      <MuiRTLThemeProvider theme={THEME}>
        <LocalizationProvider
          dateAdapter={AdapterDateFnsJalali}
          localeText={
            faIR.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          {/* <ErrorBoundary> */}
          <ToastContainer limit={1} />
          <AuthProvider>
            <div className="app">
              <Router basename />
            </div>
          </AuthProvider>
          {/* </ErrorBoundary> */}
        </LocalizationProvider>
      </MuiRTLThemeProvider>
    </>
  );
}

export default App;
