import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CloseSharp } from "@mui/icons-material";
import { BtnAlternative, BtnPrimary } from "../../../../shares/button/Button";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import useFetch from "../../../../../hooks/useFetch";
import { Input, FormControl, FormHelperText } from "@mui/material";
import moment from "jalali-moment";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

const AddVideos = ({ close, get, edit }) => {
  const [call, setCall] = useState({
    ExamName: "",
    exam_headline: "",
    exam_available_time_start: "",
    exam_available_time_end: "",
    exam_group: "",
    exam_maxenterance_time: "",
  });
  // زمان ورود مجاز آزمون
  const [groups, setGroups] = useState();
  const [gg, setGg] = useState();

  const newQuestion = useFetch({
    method: "POST",
    url: `management/exams/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });

  const editExam = useFetch({
    method: "PATCH",
    url: `management/exams/${edit}/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });

  const getExam = useFetch({
    method: "GET",
    url: `management/exams/${edit}/`,
    noHeader: false,
    trigger: edit ? true : false,
    setter: setCall,
  });

  const getGroup = useFetch({
    method: "GET",
    url: `management/question-banks/`,
    noHeader: false,
    trigger: true,
    setter: setGroups,
  });

  const getGg = useFetch({
    method: "GET",
    url: `management/groups/?page_size=1000`,
    noHeader: false,
    trigger: true,
    setter: setGg,
  });

  const questionRef = useRef(null);
  const answerRef = useRef(null);

  const handleClickQuestion = () => {
    questionRef.current.click();
  };
  const handleClickAnswer = () => {
    answerRef.current.click();
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: call,
  });

  const onSubmit = (e) => {
    const formData = new FormData();

    formData.append("ExamName", call.ExamName);
    formData.append("exam_headline", call.exam_headline);
    formData.append("exam_question_bank_id", call.exam_question_bank);
    formData.append("exam_group", call.exam_group);
    formData.append("exam_maxenterance_time", call.exam_maxenterance_time);
    formData.append(
      "exam_available_time_start",
      call.exam_available_time_start
    );
    formData.append("exam_available_time_end", call.exam_available_time_end);

    setCall(formData);

    if (edit) {
      editExam.reFetch();
    } else {
      newQuestion.reFetch();
    }
  };
  useEffect(() => {
    newQuestion.status === 201 && close();
  }, [newQuestion.status]);
  useEffect(() => {
    editExam.status === 200 && close();
  }, [editExam.status]);

  return (
    <div className="relative rounded-2xl w-2/5 bg-white max-h-[85vh] overflow-scroll">
      <CloseSharp
        className="absolute top-2 right-[6px] cursor-pointer"
        onClick={close}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pt-16 pb-10 px-14 text-xl"
      >
        <h6 className="font-extrabold text-2xl border-b-2 w-full pb-4 mb-6">
          ویرایش آزمون
        </h6>

        <div className="mb-6">
          <label htmlFor="ExamName" className="block mt-6 mb-1">
            عنوان
          </label>
          <TextField
            name="ExamName"
            value={call.question_headline}
            placeholder="عنوان"
            {...register("ExamName", {
              required: "عنوان را وارد کنید",
            })}
            error={!!errors.ExamName}
            helperText={errors.ExamName ? errors.ExamName.message : ""}
            onChange={(e) => {
              setCall({ ...call, ExamName: e.target.value });
            }}
            fullWidth
          />
        </div>

        <div className="mb-6">
          <label htmlFor="exam_headline" className="block mb-1">
            مبحث
          </label>
          <FormControl error={errors.exam_headline ? true : false} fullWidth>
            <Select
              value={call.exam_headline}
              {...register("exam_headline", {
                required: "مبحث را وارد کنید",
              })}
              fullWidth
              onChange={(e) => {
                setCall({ ...call, exam_headline: e.target.value });
              }}
            >
              {lessons.map((lesson) => {
                return <MenuItem value={lesson}>{lesson}</MenuItem>;
              })}
            </Select>
            <FormHelperText>{errors?.exam_headline?.message}</FormHelperText>
          </FormControl>
        </div>
        <div className="mb-6">
          <label htmlFor="exam_group" className="block mb-1">
            گروه
          </label>
          <FormControl error={errors.exam_group ? true : false} fullWidth>
            <Select
              value={call.exam_group}
              {...register("exam_group", {
                required: "مبحث را وارد کنید",
              })}
              fullWidth
              onChange={(e) => {
                setCall({ ...call, exam_group: e.target.value });
              }}
            >
              {gg?.results.map((g) => {
                return <MenuItem value={g.id}>{g.name}</MenuItem>;
              })}
            </Select>
            <FormHelperText>{errors?.exam_group?.message}</FormHelperText>
          </FormControl>
        </div>
        <div className="mb-6">
          <label htmlFor="start-day" className="block mb-2">
            روز شروع
          </label>
          <FormControl
            error={errors.exam_available_time_start ? true : false}
            fullWidth
          >
            <Controller
              name="start-day"
              control={control}
              render={() => (
                <MobileDateTimePicker
                  label={
                    call?.exam_available_time_start
                      ? moment(
                          call.exam_available_time_start,
                          "YYYY/MM/DD HH:mm"
                        )
                          .locale("fa")
                          .format("YYYY/MM/DD HH:mm")
                      : "روز شروع"
                  }
                  onAccept={(e) =>
                    setCall({
                      ...call,
                      exam_available_time_start:
                        moment(e).format("YYYY-MM-DD HH:mm"),
                    })
                  }
                  // onChange={}
                  views={["year", "month", "day", "hours", "minutes"]}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  className="w-full"
                  ampm={false}
                  slotProps={{
                    field: { shouldRespectLeadingZeros: true },
                    toolbar: { hidden: true },
                    tabs: { hidden: true },
                  }}
                  sx={{
                    "& .PrivatePickersClockNumber-clockNumber": {
                      fontSize: 50,
                    },
                  }}
                />
              )}
            />
            <FormHelperText>
              {errors?.assignment_available_time_start?.message}
            </FormHelperText>
          </FormControl>
        </div>
        <div className="mb-6">
          <label htmlFor="class" className="block mb-1">
            پایان
          </label>
          <FormControl
            error={errors.exam_available_time_end ? true : false}
            fullWidth
          >
            <Controller
              name="end-day"
              control={control}
              render={() => (
                <MobileDateTimePicker
                  label={
                    call?.exam_available_time_end
                      ? moment(call.exam_available_time_end, "YYYY/MM/DD HH:mm")
                          .locale("fa")
                          .format("YYYY/MM/DD HH:mm")
                      : "روز پایان"
                  }
                  onAccept={(e) =>
                    setCall({
                      ...call,
                      exam_available_time_end:
                        moment(e).format("YYYY-MM-DD HH:mm"),
                    })
                  }
                  // onChange={}
                  views={["year", "month", "day", "hours", "minutes"]}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  className="w-full"
                  ampm={false}
                  slotProps={{
                    field: { shouldRespectLeadingZeros: true },
                    toolbar: { hidden: true },
                    tabs: { hidden: true },
                  }}
                  sx={{
                    "& .PrivatePickersClockNumber-clockNumber": {
                      fontSize: 50,
                    },
                  }}
                />
              )}
            />
            <FormHelperText>
              {errors?.assignment_available_time_start?.message}
            </FormHelperText>
          </FormControl>
        </div>
        <div className="mb-6">
          <label htmlFor="exam_maxenterance_time" className="block mb-1">
            زمان ورود مجاز آزمون
          </label>
          <FormControl
            error={errors.exam_maxenterance_time ? true : false}
            fullWidth
          >
            <Controller
              name="max-day"
              control={control}
              render={() => (
                <MobileDateTimePicker
                  label={
                    call?.exam_maxenterance_time
                      ? moment(call.exam_maxenterance_time, "YYYY/MM/DD HH:mm")
                          .locale("fa")
                          .format("YYYY/MM/DD HH:mm")
                      : "زمان ورود مجاز آزمون"
                  }
                  onAccept={(e) =>
                    setCall({
                      ...call,
                      exam_maxenterance_time:
                        moment(e).format("YYYY-MM-DD HH:mm"),
                    })
                  }
                  // onChange={}
                  views={["year", "month", "day", "hours", "minutes"]}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  className="w-full"
                  ampm={false}
                  slotProps={{
                    field: { shouldRespectLeadingZeros: true },
                    toolbar: { hidden: true },
                    tabs: { hidden: true },
                  }}
                  sx={{
                    "& .PrivatePickersClockNumber-clockNumber": {
                      fontSize: 50,
                    },
                  }}
                />
              )}
            />
            <FormHelperText>
              {errors?.exam_maxenterance_time?.message}
            </FormHelperText>
          </FormControl>
        </div>

        <div className="mr-auto ml-0 w-fit">
          <button
            type="submit"
            className="text-white !bg-[#8200d3] hover:bg-[#8200d3] transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 "
          >
            ثبت
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddVideos;

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

const lessons = [
  "توابع و خواص آن ها",
  "معادله درجه دوم",
  "الگو و دنباله",
  "مثلثات",
  "توان های گویا و عبارات جبری",
  "مرور توابع و خواص آن ها",
  "آنالیز",
  "احتمالات",
  "هندسه مختصاتی",
  "معادلات و نامعادلات گویا و گنگ",
  "قدر مطلق",
  "براکت",
  "هندسه",
  "توابع نمایی و لگاریتم",
  "حد",
  "پیوستگی",
  "بخش پذیری",
  "مشتق",
  "کاربرد مشتق",
  "مجانب ها",
  "مقاطع مخروطی",
];
