import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import useFetch from "../../../hooks/useFetch";
import { SearchOutlined } from "@mui/icons-material";

const Search = ({ newData, url }) => {
  const [filter, setFilter] = useState("");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const getFilteredGroup = useFetch({
    method: "GET",
    url: `management/${url}/?search=${filter}`,
    noHeader: false,
    trigger: false,
    setter: newData,
  });

  const handleNewData = () => {
    getFilteredGroup.reFetch();
  };

  return (
    <form onSubmit={handleSubmit(handleNewData)} className="flex items-center">
      <TextField
        id="outlined-search"
        label="جستجو"
        type="search"
        onChange={(e) => setFilter(e.target.value)}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            "&:hover": {
              "& fieldset": {
                borderColor: "#8200d3",
              },
            },
            "&.Mui-focused": {
              "& fieldset": {
                borderColor: "#8200d3",
              },
            },
          },
          "& label.Mui-focused": {
            color: "#8200d3",
          },
        }}
      />
      <button
        type="submit"
        className={`text-white !bg-[#8200d3] hover:bg-[#8200d3] transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-l-[1.2rem] w-fit py-[1.62rem] px-8  `}
      >
        <SearchOutlined />
      </button>
    </form>
  );
};

export default Search;
