import React from "react";

function Card({ link, ...props }) {
  return (
    <div
      className={`card bg-white text-center rounded-3xl  shadow-card  ${props.className}`}
    >
      {props.children}
    </div>
  );
}

export { Card };
//pt-[2.4rem] pb-[1.6rem]
function ShortInfoCard({ firstTxt, secondTxt, color, ...props }) {
  return (
    <div
      className={`flex flex-row bg-white rounded-[8px] py-[1.5rem] items-center justify-center shadow-card ${props.className}`}
    >
      <div className=" px-[2.5rem] ">
        <p>{firstTxt}</p>
      </div>
      {props.children}
      <div className="w-[1px] h-[2rem] bg-[#A5B0C4] "></div>
      <div className="flex flex-row px-[2.5rem]">
        <p className={`${color}`}>{secondTxt}</p>
      </div>
    </div>
  );
}

export { ShortInfoCard };
