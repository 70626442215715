import React from "react";
import Courses from "../../components/layouts/home/courses/Courses";
import Features from "../../components/layouts/home/features/Features";
import Footer from "../../components/layouts/home/footer/Footer";
import Header from "../../components/layouts/home/header/Header";
import Navbar from "../../components/layouts/home/navbar/Navbar";

function Home() {
  return (
    <div className="sm:mt-24 mt-12">
      <Navbar />
      <div id="header">
        <Header />
      </div>
      <div className="features" id="features">
        <Features />
      </div>
      <div id="courses">
        <Courses />
      </div>
      <div className="bg-[#2C3146]" id="footer">
        <Footer />
      </div>
    </div>
  );
}

export default Home;
