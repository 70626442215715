import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material";

const MuiRTLThemeProvider = ({ children }) => {
  const theme = React.useMemo(
    () =>
      createTheme({
        direction: "rtl",
        typography: {
          fontFamily: `"Sans-Web", "Sans-Mobile", "sans-serif"`,
          fontSize: 20,
        },
        components: {
          MuiTextField: {
            styleOverrides: {
              root: {
                "& label": {
                  left: "unset",
                  right: "2.8rem",
                  transformOrigin: "right",
                },
                "& legend": {
                  textAlign: "right",
                  // fontSize: "0.6rem",
                },
              },
            },
          },
        },
      }),
    []
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiRTLThemeProvider;
