import React, { useState, useRef, useEffect } from "react";
import { BtnAlternative, BtnPrimary } from "../../../../shares/button/Button";
import { Card } from "../../../../shares/card/Card";
import doc from "../../../../../assets/img/icons/Iconly-Light-Paper.svg";
import moment from "jalali-moment";
import { timeDifference } from "../../../../../utils/timeDifference";
import CheckIcon from "@mui/icons-material/Check";
import useFetch from "../../../../../hooks/useFetch";
import { toast } from "react-toastify";

const CurrentHW = ({ assignmentInfo, examsFetchData }) => {
  const [file, setFile] = useState(null);
  const [call, setCall] = useState(null);
  const [id, setId] = useState(null);

  const inputRef = useRef(null);

  const handleClick = (assignment) => {
    setId(assignment);

    inputRef.current.click();
  };

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
    // setId(id);
  };

  const upload = useFetch({
    method: "POST",
    url: `assignment-platform/${id}/upload/`,
    noHeader: false,
    trigger: false,
    data: call,
  });

  useEffect(() => {
    const formData = new FormData();

    formData.append("assignment_file", file);
    setCall(formData);
    file && upload.reFetch();
  }, [file]);

  useEffect(() => {
    if (upload.status === 204) {
      toast.success("پاسخ تکلیف ارسال شد.", {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        rtl: true,
      });
      setFile(null);
      examsFetchData();
    }
  }, [upload.status]);

  useEffect(() => {
    if (upload.error && upload.error.response.status === 400) {
      toast.error(upload.error.response.data.message, {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        rtl: true,
      });
      setFile(null);
    }
  }, [upload.error]);

  return (
    <>
      {assignmentInfo?.map((x, index) =>
        timeDifference(x.assignment_available_time_end, Date.now()) >= 0 ? (
          <Card className="grid grid-cols-8 mb-5 md:grid-cols-10 justify-between items-center">
            <div className="h-full w-full justify-center items-center border-l-2 border-inherit hidden md:flex">
              <h1
                className={`text-[2.4rem] text-[#ef4444] ${
                  x?.assignment_student_file && "cursor-pointer !text-blue"
                }`}
                onClick={() =>
                  x?.assignment_student_file &&
                  (window.location.href = `https://lab.mindescape.co/${x.assignment_student_file}`)
                }
              >
                {index + 1}
              </h1>
            </div>
            <div className="text-start col-span-3 md:col-span-4 pt-9 pb-8 pr-4 md:pr-12">
              <h4 className="text-[1.6rem] flex">
                {`${x.AssignmentName}`}
                <span className=" text-[1.2rem] px-[1.5rem] opacity-50">
                  {moment(x.assignment_available_time_end, "YYYY/MM/DD")
                    .locale("fa")
                    .format("DD MMMM YYYY")}
                </span>
              </h4>
              <p className="text-[1.4rem] pt-[.6rem] opacity-50 ">
                {" "}
                {x.assignment_headline}{" "}
              </p>
            </div>
            <div className="text-[1.4rem] flex flex-row justify-between items-center col-span-5 pl-4 md:pl-12">
              <p className=" px-[1.45rem] border-inherit text-blue ">
                {timeDifference(x.assignment_available_time_end, Date.now())}{" "}
                روز{" "}
                <span className="  opacity-50 text-black ">
                  فرصت باقی مانده
                </span>
              </p>

              <div className="flex">
                <BtnAlternative
                  url={`https://lab.mindescape.co/${x.assignment_file}`}
                  download
                  className="border-[#EAEBED] border-2 py-[1.25rem] px-6 md:px-10 lg:px-[3.3rem] ml-[1.78rem]"
                  text={"تکلیف"}
                  icon={doc}
                />
                <BtnPrimary
                  className="py-[1.2rem] px-4 md:px-7 lg:px-[2.2rem] "
                  text={upload.loading ? "لطفا صبر کنید" : "ارسال پاسخ"}
                  onClick={() => handleClick(x.score.assignment)}
                />
                <input
                  type="file"
                  ref={inputRef}
                  onChange={(e) => handleFileInput(e)}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </Card>
        ) : (
          ""
        )
      )}
    </>
  );
};

export default CurrentHW;
