import React, { useEffect, useState } from "react";
import { ShortInfoCard } from "../../../shares/card/Card";
import profile from "../../../../assets/img/prof.png";
import useFetch from "../../../../hooks/useFetch";
import { toast } from "react-toastify";
import Loading from "../../../../utils/Loading";
import { useSession } from "../../../../contexts/SessionContext";
import Cookies from "js-cookie";

function Header() {
  const [user, setUser] = useState();
  const [studentPanel, setStudentPanel] = useState();

  const panel = useFetch({
    method: "GET",
    url: `student-panel/`,
    noHeader: false,
    trigger: true,
    setter: setStudentPanel,
    errMessage: () =>
      toast.error("مشکلی در دریافت اطلاعات دانش آموز پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
  });
  const login = useFetch({
    method: "GET",
    url: `dashboard/userinfo-box`,
    noHeader: false,
    trigger: true,
    setter: setUser,
    errMessage: () =>
      toast.error("مشکلی در دریافت اطلاعات یوزر پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
  });
  useEffect(() => {
    Cookies.set("userId", studentPanel?.id);
  }, [studentPanel]);

  return (
    <>
      {panel.loading ? (
        <Loading className="pt-10" />
      ) : (
        <header className="header flex flex-row max-md:flex-col max-md:justify-center justify-between pt-[2.45rem]">
          <div className="date-container max-md:hidden">
            {user ? (
              <ShortInfoCard
                firstTxt={
                  user || user.group_day === 0
                    ? "شنبه"
                    : (user.group_day = !0
                        ? `${user.group_day} شنبه`
                        : user.group_day === 6
                        ? "جمعه"
                        : "")
                }
                secondTxt={user?.group_time}
              />
            ) : (
              ""
            )}
          </div>
          <div className="profile-container text-center flex flex-col items-center gap-y-4">
            <div className="profile-img ">
              <img
                src={`https://lab.mindescape.co/${studentPanel?.studentuser?.avatar}`}
                className="w-36 rounded-full border-2 border-blue p-1 aspect-square"
              />
            </div>
            <div className="profile-name text-blue">
              سلام {`${studentPanel?.first_name} ${studentPanel?.last_name}`}
            </div>
            <h1 className="text-[2.4rem] pt-[12px]">
              امیدوارم روز خوبی داشته باشی
            </h1>
            <p className="opacity-40 text-[1.4rem] pt-[16px]">
              {" "}
              برای دسترسی آسونتر اینجا یه گزارش کلی از عملکرد داریم .
            </p>
          </div>
          <div className="class-container max-md:flex max-md:flex-row justify-around ">
            <p className="md:hidden pt-5 opacity-40">{`
            ${
              studentPanel?.studentuser?.student_grade
                ? studentPanel?.studentuser?.student_grade
                : ""
            }
               ${
                 studentPanel?.studentuser?.student_type
                   ? studentPanel.studentuser?.student_type
                   : ""
               } ${
              studentPanel?.studentuser?.student_gnum
                ? studentPanel.studentuser?.student_gnum
                : ""
            }
            `}</p>
            <p className="md:hidden pt-5 opacity-40">حضوری</p>
            <ShortInfoCard
              className="max-md:hidden"
              firstTxt={`
            ${
              studentPanel?.studentuser?.student_grade
                ? studentPanel.studentuser?.student_grade
                : ""
            }
               ${
                 studentPanel?.studentuser?.student_type
                   ? studentPanel.studentuser?.student_type
                   : ""
               } ${
                studentPanel?.studentuser?.student_gnum
                  ? studentPanel.studentuser?.student_gnum
                  : ""
              }
            `}
              secondTxt={" حضوری"}
            />
          </div>
        </header>
      )}
    </>
  );
}

export default Header;
