import React, { useState, useEffect } from "react";
import HeaderImg from "../../../../assets/img/attarImg.jpg";
import { BtnPrimary, BtnAlternative } from "../../../shares/button/Button";
import Celebration from "../../../../assets/img/celebrate-emoji.png";
import Heart from "../../../../assets/img/hearteye-emoji.png";
import { ReactComponent as Lines } from "../../../../assets/img/triple-line.svg";
import { ReactComponent as Pointer } from "../../../../assets/img/curly-pointer.svg";
import Bar from "../../../../assets/img/bar.png";
import { Modal, TextField } from "@mui/material";
import Login from "./Login";
import { useAuth } from "../../../../contexts/AuthContext";
import { Link } from "react-router-dom";
import Register from "./Register";
import Cookies from "js-cookie";

const Header = () => {
  const [register, setRegister] = useState(false);
  const [login, setLogin] = useState(false);
  const admin = Cookies.get("memo_cache");
  const userToken = Cookies.get("token");
  console.log(admin);

  const handleClose = () => {
    setRegister(false);
    setLogin(false);
  };

  const { isLogin, isAdmin } = useAuth();

  useEffect(() => {
    if (isLogin) {
      setRegister(false);
      setLogin(false);
    }
  }, [isLogin]);

  return (
    <header className="flex md:flex-row flex-col-reverse justify-center items-center gap-x-44 mb-40 container mt-16">
      <div className="flex flex-col md:block items-center text-center sm:text-right px-4 md:px-0">
        <h1 className="text-center md:text-right text-[5.2rem] mb-14 ">
          <span className="text-blue">ریاضیات</span> کنکور
          <span className="absolute hidden sm:inline-block">
            <Lines />
          </span>
        </h1>
        <p className=" mb-16 text-[1.8rem] text-justify">
          ریاضی کنکور با سرفصل‏ هایی چون تابع، مثلثات، حد، مشتق و ... شناخته می
          ‏شود. شاید ریاضی بیش از هر درس دیگری نیازمند عمیق شدن و درک ‏کردن دقیق
          مفاهیم باشد. در حقیقت اگر ریاضی خوب فهمیده شود، روند حل یک مسئله
          معمولاً آسان‏ تر از آن خواهد بود که نیاز داشته باشیم درگیر انواع روش‏
          های تستی و راه ‏های میان ‏بر شویم.
          <br />
          <br />
          با یک نگاه واقع ‏بینانه باید گفت، مهم ‏تر از هر کتاب و کلاس و مؤلف و
          معلمی، این «تلاش» یک دانش‏ آموز است که موفقیت نهایی وی را در ریاضی -و
          البته سایر دروس- حاصل می ‏کند.
        </p>
        {userToken ? (
          <Link to={admin === "true" ? "/admin/groups" : "/dashboard/home"}>
            <BtnPrimary
              text={admin === "true" ? "پنل ادمین" : "پنل دانش آموز"}
              className=" px-10 py-4"
            />
          </Link>
        ) : (
          <div className="flex gap-x-12">
            <BtnPrimary
              text="ورود به سامانه"
              className=" px-10 py-4"
              onClick={() => setLogin(true)}
            />
          </div>
        )}
      </div>

      <img
        src={HeaderImg}
        className="absolute -z-10 md:z-0 transition-all md:relative opacity-25 md:opacity-100 w-5/12 rounded-full aspect-square md:aspect-auto hidden sm:inline-block md:rounded-tl-[5px] md:rounded-br-[5px] md:rounded-tr-[50px] md:rounded-bl-[50px] md:hover:border-8 md:hover:border-blue"
      />
      {/* <span className="absolute left-[60rem] bottom-32 hidden lg:block">
                <Pointer />
            </span> */}

      {/* <div className="cursor-pointer rounded-r-[4.5rem] pr-12 pl-11 py-9 items-center absolute bg-white shadow-headerCard left-0 top-[27rem] hidden lg:flex">
                <img src={Celebration} className="ml-6" />
                <span className="font-bold text-[1.2rem]">
                    80% تخفیف تا فردا!
                </span>
            </div> */}
      {/* <div className="rounded-[4.5rem] hidden lg:flex pr-12 pl-11 py-9 items-center absolute bg-white shadow-headerCard top-[45rem] left-[51.5rem]">
                <img src={Heart} />
                <div className="mr-6">
                    <span className="font-bold text-[1.2rem] mb-2">
                        میزان رضایت شما
                    </span>
                    <img src={Bar} />
                </div>
            </div> */}
      {/* <Modal></Modal> */}
      <Modal open={login} onClose={handleClose}>
        <div className="w-full h-full">
          <Login close={handleClose} />
        </div>
      </Modal>
      <Modal open={register} onClose={handleClose}>
        <div className="w-full h-full">
          <Register close={handleClose} />
        </div>
      </Modal>
    </header>
  );
};

export default Header;
