import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./../layout/dashboard/Dashboard";
import Index from "./../components/layouts/dashboard/homeDash/Index";
import ExamDash from "./../components/layouts/dashboard/examDash/Index";
import ClassDash from "../components/layouts/dashboard/ClassesDash/Index";
import Session from "../components/layouts/dashboard/sessionDash/Session";
import HomeWork from "../components/layouts/dashboard/HWDash/Index";
import Exam from "../components/layouts/dashboard/examDash/Exam";
import ClassesList from "./ClassesList";
import DashProfile from "../components/layouts/dashboard/profile/Profile";
import NotFoundPage from "./NotFoundPage";
import Rules from "../components/layouts/dashboard/rules/rules";
import Books from "../components/layouts/dashboard/books/Books";

function UserRouter(props) {
  return (
    <Routes>
      <Route element={<Dashboard />}>
        <Route key={""} path="/home" element={<Index />} />
        <Route key={"exams"} path="/exams" element={<ExamDash />} />
        <Route key={"classes"} path="/classes" element={<ClassDash />} />
        <Route key={"Books"} path="/books" element={<Books />} />
        <Route key={"homework"} path="/homework" element={<HomeWork />} />
        <Route key={"rules"} path="/rules" element={<Rules />} />
        <Route
          key={"classes/:Id/"}
          path="/classes/:Id"
          element={<ClassesList />}
        />
        <Route
          key={`classes/:Id/:Id`}
          path="/classes/:Id/:Id"
          element={<Session />}
        />
      </Route>
      <Route key={"/exam/:Id"} path="/exam/:Id" element={<Exam />} />
      <Route key={"*"} path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default UserRouter;
