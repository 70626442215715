import React from "react";
import Play from "../../../../assets/img/Play.png";
import { Card } from "../../../shares/card/Card";
import { ReactComponent as Line } from "../../../../assets/img/triple-blue-line.svg";
import { ReactComponent as CurlyLine } from "../../../../assets/img/curly-line.svg";

const Courses = () => {
  return (
    <div className="container flex flex-col justify-center items-center mb-[16.2rem]">
      <img src={Play} className="mb-10" />
      <h3 className="text-[4.8rem] font-bold mb-8 relative text-center md:text-right">
        دوره‌های آموزشی
        <span className="absolute -bottom-2 -right-14 hidden md:inline-block">
          <Line />
        </span>
      </h3>
      <p className="text-[1.7rem] font-light text-center text-[#8E9BA9] mb-24 px-4 md:px-0">
        با آغاز همه ‏گیری ویروس کرونا، نقش اینترنت و فضای مجازی در آموزش پررنگ
        ‏تر شد. گرچه پیش از این نیز بسیاری محافل آکادمیک به ‏سوی مجازی‏ شدن حرکت
        کرده بودند، اما بیماری اخیر سرعت این روند را دوچندان کرد. آموزش مجازی
        گاه شالودۀ اصلی کار ما را تشکیل می ‏دهد و گاه به ‏عنوان مکمل در کنار
        آموزش حضوری عمل می ‏نماید. گرچه مزایای آموزش مجازی بر کسی پوشیده نیست،
        اما این موضوع نباید ما را از آسیب ‏شناسی آن غافل کند. دانش ‏آموز در
        آموزش مجازی (اعم از آنلاین و آفلاین) نقش فعالانه تری ایفا می‏کند. آنچه
        نتیجۀ نهایی کار را می ‏سازد، برنامه ‏ریزی صحیح و میزان اهمیتی است که شخص
        دانش‏ آموز برای درس قائل می ‏شود. آموزش حضوری ارتباط بهتر و صمیمانه ‏تری
        میان دانش ‏آموز و آموزگار برقرار می‏سازد. آموزش مجازی (به ‏ویژه نوع
        آفلاین) از این ویژگی بی ‏بهره است اما در عوض این مزیت را دارد که
        تکرارپذیر است و دانش‏ آموز می تواند یک فیلم آموزشی را چندین بار ببیند.
        این خیر می تواند خود منشاء شر شود! ببینید بچه ‏ها! اگر شما مثلاً هفته
        ‏ای 2 ساعت فیلم ریاضی می بینید (یا 2 ساعت در کلاس آنلاین ریاضی شرکت می
        ‏کنید)، حداقل باید 2 ساعت دیگر را نیز صرف مطالعه و تست ‏زنی کنید. گاهی
        برخی از شما از این گله دارید که در حل سؤالات امتحان‏ ها دچار بی‏ دقتی
        می‏شوید. غالب اوقات این بی ‏دقتی نیست، بلکه کمبود تسلط است و علتش هم
        اینست که خودتان در خانه به اندازۀ کافی مسئله حل نکرده ‏اید. بگذارید یک
        مثالی بزنم ... اگر در امتحان در حل یک معادله حین جذرگرفتن از طرفین،
        علامت مثبت/منفی را فراموش کردید، نام این را «بی ‏دقتی» نگذارید؛ شما
        «تسلط کافی نداشته‏ اید» که چنین شده است. راه‏ حل این مشکل هم بسیار ساده
        است. باید بیشتر تست بزنید و بیشتر خود را با حل مسئله درگیر کنید. اگر به
        ‏صرف فیلم ‏دیدن و نقش منفعلانۀ خود اکتفا کنید، هرگز نمی توانید به همۀ
        زیر و زبر کار، آنچنان که باید و شاید، مسلط شوید. اگر بخواهم مفهوم صحبتم
        را خلاصه کنم، باید بگویم دوران اجبار و آموزش معلم ‏محور گذشته است. در
        زمانۀ فعلی نقش شما دانش ‏آموزان در یادگیری خودتان بسیار پررنگ‏ تر شده
        است و به ‏همین‏ دلیل باید بتوانید با برنامه‏ ریزی و صرف وقت کافی در
        راستای رسیدن به اهدافتان فعالانه تلاش کنید. همانطور که در بالا گفتم، این
        امر به‏ ویژه در مورد درس ریاضی اهمیت وافری دارد و از این حیث تفاوتی نیز
        میان دانش ‏آموزان رشتۀ تجربی با رشتۀ ریاضی نیست. سرفصل‏ های مشترک را هر
        دو دسته باید به یک اندازه بلد باشند. در پایان بگذارید یک توصیۀ دیگر هم
        بکنم. راه موفقیت یک شبه طی نمی‏ شود. «فلانی 3 ماه مانده به کنکور شروع
        کرد و آخر سر بهترین رشته را قبول شد» یک افسانه است. «با این نکات می
        توانید ظرف 25 ثانیه همۀ سؤالات ریاضی کنکور را حل کنید» یک افسانۀ دیگر
        است. باید واقع ‏بین باشید، مستمراً تلاش کنید و اصل مطلب را -چه در ریاضی
        و چه در دروس دیگر- یاد بگیرید. وقتی اصل مطلب را یاد گرفتید، راه های
        کوتاه و آسان را هم یاد خواهید گرفت.
      </p>
      <div className="flex gap-x-6 flex-col md:flex-row gap-y-8">
        <Card className="text-right pt-16 pb-[3.2rem] px-[4.4rem] md:w-1/3 h-fit relative hover:shadow-2xl transition-shadow hover:-mt-1">
          <h4 className="font-bold text-[2.4rem]">ریاضیات دهم</h4>
          <p className="text-[1.6rem] text-[#8E9BA9] font-light">
            سرفصل ‏های تدریس ‏شده برای دانش‏ آموزان پایۀ دهم منطبق با کتاب ریاضی
            (1) است و از این حیث تفاوتی میان دانش‏ آموزان رشتۀ تجربی و ریاضی
            نیست. تلاش شده مباحث پیش‏ نیاز از سال‏های قبل (دورۀ متوسطۀ اول) تا
            حد امکان در تدریس گنجانده شود تا دانش ‏آموزان بتوانند آسان‏تر با
            ریاضیات کنکور ارتباط گیرند. ترتیب سرفصل‏ها به‏ گونه ‏ای انتخاب شده
            که آموزش بهتری صورت گیرد و فهم مطالب ساده‏ تر گردد.
          </p>
          <hr className="my-12" />

          <span className="text-[1.6rem]">سرفصل‌ها</span>
          <ul className="text-[1.6rem] font-light list-disc">
            <li className="marker:text-blue mr-6">توابع</li>
            <li className="marker:text-blue mr-6">معادله و نامعادله</li>
            <li className="marker:text-blue mr-6">الگو و دنباله</li>
            <li className="marker:text-blue mr-6">مثلثات</li>
            <li className="marker:text-blue mr-6">توابع گویا و عبارات جبری</li>
            <li className="marker:text-blue mr-6">معادله درجه دوم</li>
            <li className="marker:text-blue mr-6">توابع</li>
            <li className="marker:text-blue mr-6">شمارش بدون شمردن</li>
            <li className="marker:text-blue mr-6">احتمالات</li>
            <li className="marker:text-blue mr-6">آمار</li>
          </ul>
          <span className="absolute -bottom-10 left-8 md:inline-block hidden">
            <CurlyLine />
          </span>
        </Card>
        <Card className="text-right pt-16 pb-[3.2rem] px-[4.4rem] md:w-1/3 h-fit hover:shadow-2xl transition-shadow hover:-mt-1">
          <h4 className="font-bold text-[2.4rem]">ریاضیات یازدهم</h4>
          <p className="text-[1.6rem] text-[#8E9BA9] font-light">
            سرفصل ‏های تدریس ‏شده برای دانش‏ آموزان پایۀ یازدهم منطبق با کتب
            ریاضی (2) ویژۀ رشتۀ تجربی و حسابان (1) ویژۀ رشتۀ ریاضی است. دانش‏
            آموزان ریاضی سرفصل‏های نامشترک نظیر هندسه، آمار و احتمال را در کتب
            دیگر خود می‏خوانند. تدریس مطالب در این دوره به‏ گونه ‏ایست که علاوه
            بر مطالب سال یازدهم، هم مفاهیم سال دهم را پوشش می‏دهد و هم پیش
            ‏زمینه‏ ای برای سال دوازدهم مهیا می‏کند. ترتیب سرفصل‏ها به‏ گونه ‏ای
            انتخاب شده که آموزش بهتری صورت گیرد.
          </p>
          <hr className="my-12" />

          <span className="text-[1.6rem]">سرفصل‌ها</span>
          <ul className="text-[1.6rem] font-light list-disc">
            <li className="marker:text-blue mr-6">توابع</li>
            <li className="marker:text-blue mr-6">هندسه مختصاتی</li>
            <li className="marker:text-blue mr-6">
              معادلات و نامعادلات گویا و گنگ
            </li>
            <li className="marker:text-blue mr-6">معادلات درجه دوم</li>
            <li className="marker:text-blue mr-6">تصاعد(الگو و دنباله)</li>
            <li className="marker:text-blue mr-6">قدر مطلق و ویژگی‌های آن</li>
            <li className="marker:text-blue mr-6">براکت و ویژگی‌های آن</li>
            <li className="marker:text-blue mr-6">هندسه</li>
            <li className="marker:text-blue mr-6">توابع نمایی و لگاریتمی</li>
            <li className="marker:text-blue mr-6">مثلثات</li>
            <li className="marker:text-blue mr-6">حد</li>
            <li className="marker:text-blue mr-6">پیوستگی</li>
            <li className="marker:text-blue mr-6">آنالیز</li>
            <li className="marker:text-blue mr-6">احتمال</li>
            <li className="marker:text-blue mr-6">آمار</li>
          </ul>
        </Card>
        <Card className="text-right pt-16 pb-[3.2rem] px-[4.4rem] md:w-1/3 h-fit hover:shadow-2xl transition-shadow hover:-mt-1">
          <h4 className="font-bold text-[2.4rem]">ریاضیات دوازدهم</h4>
          <p className="text-[1.6rem] text-[#8E9BA9] font-light">
            سرفصل‏های تدریس ‏شده برای دانش ‏آموزان پایۀ دوازدهم منطبق با کتب
            ریاضی (3) ویژۀ رشتۀ تجربی و حسابان (2) ویژۀ رشتۀ ریاضی است. دانش‏
            آموزان ریاضی سرفصل ‏های نامشترک نظیر مقاطع مخروطی و احتمال را در کتب
            دیگر خود می‏خوانند. در این دوره، علاوه بر بیان مفاهیم و مسائل سال
            دوازدهم، مروری جامع بر مطالب سال‏های گذشته صورت می‏گیرد و تکنیک ‏های
            تست ‏زنی نیز آموزش داده می‏شود تا دانش‏ آموزان عملکرد هرچه ‏بهتری در
            آزمون‏های آزمایشی و کنکور سراسری داشته باشند.
          </p>
          <hr className="my-12" />

          <span className="text-[1.6rem]">سرفصل‌ها</span>
          <ul className="text-[1.6rem] font-light list-disc">
            <li className="marker:text-blue mr-6">توابع</li>
            <li className="marker:text-blue mr-6">هندسه مختصاتی</li>
            <li className="marker:text-blue mr-6">مثلثات</li>
            <li className="marker:text-blue mr-6">تصاعد (الگو و دنباله)</li>
            <li className="marker:text-blue mr-6">قدرمطلق و ویژگی های آن</li>
            <li className="marker:text-blue mr-6">ابراکت و ویژگی های آن</li>
            <li className="marker:text-blue mr-6">معادلات درجه دوم</li>
            <li className="marker:text-blue mr-6">بخش پذیری</li>
            <li className="marker:text-blue mr-6">حد </li>
            <li className="marker:text-blue mr-6">پیوستگی</li>
            <li className="marker:text-blue mr-6">مشتق</li>
            <li className="marker:text-blue mr-6">کاربرد مشتق</li>
            <li className="marker:text-blue mr-6">مجانب ها</li>
            <li className="marker:text-blue mr-6">مقاطع مخروطی</li>
            <li className="marker:text-blue mr-6">آنالیز </li>
            <li className="marker:text-blue mr-6">احتمال</li>
            <li className="marker:text-blue mr-6">آمار [تکمیلی]</li>
            <li className="marker:text-blue mr-6">هندسه [تکمیلی]</li>
          </ul>
        </Card>
      </div>
    </div>
  );
};

export default Courses;
