import React from "react";
import { CloseSharp } from "@mui/icons-material";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { BtnPrimary } from "../../../shares/button/Button";

const MyComponent = ({ choices, close, setReturnTo, aqn }) => {
  const items = [...Array(10).keys()];

  return (
    <div className="relative rounded-2xl w-2/5 bg-white ">
      <CloseSharp
        className="absolute top-2 right-[6px] cursor-pointer"
        onClick={close}
      />
      <div className="flex gap-4 flex-col pt-12 pb-10 px-14 overflow-scroll max-h-[80vh]">
        <span className="text-3xl mb-4 border-b-2 pb-2 border-blue">
          منوی بازگشت
        </span>
        {items.map((item, index) => (
          <>
            <RadioGroup
              key={item}
              className="border-b border-black pb-4 md:pb-0  !flex-row items-center"
              defaultValue={choices[index]}
              disabled={true}
            >
              <span className="text-xl">سوال {index + 1}</span>
              <FormControlLabel
                className="pointer-events-none"
                value="1"
                control={<Radio />}
                label={"1"}
              />
              <FormControlLabel
                className="pointer-events-none"
                value="2"
                control={<Radio />}
                label="2"
              />
              <FormControlLabel
                className="pointer-events-none"
                value="3"
                control={<Radio />}
                label="3"
              />
              <FormControlLabel
                className="pointer-events-none"
                value="4"
                control={<Radio />}
                label="4"
              />
              {aqn > index + 1 && (
                <BtnPrimary
                  text={"بازگشت"}
                  className="mr-auto ml-0 py-2 px-4 hover:scale-110"
                  onClick={() => setReturnTo(index + 1)}
                />
              )}
            </RadioGroup>
          </>
        ))}
      </div>
    </div>
  );
};

const defaultValues = [2, 4, 1, 3, 2, 4, 1, 3, 0, 0];

export default MyComponent;
