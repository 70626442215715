import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CloseSharp } from "@mui/icons-material";
import { BtnAlternative, BtnPrimary } from "../../../../shares/button/Button";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import useFetch from "../../../../../hooks/useFetch";
import { Input, FormControl, FormHelperText } from "@mui/material";

const AddGroups = ({ close, get, edit }) => {
  const [call, setCall] = useState({
    name: "",
    group_time: "",
    group_day: "",
    group_gender: "",
    group_grade: "",
  });

  const [defaultTime, setDefaultTime] = useState();

  const newGroup = useFetch({
    method: "POST",
    url: `management/groups/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });
  const editGroup = useFetch({
    method: "PATCH",
    url: `management/groups/${edit}/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });
  const getGroup = useFetch({
    method: "GET",
    url: `management/groups/${edit}/`,
    noHeader: false,
    trigger: edit ? true : false,
    setter: setCall,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: call,
  });

  const onSubmit = (e) => {
    // e.preventDefault();

    const formData = new FormData();

    formData.append("name", call.name);
    formData.append("group_time", call.group_time);
    formData.append("group_day", call.group_day);
    formData.append("group_gender", call.group_gender);
    formData.append("group_grade", call.group_grade);

    setCall(formData);
    if (edit) {
      editGroup.reFetch();
    } else {
      newGroup.reFetch();
    }
  };
  useEffect(() => {
    newGroup.status === 201 && close();
  }, [newGroup.status]);
  useEffect(() => {
    editGroup.status === 200 && close();
  }, [editGroup.status]);

  return (
    <div className="relative rounded-2xl w-2/5 bg-white">
      <CloseSharp
        className="absolute top-2 right-[6px] cursor-pointer"
        onClick={close}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pt-16 pb-10 px-14 text-xl"
      >
        <h6 className="font-extrabold text-2xl border-b-2 w-full pb-4 mb-6">
          {edit ? "ویرایش گروه" : "گروه جدید"}
        </h6>
        <div className="mb-6">
          <label htmlFor="name" className="block mb-1">
            عنوان
          </label>
          <TextField
            name="name"
            value={call.name}
            placeholder="عنوان"
            {...register("name", {
              required: "عنوان را وارد کنید",
            })}
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ""}
            onChange={(e) => {
              setCall({ ...call, name: e.target.value });
            }}
            fullWidth
          />
        </div>

        <div className="mb-6">
          <label htmlFor="class" className="block mb-1">
            روز
          </label>
          <FormControl error={errors.group_day ? true : false} fullWidth>
            <Select
              value={call.group_day}
              {...register("group_day", {
                required: "عنوان را وارد کنید",
              })}
              fullWidth
              onChange={(e) => {
                setCall({ ...call, group_day: e.target.value });
              }}
            >
              <MenuItem value="شنبه">شنبه</MenuItem>
              <MenuItem value="یکشنبه">یکشنبه</MenuItem>
              <MenuItem value="دوشنبه">دوشنبه</MenuItem>
              <MenuItem value="سهشنبه">سه شنبه</MenuItem>
              <MenuItem value="چهارشنبه">چهار شنبه</MenuItem>
              <MenuItem value="پنجشنبه">پنجشنبه</MenuItem>
              <MenuItem value="جمعه">جمعه</MenuItem>
            </Select>
            <FormHelperText>{errors?.group_day?.message}</FormHelperText>
          </FormControl>
        </div>
        <div className="mb-6">
          <label htmlFor="name" className="block mb-1">
            ساعت
          </label>
          <TextField
            name="name"
            value={call.group_time}
            placeholder="ساعت"
            {...register("group_time", {
              required: "ساعت را وارد کنید",
            })}
            error={!!errors.group_time}
            helperText={errors.group_time ? errors.group_time.message : ""}
            onChange={(e) => {
              setCall({ ...call, group_time: e.target.value });
            }}
            fullWidth
          />
        </div>

        <div className="mb-6">
          <label htmlFor="class" className="block mb-1">
            مقطع
          </label>
          <FormControl error={errors.group_grade ? true : false} fullWidth>
            <Select
              value={call.group_grade}
              {...register("group_grade", {
                required: "عنوان را وارد کنید",
              })}
              id="class"
              fullWidth
              onChange={(e) => {
                setCall({ ...call, group_grade: e.target.value });
              }}
            >
              <MenuItem value="دهم">دهم</MenuItem>
              <MenuItem value="یازدهم">یازدهم</MenuItem>
              <MenuItem value="دوازدهم">دوازهم</MenuItem>
            </Select>
            <FormHelperText>{errors?.group_grade?.message}</FormHelperText>
          </FormControl>
        </div>

        <div className="mr-auto ml-0 w-fit">
          <button
            type="submit"
            className="text-white !bg-[#8200d3] transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 "
          >
            ثبت گروه
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddGroups;

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
