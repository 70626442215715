import React from 'react'

const Header = () => {
  return (
    <header className="py-[3.4rem] text-center">
    <h1 className="text-[2.4rem] font-bold ">نکته</h1>
    <p className="text-[1.4rem] pt-[1.9rem] opacity-50">
      در صورتی که شما کمتر از 80 درصد ویدیو هر جلسه را دیده باشید، آن جلسه
      غیبت در نظر گرفته میشود
    </p>
  </header>
  )
}

export default Header