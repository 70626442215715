import React, { useState, useEffect } from "react";
import { Close } from "@mui/icons-material";
import { BtnPrimary } from "../../../shares/button/Button";
import useFetch from "../../../../hooks/useFetch";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { useAuth } from "../../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];



const Register = ({ close }) => {
  // const [username, setUsername] = useState();
  const [res, setRes] = useState();
  const [call, setCall] = useState({
    student_user: {
      username: "",
      password: "",
      password2: "",
      first_name: "",
      last_name: "",
    },
    birth_date: "",
    phone_number: "",
    home_number: "",
    student_type: "",
    student_school: "",
    student_gender: "",
    student_grade: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const registerFetch = useFetch({
    method: "POST",
    url: `register`,
    noHeader: true,
    trigger: false,
    data: call,
    setter: setRes
  });

  const submitForm = (event) => {
    // event.preventDefault();
    const formData = new FormData();

    formData.append("student_user.username", call.student_user.username);
    formData.append("student_user.password", call.student_user.password);
    formData.append("student_user.password2", call.student_user.password2);
    formData.append("student_user.first_name", call.student_user.first_name);
    formData.append("student_user.last_name", call.student_user.last_name);
    formData.append("birth_date", call.birth_date);
    formData.append("phone_number", call.phone_number);
    formData.append("home_number", call.home_number);
    formData.append("student_type", call.student_type);
    formData.append("student_school", call.student_school);
    formData.append("student_gender", call.student_gender);
    formData.append("student_grade", call.student_grade);

    setCall(formData);
    registerFetch.reFetch();
    // close;
    // console.log("first")
  };

  useEffect(() => {
    if (res) {
      toast.success("ثبت نام با موفقیت انجام شد")
      close()
    }
  }, [res]);

  return (
    <div className="flex justify-center h-full w-full overflow-scroll ">
      {registerFetch.loading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <>
          <form
            className="bg-white pt-[6rem] pb-12 px-16 rounded-[3.2rem] relative w-full md:w-2/5 h-fit"
            onSubmit={handleSubmit(submitForm)}
          >
            {errors?.username && <p className="text-[1.4rem] text-red-500">{errors?.username.message}</p>}
            {errors?.password && <p className="text-[1.4rem] text-red-500">{errors?.password.message}</p>}
            {errors?.password2 && <p className="text-[1.4rem] text-red-500">{errors?.password2.message}</p>}
            {errors?.firstName && <p className="text-[1.4rem] text-red-500">{errors?.firstName.message}</p>}
            {errors?.lastName && <p className="text-[1.4rem] text-red-500">{errors?.lastName.message}</p>}
            {errors?.phoneNumber && <p className="text-[1.4rem] text-red-500">{errors?.phoneNumber.message}</p>}
            {errors?.birthDate && <p className="text-[1.4rem] text-red-500">{errors?.birthDate.message}</p>}
            {errors?.homeNumber && <p className="text-[1.4rem] text-red-500">{errors?.homeNumber.message}</p>}
            {errors?.studentSchool && <p className="text-[1.4rem] text-red-500">{errors?.studentSchool.message}</p>}
            {errors?.studentGender && <p className="text-[1.4rem] text-red-500">{errors?.studentGender.message}</p>}
            {errors?.studentGrade && <p className="text-[1.4rem] text-red-500">{errors?.studentGrade.message}</p>}
            <div className="cursor-pointer absolute top-8 right-12">
              <Close onClick={close} sx={{ fontSize: 26 }} />
            </div>
            <h5 className="font-bold text-[2.4rem] mb-9">ثبت نام</h5>

            <div className="flex flex-col mb-10 gap-y-2">
              <input
                autoComplete="false"
                placeholder="کدملی"
                type="number"
                name="username"
                className="border-2 border-[#C7D9FF] rounded-t-[1.6rem] bg-transparent py-10 pr-12 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
                {...register("username", {
                  required: "لطفا کدملی خود را وارد کنید",
                  maxLength: {
                    value: 10,
                    message: "کدملی خود را به طور صحیح وارد کنید",
                  },
                  minLength: {
                    value: 10,
                    message: "کدملی خود را به طور صحیح وارد کنید",
                  },
                })}
                onChange={(e) =>
                  setCall((prevState) => ({ ...prevState, student_user: { ...prevState.student_user, username: e.target.value } }))
                }
              />
              <input
                autoComplete="false"
                placeholder="رمز عبور"
                type="password"
                name="password"
                className="border-2 border-[#C7D9FF] bg-transparent py-10 pr-12 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
                {...register("password", {
                  required: "لطفا رمز عبور خود را وارد کنید",
                  maxLength: {
                    value: 12,
                    message: "رمز خود را به طور صحیح وارد کنید",
                  },
                  minLength: {
                    value: 8,
                    message: "رمز خود را به طور صحیح وارد کنید",
                  },
                })}
                onChange={(e) =>
                  setCall((pervState) => ({ ...pervState, student_user: { ...pervState.student_user, password: e.target.value } }))
                }
              />
              <input
                autoComplete="false"
                placeholder="تکرار رمز عبور"
                type="password"
                name="password2"
                className="border-2 border-[#C7D9FF] bg-transparent py-10 pr-12 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
                {...register("password2", {
                  required: "لطفا رمز عبور خود را وارد کنید",
                  maxLength: {
                    value: 12,
                    message: "رمز خود را به طور صحیح وارد کنید",
                  },
                  minLength: {
                    value: 8,
                    message: "رمز خود را به طور صحیح وارد کنید",
                  },
                })}
                onChange={(e) =>
                  setCall((pervState) => ({ ...pervState, student_user: { ...pervState.student_user, password2: e.target.value } }))
                }
              />
              <input
                autoComplete="false"
                placeholder="نام"
                type="text"
                name="firstName"
                className="border-2 border-[#C7D9FF] bg-transparent py-10 pr-12 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
                {...register("firstName", {
                  required: "لطفا نام خود را وارد کنید",
                })}
                onChange={(e) =>
                  setCall((pervState) => ({ ...pervState, student_user: { ...pervState.student_user, first_name: e.target.value } }))
                }
              />
              <input
                autoComplete="false"
                placeholder="نام خانوادگی"
                type="text"
                name="lastName"
                className="border-2 border-[#C7D9FF] bg-transparent py-10 pr-12 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
                {...register("lastName", {
                  required: "لطفا نام خانوادگی خود را وارد کنید",
                })}
                onChange={(e) =>
                  setCall((pervState) => ({ ...pervState, student_user: { ...pervState.student_user, last_name: e.target.value } }))
                }
              />
              <DatePicker
                // value={call.birth_date}
                // required
                inputClass="border-2 border-[#C7D9FF] bg-transparent py-10 pr-12 font-light text-[1.6rem] placeholder:text-[#c7d9ff] w-full"
                format="YYYY-MM-DD"
                containerClassName="w-full"
                calendar={persian}
                locale={persian_fa}
                placeholder={"تولد"}
                digits={digits}
                // mobileLabels={{
                //   OK: "تایید ",
                //   CANCEL: "انصراف",
                // }}
                onChange={(e) => setCall({ ...call, birth_date: e.format() })}
              // {...rest}
              />
              <input
                autoComplete="false"
                placeholder="شماره همراه"
                type="number"
                name="phoneNumber"
                className="border-2 border-[#C7D9FF] bg-transparent py-10 pr-12 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
                {...register("phoneNumber", {
                  required: "لطفا شماره همراه خود را وارد کنید",
                })}
                onChange={(e) => setCall({ ...call, phone_number: e.target.value })}
              />
              <input
                autoComplete="false"
                placeholder="تلفن ثابت"
                type="number"
                name="homeNumber"
                className="border-2 border-[#C7D9FF] bg-transparent py-10 pr-12 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
                {...register("homeNumber", {
                  required: "لطفا تلفن خود را وارد کنید",
                })}
                onChange={(e) => setCall({ ...call, home_number: e.target.value })}
              />
              <select
                placeholder="رشته"
                name="studentType"
                className="border-2 border-[#C7D9FF] bg-transparent py-10 pr-12 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
                {...register("studentType", {
                  required: "لطفا رشته خود را وارد کنید",
                })}
                onChange={(e) => setCall({ ...call, student_type: e.target.value })}
              >
                <option value="تجربی" disabled selected>
                  رشته
                </option>
                <option value="تجربی">تجربی</option>
                <option value="ریاضی">ریاضی</option>
              </select>
              <select
                placeholder="مدرسه"
                name="studentSchool"
                className="border-2 border-[#C7D9FF] bg-transparent py-10 pr-12 font-light text-[1.6rem] 
                        placeholder:text-[#c7d9ff]"
                {...register("studentSchool", {
                  required: "لطفا مدرسه خود را وارد کنید",
                })}
                onChange={(e) => setCall({ ...call, student_school: e.target.value })}
              >
                <option value="تجربی" disabled selected>
                  مدرسه
                </option>
                <option value={1}>شهید اژه‌ای</option>
                <option value={2}>امام صادق(ع)</option>
                <option value={3}>سایر</option>
              </select>
              <select
                placeholder="جنسیت"
                name="studentGender"
                className="border-2 border-[#C7D9FF] bg-transparent py-10 pr-12 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
                {...register("studentGender", {
                  required: "لطفا جنسیت خود را وارد کنید",
                })}
                onChange={(e) => setCall({ ...call, student_gender: e.target.value })}
              >
                <option value="تجربی" disabled selected>
                  جنسیت
                </option>
                <option value="پسر">پسر</option>
                <option value="دختر">دختر</option>
              </select>
              <select
                placeholder="پایه"
                name="studentGrade"
                className="border-2 border-[#C7D9FF] rounded-b-[1.6rem] bg-transparent py-10 pr-12 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
                {...register("studentGrade", {
                  required: "لطفا پایه‌ی خود را وارد کنید",
                })}
                onChange={(e) => setCall({ ...call, student_grade: e.target.value })}
              >
                <option value="تجربی" disabled selected>
                  پایه‌ی تحصیلی
                </option>
                <option value="دهم">دهم</option>
                <option value="یازدهم">یازدهم</option>
                <option value="دوازدهم">دوازدهم</option>
              </select>
            </div>
            <div className="flex justify-center w-full mb-8 text-right">
              {/* <BtnPrimary text="ورود به سامانه" className="py-6 px-16" type="submit" /> */}

              <button
                type="submit"
                className="text-white bg-blue hover:bg-blueHover transition-all rounded-16 focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] py-6 px-16 w-full"
              >
                ثبت نام
              </button>
            </div>
          </form>
        </>
      )}

      {/* <div className="items-center justify-center h-full text-center w-full"> */}

      {/* </div> */}
    </div>
  );
};

export default Register;
