import React from "react";
import { useNavigate } from "react-router-dom";
import { BtnPrimary } from "../components/shares/button/Button";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center min-h-screen bg-black text-white flex-col gap-y-8">
      <h1 className="text-4xl">404 - صفحه مورد نظر یافت نشد!</h1>
      <BtnPrimary
        text={"بازگشت"}
        className="py-6 px-12"
        onClick={() => navigate(-1)}
      />
    </div>
  );
};

export default NotFoundPage;
