import React from "react";
import Logo from "../../../../assets/img/logo.png";
import TelegramIcon from "@mui/icons-material/Telegram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";

function navbar() {
  return (
    <>
      <nav className="sm:flex sm:justify-between text-[1.4rem] container hidden">
        <img src={Logo} alt="لوگو" />

        <ul className=" flex md:gap-x-[4.4rem] gap-x-10">
          <li>
            <a href="#header">صفحه اصلی</a>
          </li>
          <li>
            <a href="#courses">دوره‌های آموزشگاه</a>
          </li>
          <li>
            <a href="#footer">تماس با ما</a>
          </li>
        </ul>

        <ul className=" flex md:gap-x-[1.5rem] gap-x-4">
          <li>ما را دنبال کنید</li>
          <li>
            <TelegramIcon sx={{ fontSize: 18 }} />
          </li>
          <li>
            <LinkedInIcon sx={{ fontSize: 18 }} />
          </li>
        </ul>
      </nav>
      <nav className="flex justify-between text-[1.4rem] container sm:hidden">
        <img src={Logo} alt="لوگو" />

        {/* <ul className=" flex gap-x-[4.4rem]">
                <li><Link to="/dashboard">صفحه اصلی</Link></li>
                <li>دوره‌های آموزشگاه</li>
                <li>تماس با ما</li>
            </ul>

            <ul className=" flex gap-x-[1.5rem]">
                <li>ما را دنبال کنید</li>
                <li>
                    <TelegramIcon sx={{ fontSize: 18 }} />
                </li>
                <li>
                    <LinkedInIcon sx={{ fontSize: 18 }} />
                </li> */}
        {/* </ul> */}
      </nav>
    </>
  );
}

export default navbar;
