import React, { useEffect } from "react";
import HWCard from "./cards/HWCard";
import EXAMCard from "./cards/EXAMCard";
import CLassCard from "./cards/CLassCard";
import Header from "./Header";
import useFetch from "./../../../../hooks/useFetch";

function Index() {

  return (
    <div className="dash-home  text-center">
      <Header />

      <div className="info-cards py-[4.1rem]">
        <div className="info-card container gap-[2.4rem]">
          <CLassCard />
          <EXAMCard />
          <HWCard />
        </div>
      </div>
    </div>
  );
}

export default Index;
