import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Home from "../layout/Home/Home";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import AdminRouter from "./AdminRouter";
import UserRouter from "./UserRouter";
import NotFoundPage from "./NotFoundPage";

function Router(props) {
  const location = useLocation();
  const userToken = Cookies.get("token");
  const admin = Cookies.get("memo_cache");
  const navigate = useNavigate();

  useEffect(() => {
    !userToken && navigate("/");
  }, [userToken]);

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
        <div className="content h-full">
          <Routes location={location}>
            <Route key={"/"} path="/" element={<Home />} />
            {userToken && admin === "true" && (
              <Route
                key={"/admin/*"}
                path="/admin/*"
                element={<AdminRouter />}
              />
            )}
            {userToken && admin === "false" && (
              <Route
                key={"dashboard"}
                path="/dashboard/*"
                element={<UserRouter />}
              />
            )}
            <Route key={"*"} path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}

export default Router;
