import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
// import video from "../../../assets/vid/Sirvan Khosravi - Bargard - Live in Tehran - 2019 - 4K.mkv";
import ControlIcons from "./ControlIcons";
import screenfull from "screenfull";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { useSession } from "../../../contexts/SessionContext";

const VideoPlayer = ({ percentSetter, durationSetter, video, presence_percentage }) => {
  const [playerstate, setPlayerState] = useState({
    playing: false,
    // mute: true,
    volume: 100,
    playerbackRate: 1.0,
    played: 0,
    seeking: false,
  });
  const [isDuration, setDuration] = useState();
  const [seeked, setSeeked] = useState(false);
  const [percent, setPercent] = useState();
  const playerRef = useRef();
  const { playing, mute, volume, playerbackRate, played, seeking } = playerstate;
  const handleMuting = () => {
    setPlayerState({ ...playerstate, mute: !playerstate.mute });
  };
  const handlePlayAndPause = (event) => {
    if (event && event.type === "keydown" && event.key === "SPACE") {
      return;
    }
    setPlayerState({
      ...playerstate,
      playing: !playerstate.playing,
    });
  };
  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };
  const handleRewind30 = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 30);
  };
  const resetVideo = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() === 0);
  };
  // const handlePlayerSeek = (newValue) => {
  //   setPlayerState({ ...playerstate, played: Math.round(newValue / 100) });
  //   playerRef.current.seekTo(Math.round(newValue / 100));
  // };

  const handlePlayerMouseSeekUp = (newValue) => {
    setPlayerState({ ...playerstate, seeking: false });
    playerRef.current.seekTo(newValue / 100);
  };
  const currentPlayerTime = playerRef.current ? playerRef.current.getCurrentTime() : "00:00";
  const movieDuration = playerRef.current ? playerRef.current.getDuration() : "00:00";
  const format = (seconds) => {
    if (isNaN(seconds)) {
      return "00:00";
    }

    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");

    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    } else {
      return `${mm}:${ss}`;
    }
  };
  // useEffect(() => {

  //   playerRef.current.seekTo(100);

  // }, [])
  // console.log(movieDuration);

  const playedTime = format(currentPlayerTime);
  const fullMovieTime = format(movieDuration);

  const handleVolumeChange = (e, newValue) => {
    setPlayerState({ ...playerstate, volume: parseFloat(newValue / 100), mute: newValue === 0 ? true : false });
  };

  //function for the `onChangeCommitted` event
  const handleVolumeSeek = (e, newValue) => {
    setPlayerState({ ...playerstate, volume: parseFloat(newValue / 100), mute: newValue === 0 ? true : false });
  };
  const handlePlayerRate = (rate) => {
    setPlayerState({ ...playerstate, playerbackRate: rate });
  };
  //   const playerDivRef = useRef(null);
  const handleFullScreenMode = () => {
    screenfull.toggle(playerDivRef.current);
  };
  const duration = movieDuration;
  const currentTime = currentPlayerTime;
  durationSetter(fullMovieTime);
  const handlePlayerProgress = (state) => {
    if (isNaN(duration)) return;
    setDuration(duration);
    setPercent(Math.round((currentTime / duration) * 100));
    percentSetter(percent);
    if (!playerstate.seeking) {
      setPlayerState({ ...playerstate, ...state });
    }
    if (percent === 10) {
    }
  };
  const playerDivRef = useRef();
  const { sessionList, contentId, isReady, setIsReady } = useSession();
  // console.log(isReady)
  // console.log(presence_percentage)
  const onReady = useCallback(() => {
    if (!isReady && Boolean(Number(presence_percentage)) && playerRef.current) {
      // playerRef.current.seekTo((playerRef.current.getDuration() * presence_percentage) / 100, "seconds");
      playerRef.current.seekTo((playerRef.current.getDuration() * sessionList[contentId - 1]) / 100, "seconds");
      setIsReady(true);
    }
  }, [isReady, presence_percentage]);
  // console.log(playerRef.current ? (playerRef.current.getDuration() * presence_percentage) / 100 : "");
  // console.log(playerRef.current ? playerRef.current.getDuration() : "");

  return (
    <>
    
      <div className="player-wrapper max-md:w-full relative px-10 w-10/12" ref={playerDivRef}>
        <ReactPlayer
          className={""}
          width="100%"
          height="100%"
          style={{ borderRadius: "0.75rem", overflow: "hidden" }}
          controls
          ref={playerRef}
          playing={playing}
          onProgress={handlePlayerProgress}
          url={video}
          volume={volume}
          muted={false}
          onSeek={seeking}
          playbackRate={playerbackRate}
          onClickPreview={(event) => handlePlayAndPause()}
          onReady={onReady}
        />
        <ControlIcons
          playandpause={(event) => handlePlayAndPause()}
          playing={playing}
          rewind10={handleRewind}
          rewind30={handleRewind30}
          played={played}
          // onSeek={handlePlayerSeek}
          onSeekMouseUp={handlePlayerMouseSeekUp}
          playedTime={playedTime}
          fullMovieTime={fullMovieTime}
          muting={handleMuting}
          muted={mute}
          volume={volume}
          volumeChange={handleVolumeChange}
          volumeSeek={handleVolumeSeek}
          playerbackRate={playerbackRate}
          playRate={handlePlayerRate}
          fullScreenMode={handleFullScreenMode}
          resetVideo={resetVideo}
        />
      </div>
    </>
  );
};

export default VideoPlayer;
