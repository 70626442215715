import React, { useState } from "react";
import VideocamIcon from "@mui/icons-material/Videocam";
import GroupIcon from "@mui/icons-material/Group";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Logo from "../../../../../assets/img/logo.png";
import { Link, NavLink, useMatch, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../contexts/AuthContext";
import ResRightSidebar from "./ResRightSidebar";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AccountBox,
  AccountCircle,
  BubbleChart,
  Create,
  Description,
  FormatListBulleted,
  LiveHelp,
  MenuBook,
  PostAdd,
  Quiz,
} from "@mui/icons-material";
import useFetch from "../../../../../hooks/useFetch";
import { toast } from "react-toastify";

function RightSidebar() {
  const [expanded, setExpanded] = useState(false);
  const [info, setInfo] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const groupsMatch = useMatch("/admin/groups");
  const videosMatch = useMatch("/admin/videos");
  const questionsMatch = useMatch("/admin/questions");
  const questionCatMatch = useMatch("/admin/question-cat");
  const examsMatch = useMatch("/admin/exams");
  const booksMatch = useMatch("/admin/books");
  const homeworkListMatch = useMatch("/admin/homework/list");
  const homeworkAnswerMatch = useMatch("/admin/homework/answers");
  const studentMatch = useMatch("/admin/management/student");
  const employeeMatch = useMatch("/admin/management/employee");
  const navigate = useNavigate();

  const panel = useFetch({
    method: "GET",
    url: `student-panel/`,
    noHeader: false,
    trigger: true,
    setter: setInfo,
    errMessage: () =>
      toast.error("مشکلی در دریافت اطلاعات دانش آموز پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
  });

  const { loginDispatch } = useAuth();

  const logoutFunc = () => {
    loginDispatch({
      type: "LOGOUT",
    });
    navigate("/");
  };

  return (
    <>
      <div className=" h-screen inline-block w-full sticky top-0">
        <aside
          id="default-sidebar"
          aria-label="Sidebar"
          style={{}}
          className={`h-full shadow-xl text-[#A5B0C2] bg-white transition-transform -translate-x-full sm:translate-x-0 `}
        >
          <div className="sidebarRight-wrapper px-8 ">
            <div className="sidebarRight-container">
              <div className="logo-container pt-[3.27rem]">
                <Link to="/">
                  <img className="logo" src={Logo}></img>
                </Link>
              </div>

              <div className="nav-container mt-20">
                <ul className="nav-list flex flex-col gap-y-8 h-full">
                  <li>
                    <NavLink exact to={"/admin/groups"} className={`flex`}>
                      <GroupIcon
                        className={`ml-[1.77rem]`}
                        sx={{
                          color: groupsMatch ? "#8200d3" : "#A5B0C4",
                          fontSize: 24,
                        }}
                      />
                      گروه‌ها
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to={"/admin/videos"} className={`flex`}>
                      <VideocamIcon
                        className={`ml-[1.77rem]`}
                        sx={{
                          color: videosMatch ? "#8200d3" : "#A5B0C4",
                          fontSize: 24,
                        }}
                      />
                      درس‌ها و ویدیوها
                    </NavLink>
                  </li>
                  <li>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                      className="before:hidden"
                      sx={{
                        boxShadow: "none",
                        "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root":
                          { padding: "0px 0px", minHeight: "0px" },
                        "& .css-o4b71y-MuiAccordionSummary-content": {
                          margin: "0px 0px",
                        },
                        "& .css-15v22id-MuiAccordionDetails-root": {
                          padding: "5px 15px 5px 0px",
                          backgroundColor: "rgb(248, 250, 252)",
                        },
                        "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
                          { padding: "0px 0px", minHeight: "0px" },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <div className={`flex`}>
                          <Description
                            className={`ml-[1.77rem]`}
                            sx={{
                              color:
                                homeworkAnswerMatch || homeworkListMatch
                                  ? "#8200d3"
                                  : "#A5B0C4",
                              fontSize: 24,
                            }}
                          />
                          <span
                            className={
                              homeworkAnswerMatch || homeworkListMatch
                                ? "active"
                                : "text-[#a5b0c4]"
                            }
                          >
                            تکلیف
                          </span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails sx={{}}>
                        <NavLink
                          exact
                          to={"/admin/homework/list"}
                          className={`flex`}
                        >
                          <FormatListBulleted
                            className={`ml-[1.77rem]`}
                            sx={{
                              color: homeworkListMatch ? "#8200d3" : "#A5B0C4",
                              fontSize: 24,
                            }}
                          />
                          <span
                            className={
                              homeworkListMatch ? "active" : "text-[#a5b0c4]"
                            }
                          >
                            لیست تکالیف
                          </span>
                        </NavLink>
                        <NavLink
                          exact
                          to={"/admin/homework/answers"}
                          className={`flex mt-8`}
                        >
                          <Create
                            className={`ml-[1.77rem]`}
                            sx={{
                              color: homeworkAnswerMatch
                                ? "#8200d3"
                                : "#A5B0C4",
                              fontSize: 24,
                            }}
                          />
                          <span
                            className={
                              homeworkAnswerMatch ? "active" : "text-[#a5b0c4]"
                            }
                          >
                            پنل تصحیح
                          </span>
                        </NavLink>
                      </AccordionDetails>
                    </Accordion>
                  </li>
                  <li>
                    <NavLink exact to={"/admin/questions"} className={`flex`}>
                      <LiveHelp
                        className={`ml-[1.77rem]`}
                        sx={{
                          color: questionsMatch ? "#8200d3" : "#A5B0C4",
                          fontSize: 24,
                        }}
                      />
                      بانک سوال
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to={"/admin/question-cat"}
                      className={`flex`}
                    >
                      <PostAdd
                        className={`ml-[1.77rem]`}
                        sx={{
                          color: questionCatMatch ? "#8200d3" : "#A5B0C4",
                          fontSize: 24,
                        }}
                      />
                      ساخت آزمون
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to={"/admin/exams"} className={`flex`}>
                      <Quiz
                        className={`ml-[1.77rem]`}
                        sx={{
                          color: examsMatch ? "#8200d3" : "#A5B0C4",
                          fontSize: 24,
                        }}
                      />
                      آزمون
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to={"/admin/management/student"}
                      className={`flex`}
                    >
                      <AccountBox
                        className={`ml-[1.77rem]`}
                        sx={{
                          color: studentMatch ? "#8200d3" : "#A5B0C4",
                          fontSize: 24,
                        }}
                      />
                      <span
                        className={studentMatch ? "active" : "text-[#a5b0c4]"}
                      >
                        دانش آموزان
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to={"/admin/books"} className={`flex`}>
                      <MenuBook
                        className={`ml-[1.77rem]`}
                        sx={{
                          color: booksMatch ? "#8200d3" : "#A5B0C4",
                          fontSize: 24,
                        }}
                      />
                      جزوات
                    </NavLink>
                  </li>

                  <hr className="w-full -ml-36" />
                  <li onClick={logoutFunc}>
                    <a className="text-red-500">
                      <LogoutRoundedIcon
                        className="ml-[1.77rem]"
                        sx={{ color: "#EF4444", fontSize: 24 }}
                      />
                      خروج
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
        <div className="absolute bottom-4 w-full">
          <hr className="w-full -ml-36 mb-4" />
          <p className=" text-black px-8  text-ellipsis whitespace-nowrap overflow-hidden">
            <AccountCircle
              className="ml-[1.77rem]"
              sx={{
                color: "#8200d3",
                fontSize: 24,
              }}
            />
            {info?.first_name} {info?.last_name}
          </p>
        </div>
      </div>
    </>
  );
}

export default RightSidebar;
