import React, { useState, useEffect, useRef } from "react";
import { Close, Cookie } from "@mui/icons-material";
import { BtnPrimary } from "../../../shares/button/Button";
import useFetch from "../../../../hooks/useFetch";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { useAuth } from "../../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = ({ close }) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const [call, setCall] = useState({ username: "", password: "" });
  const [token, setToken] = useState();

  const { loginDispatch, isLogin, admin, setAdmin } = useAuth();

  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: call.username,
      password: call.password,
    },
  });

  const login = useFetch({
    method: "POST",
    url: `token-auth`,
    noHeader: true,
    trigger: false,
    data: call,
    setter: setToken,
    errMessage: () =>
      toast.error("دوباره تلاش کنید", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
  });

  const submitForm = () => {
    if (username !== "" && password !== "") {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);

      setCall(formData);
      login.reFetch();
    }
    // console.log("first")
  };

  useEffect(() => {
    // Cookies.set("token", token?.token)
    if (token) {
      loginDispatch({ type: "LOGIN", token: token?.token });
      setAdmin(token?.memo_cache);
    }
  }, [token]);

  useEffect(() => {
    if (isLogin === true && admin === false) navigate("/dashboard/home");
    else if (isLogin === true && admin === true) {
      navigate("admin/groups");
    }
  }, [isLogin]);

  return (
    <div className="flex justify-center h-full w-full">
      {/* <div className="items-center justify-center h-full text-center w-full"> */}
      <form
        autoComplete="off"
        aria-autocomplete="off"
        className="bg-white pt-[6rem] pb-12 px-16 rounded-[3.2rem] relative w-full md:w-2/5"
        onSubmit={handleSubmit(submitForm)}
      >
        {errors?.username && (
          <p className="text-[1.4rem] text-red-500">
            {errors?.username.message}
          </p>
        )}
        {errors?.password && (
          <p className="text-[1.4rem] text-red-500">
            {errors?.password.message}
          </p>
        )}
        <div className="cursor-pointer absolute top-8 right-12">
          <Close onClick={close} sx={{ fontSize: 26 }} />
        </div>
        <h5 className="font-bold text-[2.4rem] mb-9">ورود به سامانه</h5>
        <p className="text-[1.6rem] mb-16">
          اگر دانش آموز هستید باید کد ملی خود را وارد کنید.
        </p>
        <div className="flex flex-col mb-10 relative">
          <input
            control={control}
            placeholder="کدملی یا شماره همراه"
            type="number"
            name="username"
            className="border-2 border-[#C7D9FF] rounded-t-[1.6rem] bg-transparent py-10 pr-12 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
            {...register("username", {
              // required: "لطفا کدملی خود را وارد کنید",
              maxLength: {
                value: 10,
                message: "کدملی خود را به طور صحیح وارد کنید",
              },
              minLength: {
                value: 10,
                message: "کدملی خود را به طور صحیح وارد کنید",
              },
            })}
            // defaultValue={call.username}
            onChange={(e) => {
              // setCall({ ...call, username: e.target.value });
              setUsername(e.target.value);
            }}
          />

          <input
            control={control}
            placeholder="رمز عبور"
            type={showPassword ? "text" : "password"}
            name="password"
            className="border-2 border-[#C7D9FF] rounded-b-[1.6rem] bg-transparent py-10 pr-12 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
            {...register("password", {
              // required: "لطفا رمز عبور خود را وارد کنید",
              minLength: {
                value: 4,
                message: "رمز خود را به طور صحیح وارد کنید",
              },
            })}
            // defaultValue={call.password}
            onChange={(e) => {
              // setCall({ ...call, password: e.target.value });
              setPassword(e.target.value);
            }}
          />
          <span
            onClick={() => setShowPassword(!showPassword)}
            className="absolute bottom-12 left-8 cursor-pointer"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </span>
        </div>
        <div className="flex mb-16 text-right">
          {/* <div>
            <span className="block text-[1.6rem] font-light text-[#8E9BA9]">
              رمز عبور خود را فراموش کرده‌اید؟
            </span>
            <a className="text-blue underline text-[1.6rem]">
              بازیابی رمز عبور
            </a>
          </div> */}
          {/* <BtnPrimary text="ورود به سامانه" className="py-6 px-16" type="submit" /> */}

          {login.loading ? <CircularProgress /> : ""}
          <button
            type="submit"
            className="text-white bg-blue hover:bg-blueHover transition-all rounded-16 focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] py-6 px-16  mr-auto ml-0"
          >
            ورود به سامانه
          </button>
        </div>
        <hr className="mb-16" />
        <p className="text-[1.6rem] text-[#8E9BA9] text-center">
          درصورتی که مشخصات ورود به سامانه آموزشی عطار را دریافت نکردید با واحد
          اداری آموزشگاه ریاضی مهندس عطار تماس حاصل فرمائید و یا از طریق فرم پیش
          ثبت نام اقدام فرمایید.
        </p>
      </form>
      {/* </div> */}
    </div>
  );
};

export default Login;
