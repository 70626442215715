import React, { useState } from "react";
import { Card } from "../../../shares/card/Card";
import ShareTable from "../../../shares/tables/ShareTable";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Approval,
  TextFields,
  TextureRounded,
  TextureSharp,
} from "@mui/icons-material";
import { BtnPrimary } from "../../../shares/button/Button";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Modal,
} from "@mui/material";
import AddGroups from "./modals/AddVideos";

const Notes = () => {
  const [classFilter, setClassFilter] = useState("");
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleClose = () => {
    setNewModal(false);
    setEditModal(false);
    setDeleteModal(false);
  };

  const handleFilter = (event) => {
    setClassFilter(event.target.value);
  };

  return (
    <div className="flex mt-20">
      <Card className="p-16 w-full text-right">
        <div className="flex justify-between items-center border-b-2 border-gray-200 pb-8 mb-12">
          <h3 className="text-3xl">درس‌ها و ویدیو‌ها</h3>
          <BtnPrimary
            text="+ افزودن درس جدید"
            className="px-6 py-4"
            onClick={() => {
              setNewModal(true);
            }}
          />
        </div>

        <ShareTable data={data} columns={columns} />
      </Card>
      <Modal
        open={newModal}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AddGroups close={handleClose} />
      </Modal>
    </div>
  );
};

export default Notes;

const groups = [
  "یازدهم دختر (پنجشنبه 9-7)",
  "دوازدهم پسر (پنجشنبه 9-7)",
  "دوازدهم پسر (پنجشنبه 9-7)",
  "دوازدهم پسر (پنجشنبه 9-7)",
  "دوازدهم پسر (پنجشنبه 9-7)",
];

const data = [
  {
    id: 1,
    name: "امیر",
    description: 25,
    priority: 3,
    class: "یازدهم",
    topic: "احتمال",
    groups: (
      <div className="flex gap-2 flex-col">
        {groups.map((g) => (
          <span className="inline-block border border-gray-500  rounded-lg">
            {g}
          </span>
        ))}
      </div>
    ),
    classStatus: "انجام شد",
    approve: <Approval />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "2امیر",
    description: 5,
    priority: 5,
    class: "دهم",
    topic: "احتمال",
    groups: (
      <div className="flex gap-2 flex-col">
        {groups.map((g) => (
          <span className="inline-block border border-gray-500  rounded-lg">
            {g}
          </span>
        ))}
      </div>
    ),
    classStatus: "انجام شد",
    approve: <Approval />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "2امیر",
    description: 5,
    priority: 5,
    class: "دهم",
    topic: "احتمال",
    groups: (
      <div className="flex gap-2 flex-col">
        {groups.map((g) => (
          <span className="inline-block border border-gray-500  rounded-lg">
            {g}
          </span>
        ))}
      </div>
    ),
    classStatus: "انجام شد",
    approve: <Approval />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "2امیر",
    description: 5,
    priority: 5,
    class: "دهم",
    topic: "احتمال",
    groups: (
      <div className="flex gap-2 flex-col">
        {groups.map((g) => (
          <span className="inline-block border border-gray-500  rounded-lg">
            {g}
          </span>
        ))}
      </div>
    ),
    classStatus: "انجام شد",
    approve: <Approval />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "2امیر",
    description: 5,
    priority: 5,
    class: "دهم",
    topic: "احتمال",
    groups: (
      <div className="flex gap-2 flex-col">
        {groups.map((g) => (
          <span className="inline-block border border-gray-500  rounded-lg">
            {g}
          </span>
        ))}
      </div>
    ),
    classStatus: "انجام شد",
    approve: <Approval />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "2امیر",
    description: 5,
    priority: 5,
    class: "دهم",
    topic: "احتمال",
    groups: (
      <div className="flex gap-2 flex-col">
        {groups.map((g) => (
          <span className="inline-block border border-gray-500  rounded-lg">
            {g}
          </span>
        ))}
      </div>
    ),
    classStatus: "انجام شد",
    approve: <Approval />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "2امیر",
    description: 5,
    priority: 5,
    class: "دهم",
    topic: "احتمال",
    groups: (
      <div className="flex gap-2 flex-col">
        {groups.map((g) => (
          <span className="inline-block border border-gray-500  rounded-lg">
            {g}
          </span>
        ))}
      </div>
    ),
    classStatus: "انجام شد",
    approve: <Approval />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "2امیر",
    description: 5,
    priority: 5,
    class: "دهم",
    topic: "احتمال",
    groups: (
      <div className="flex gap-2 flex-col">
        {groups.map((g) => (
          <span className="inline-block border border-gray-500  rounded-lg">
            {g}
          </span>
        ))}
      </div>
    ),
    classStatus: "انجام شد",
    approve: <Approval />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "2امیر",
    description: 5,
    priority: 5,
    class: "دهم",
    topic: "احتمال",
    groups: (
      <div className="flex gap-2 flex-col">
        {groups.map((g) => (
          <span className="inline-block border border-gray-500  rounded-lg">
            {g}
          </span>
        ))}
      </div>
    ),
    classStatus: "انجام شد",
    approve: <Approval />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "2امیر",
    description: 5,
    priority: 5,
    class: "دهم",
    topic: "احتمال",
    groups: (
      <div className="flex gap-2 flex-col">
        {groups.map((g) => (
          <span className="inline-block border border-gray-500  rounded-lg">
            {g}
          </span>
        ))}
      </div>
    ),
    classStatus: "انجام شد",
    approve: <Approval />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "2امیر",
    description: 5,
    priority: 5,
    class: "دهم",
    topic: "احتمال",
    groups: (
      <div className="flex gap-2 flex-col">
        {groups.map((g) => (
          <span className="inline-block border border-gray-500  rounded-lg">
            {g}
          </span>
        ))}
      </div>
    ),
    classStatus: "انجام شد",
    approve: <Approval />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "2امیر",
    description: 5,
    priority: 5,
    class: "دهم",
    topic: "احتمال",
    groups: (
      <div className="flex gap-2 flex-col">
        {groups.map((g) => (
          <span className="inline-block border border-gray-500  rounded-lg">
            {g}
          </span>
        ))}
      </div>
    ),
    classStatus: "انجام شد",
    approve: <Approval />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "2امیر",
    description: 5,
    priority: 5,
    class: "دهم",
    topic: "احتمال",
    groups: (
      <div className="flex gap-2 flex-col">
        {groups.map((g) => (
          <span className="inline-block border border-gray-500  rounded-lg">
            {g}
          </span>
        ))}
      </div>
    ),
    classStatus: "انجام شد",
    approve: <Approval />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  // Add more data objects as needed
];

const columns = [
  { id: "id", label: "id" },
  { id: "name", label: "نام" },
  { id: "description", label: "توضیحات" },
  { id: "priority", label: "اولویت" },
  { id: "class", label: "مقطع" },
  { id: "topic", label: "مبحث" },
  { id: "groups", label: "گروه" },
  { id: "classStatus", label: "وضعیت" },
  { id: "approve", label: "اعمال به کلاس" },
  { id: "edit", label: "ویرایش" },
  { id: "delete", label: "حذف" },
  // Add more columns as needed
];
