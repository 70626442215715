import React, { useState } from "react";
import { Card } from "../../../../shares/card/Card";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useFetch from "../../../../../hooks/useFetch";
import { toast } from "react-toastify";
import Loading from "../../../../../utils/Loading";

function CLassCard() {
  const [classInfoBox, setClassInfoBox] = useState();

  const fetchData = useFetch({
    method: "GET",
    url: `dashboard/classroom-box`,
    noHeader: false,
    trigger: true,
    setter: setClassInfoBox,
    // errMessage: () => toast.error("مشکلی در دریافت اطلاعات کلاس پیش آمده", { position: "bottom-left", rtl: true, autoClose: 2000 }),

  });

  return (<>
{fetchData.loading ? "" :

    <Card className="CLASS relative overflow-hidden px-[3.2rem] pt-[2.1rem] pb-[4.7rem]">
      <div className="">
        <h4 className="text-right">حضور در کلاس</h4>
      </div>
      <div className=" flex justify-evenly pt-[2.1rem]">
        <div className= " max-lg:hidden absolute bottom-[-3.5rem]  right-0 bg-class w-[14rem] h-[14rem]"></div>
        <div className="text-start pr-[2.22rem]">
          <h3 className="text-[2.4rem] text-red-500">
            {classInfoBox?.abscent_count} غیبت <span className="text-[1.4rem] text-black opacity-50">از {classInfoBox?.Total} جلسه</span>
          </h3>
          <h4 className="text-red-500 text-[1.2rem]  pt-[1.61rem]">
            {classInfoBox?.abscent_count > 3 ? (
              <>
                <ErrorOutlineIcon />
                شما بیش از 3 جلسه غیبت داشته‌اید
              </>
            ) : (
              ""
            )}

          </h4>
        </div>
      </div>
    </Card>
}
  </>
  );
}

export default CLassCard;
