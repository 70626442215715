import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CloseSharp } from "@mui/icons-material";
import { BtnAlternative, BtnPrimary } from "../../../../shares/button/Button";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import useFetch from "../../../../../hooks/useFetch";
import { Input, FormControl, FormHelperText } from "@mui/material";
import moment from "jalali-moment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

const DirectMoney = ({ close, get, edit, Id }) => {
  const [call, setCall] = useState({
    amout: "",
    payment_date: "",
    refrence_number: "",
    following_number: "",
    card_number: "",
    payment_method: "",
    bank: "",
    description: "",
    student: Id,
  });

  const newGroup = useFetch({
    method: "POST",
    url: `management/directmoney/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });

  const editMoney = useFetch({
    method: "PATCH",
    url: `management/directmoney/${edit}/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });

  const getMoney = useFetch({
    method: "GET",
    url: `management/directmoney/${edit}/`,
    noHeader: false,
    trigger: edit ? true : false,
    setter: setCall,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: call,
  });

  const onSubmit = (e) => {
    // e.preventDefault();

    if (edit) {
      editMoney.reFetch();
    } else {
      newGroup.reFetch();
    }
  };
  useEffect(() => {
    newGroup.status === 201 && close();
    editMoney.status === 200 && close();
  }, [newGroup.status || editMoney.status]);

  return (
    <div className="relative rounded-2xl w-2/5 bg-white">
      <CloseSharp
        className="absolute top-2 right-[6px] cursor-pointer"
        onClick={close}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pt-16 pb-10 px-14 text-xl overflow-scroll max-h-[85vh]"
      >
        <h6 className="font-extrabold text-2xl border-b-2 w-full pb-4 mb-6">
          {edit ? "ویرایش پرداخت" : "پرداخت جدید"}
        </h6>
        <div className="mb-6">
          <label htmlFor="amout" className="block mb-1">
            مبلغ
          </label>
          <TextField
            name="amout"
            type={"text"}
            value={call.amout}
            placeholder="مبلغ"
            {...register("amout", {
              required: "مبلغ را وارد کنید",
            })}
            error={!!errors.amout}
            helperText={errors.amout ? errors.amout.message : ""}
            onChange={(e) => {
              setCall({ ...call, amout: e.target.value });
            }}
            fullWidth
          />
        </div>
        <div className="mb-6">
          <label htmlFor="following_number" className="block mb-1">
            شماره پیگیری
          </label>
          <TextField
            name="following_number"
            type={"text"}
            value={call.following_number}
            placeholder="شماره پیگیری"
            {...register("following_number", {
              required: "شماره پیگیری را وارد کنید",
            })}
            error={!!errors.following_number}
            helperText={
              errors.following_number ? errors.following_number.message : ""
            }
            onChange={(e) => {
              setCall({ ...call, following_number: e.target.value });
            }}
            fullWidth
          />
        </div>
        <div className="mb-6">
          <label htmlFor="refrence_number" className="block mb-1">
            شماره مرجع
          </label>
          <TextField
            name="refrence_number"
            type={"text"}
            value={call.refrence_number}
            placeholder="شماره مرجع"
            {...register("refrence_number", {
              required: "شماره مرجع را وارد کنید",
            })}
            error={!!errors.refrence_number}
            helperText={
              errors.refrence_number ? errors.refrence_number.message : ""
            }
            onChange={(e) => {
              setCall({ ...call, refrence_number: e.target.value });
            }}
            fullWidth
          />
        </div>

        <div className="mb-6">
          <label htmlFor="card_number" className="block mb-1">
            چهار رقم آخر کارت
          </label>
          <TextField
            name="card_number"
            type="text"
            value={call.card_number}
            placeholder="چهار رقم آخر کارت"
            {...register("card_number", {
              required: "وارد کنید",
            })}
            error={!!errors.card_number}
            helperText={errors.card_number ? errors.card_number.message : ""}
            onChange={(e) => {
              setCall({ ...call, card_number: e.target.value.toString() });
            }}
            fullWidth
          />
        </div>
        <div className="mb-6">
          <label htmlFor="payment_method" className="block mb-1">
            روش پرداخت
          </label>
          <TextField
            name="payment_method"
            value={call.payment_method}
            placeholder="روش پرداخت"
            {...register("payment_method", {
              required: "روش پرداخت را وارد کنید",
            })}
            error={!!errors.payment_method}
            helperText={
              errors.payment_method ? errors.payment_method.message : ""
            }
            onChange={(e) => {
              setCall({ ...call, payment_method: e.target.value });
            }}
            fullWidth
          />
        </div>
        <div className="mb-6">
          <label htmlFor="bank" className="block mb-1">
            بانک
          </label>
          <TextField
            name="bank"
            value={call.bank}
            placeholder="بانک"
            {...register("bank", {
              required: "بانک را وارد کنید",
            })}
            error={!!errors.bank}
            helperText={errors.bank ? errors.bank.message : ""}
            onChange={(e) => {
              setCall({ ...call, bank: e.target.value });
            }}
            fullWidth
          />
        </div>
        <div className="mb-6">
          <label htmlFor="payment_date" className="block mb-1">
            تاریخ
          </label>
          <FormControl
            error={errors.assignment_available_time_end ? true : false}
            fullWidth
          >
            <Controller
              name="day"
              control={control}
              render={() => (
                <MobileDatePicker
                  label={
                    call?.payment_date
                      ? moment(call.payment_date, "YYYY/MM/DD")
                          .locale("fa")
                          .format("YYYY/MM/DD")
                      : "تاریخ"
                  }
                  onAccept={(e) =>
                    setCall({
                      ...call,
                      payment_date: moment(e).format("YYYY-MM-DD"),
                    })
                  }
                  // onChange={}
                  views={["year", "month", "day"]}
                  className="!text-[5rem]"
                  ampm={false}
                  slotProps={{
                    field: { shouldRespectLeadingZeros: true },
                    toolbar: { hidden: true },
                    tabs: { hidden: true },
                  }}
                  sx={{
                    "& .PrivatePickersClockNumber-clockNumber": {
                      fontSize: 50,
                    },
                  }}
                />
              )}
            />

            <FormHelperText>{errors?.payment_date?.message}</FormHelperText>
          </FormControl>
        </div>
        <div className="mb-6">
          <label htmlFor="description" className="block mb-1">
            توضیحات
          </label>
          <TextField
            name="description"
            value={call.description}
            placeholder="توضیحات"
            {...register("description", {})}
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ""}
            onChange={(e) => {
              setCall({ ...call, description: e.target.value });
            }}
            fullWidth
          />
        </div>
        <div className="mr-auto ml-0 w-fit">
          <button
            type="submit"
            className="text-white !bg-[#8200d3] hover:bg-[#8200d3] transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 "
          >
            ثبت تراکنش
          </button>
        </div>
      </form>
    </div>
  );
};

export default DirectMoney;

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
