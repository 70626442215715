import React, { useEffect, useState } from "react";

import Header from "./Header";
import CurrentExam from "./cards/CurrentExam";
import PreviousExam from "./cards/PreviousExam";
import useFetch from "../../../../hooks/useFetch";
import { toast } from "react-toastify";
import Loading from "../../../../utils/Loading";

function Index() {
  const [examInfo, setExamInfo] = useState();
  const [examCurrent, setExamCurrent] = useState();
  const [examPerv, setExamPerv] = useState();

  const fetchData = useFetch({
    method: "GET",
    url: `exam-platform/`,
    noHeader: false,
    trigger: true,
    setter: setExamInfo,
    errMessage: () =>
      toast.error("مشکلی در دریافت اطلاعات پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
  });

  const examCheck = () => {};

  useEffect(() => {
    setExamPerv(
      examInfo?.filter(
        (exam) =>
          exam.exam_permission === false ||
          exam.score.exam_finished ||
          exam.ExamFinished === true
      )
    );
    setExamCurrent(
      examInfo?.filter(
        (exam) =>
          exam.exam_permission === true &&
          !exam.score.exam_finished &&
          exam.ExamFinished === false
      )
    );
  }, [examInfo]);

  return (
    <div className="exams">
      <Header />
      {/* <div className="container"> */}
      {fetchData.loading ? (
        <Loading />
      ) : (
        <>
          <div className="current-exam">
            {examCurrent?.map((exam, index) => (
              <CurrentExam exam={exam} index={index} />
            ))}
          </div>

          <div className="previous-exam mt-[4rem] overflow-y-scroll ">
            <h3 className="pb-[2rem]">امتحانات قبلی</h3>
            {examPerv?.map((exam, index) => (
              <PreviousExam exam={exam} index={index} />
            ))}
          </div>
        </>
      )}
      {/* </div> */}
    </div>
  );
}

export default Index;
