import React, { useState, useEffect, useMemo } from "react";
import { Card } from "../../../shares/card/Card";
import ShareTable from "../../../shares/tables/ShareTable";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  DeleteOutline,
  EditOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import { BtnPrimary } from "../../../shares/button/Button";
import { Modal } from "@mui/material";
import AddGroups from "./modals/AddGroups";
import useFetch from "../../../../hooks/useFetch";
import Search from "../../../shares/search/Search";

const Groups = () => {
  const [filterChoice, setFilterChoice] = useState("همه");
  const [classFilter, setClassFilter] = useState("");
  const [newModal, setNewModal] = useState(false);
  const [edit, setEdit] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [groups, setGroups] = useState();
  const [newGroups, setNewGroups] = useState();
  const [id, setId] = useState(null);
  const [examId, setExamId] = useState(null);
  const [assignmentId, setAssignmentId] = useState(null);
  const [isLoad, setIsLoad] = useState(false);

  const handleClose = () => {
    setNewModal(false);
    setEdit(null);
    setDeleteModal(false);
  };

  const handleEdit = (id) => {
    setNewModal(true);
    setEdit(id);
  };

  const getGroup = useFetch({
    method: "GET",
    url: `management/groups/`,
    noHeader: false,
    trigger: true,
    setter: setGroups,
  });

  const deleteGroup = useFetch({
    method: "DELETE",
    url: `management/groups/${id}/`,
    noHeader: false,
    trigger: false,
    caller: getGroup,
  });

  useEffect(() => {
    examId &&
      window.open(
        `https://lab.mindescape.co/management/groups/${examId}/export_exam_scores/`,
        "_blank"
      );
  }, [examId]);

  useEffect(() => {
    assignmentId &&
      window.open(
        `https://lab.mindescape.co/management/groups/${assignmentId}/export_assignment_scores/`,
        "_blank"
      );
  }, [assignmentId]);

  useMemo(() => {
    setNewGroups(
      groups?.results.map((group) => {
        const newGroup = {
          ...group,
          exam: <FileDownloadOutlined onClick={() => setExamId(group.id)} />,
          homework: (
            <FileDownloadOutlined onClick={() => setAssignmentId(group.id)} />
          ),
          edit: (
            <EditOutlined
              onClick={() => handleEdit(group.id)}
              className="cursor-pointer"
            />
          ),
          delete: (
            <DeleteOutline
              onClick={() => setId(group.id)}
              className="cursor-pointer"
              color="admin"
            />
          ),
        };

        return newGroup;
      })
    );
  }, [groups]);

  useEffect(() => {
    id && deleteGroup.reFetch();
  }, [id]);

  return (
    <div className="flex mt-20">
      <Card className="p-16 w-full text-right">
        <div className="flex justify-between items-center border-b-2 border-gray-200 pb-8 mb-12">
          <h3 className="text-3xl">گروه‌ها</h3>
          <BtnPrimary
            text="+ افزودن گروه جدید"
            className="px-6 py-4 !bg-[#8200d3] hover:bg-[#8200d3]"
            onClick={() => {
              setNewModal(true);
            }}
          />
        </div>
        <div className="flex items-center mb-6">
          <Search newData={setGroups} url={"groups"} />
        </div>

        <ShareTable
          data={
            newGroups && classFilter === ""
              ? newGroups
              : newGroups && classFilter !== ""
              ? classFilter
              : []
          }
          columns={columns}
          next={
            groups?.next
              ? groups?.next?.replace(/https:\/\/lab\.mindescape\.co\//, "")
              : null
          }
          perv={
            groups?.previous
              ? groups?.previous?.replace(/https:\/\/lab\.mindescape\.co\//, "")
              : null
          }
          newData={setGroups}
          getLoad={getGroup.loading}
        />
      </Card>
      <Modal
        open={newModal}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AddGroups close={handleClose} get={getGroup} edit={edit} />
      </Modal>
    </div>
  );
};

export default Groups;

const data = [
  {
    id: 1,
    name: "امیر",
    age: 25,
    start: "27 بهمن 1400",
    cap: 32,
    class: "یازدهم",
    exam: <DownloadIcon onClick={() => console.log("hi")} />,
    homework: <DownloadIcon />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "امیر2",
    age: 30,
    start: "27 بهمن 1400",
    cap: 32,
    class: "یازدهم",
    exam: <DownloadIcon onClick={() => console.log("hi")} />,
    homework: <DownloadIcon />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  // Add more data objects as needed
];

const columns = [
  { id: "id", label: "id" },
  { id: "name", label: "نام" },
  { id: "group_time", label: "زمان شروع" },
  { id: "group_grade", label: "مقطع" },
  { id: "exam", label: "اکسل امتحانات" },
  { id: "homework", label: "اکسل تکالیف" },
  { id: "edit", label: "ویرایش" },
  { id: "delete", label: "حذف" },
  // Add more columns as needed
];
