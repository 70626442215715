import React from "react";
import { Stepper, Step, StepLabel, Button } from "@mui/material";
import clsx from "clsx";

const ShareStepper = ({ steps, activeStep, onNext, onBack }) => {
  const handleNext = () => {
    onNext();
  };

  const handleBack = () => {
    onBack();
  };

  const isLastStep = activeStep === steps.length - 1;

  return (
    <div className=" w-2/3 mx-auto">
      <Stepper activeStep={activeStep} className="bg-white">
        {steps.map((label, index) => (
          <Step key={index} color="secondary">
            <StepLabel className="text-purple-500" />
          </Step>
        ))}
      </Stepper>

      <style jsx>{`
        .MuiStepIcon-root {
          color: #8200d3;
          width: 30px;
          height: 30px;
        }

        .Mui-active {
          color: #8200d3 !important;
        }

        .Mui-completed {
          color: #8200d3 !important;
        }
      `}</style>
    </div>
  );
};

export default ShareStepper;
