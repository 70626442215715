import React from "react";
import VideocamIcon from "@mui/icons-material/Videocam";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Logo from "../../../../../assets/img/logo.png";
import Wallet from "../../../../../assets/img/icons/Wallet-2.svg";
import { Link, NavLink, useMatch, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../contexts/AuthContext";
import ResRightSidebar from "./ResRightSidebar";
import { AccountCircleSharp } from "@mui/icons-material";

function RightSidebar() {
  const homeMatch = useMatch("/dashboard/home");
  const sessionMatch = useMatch("/dashboard/classes");
  const examMatch = useMatch("/dashboard/exams");
  const homeWorkMatch = useMatch("/dashboard/homework");
  const booksMatch = useMatch("/dashboard/books");
  const rulesMatch = useMatch("/dashboard/rules");
  const navigate = useNavigate();

  const { loginDispatch } = useAuth();

  const logoutFunc = () => {
    loginDispatch({
      type: "LOGOUT",
    });
    navigate("/");
  };

  return (
    <>
      <div className="inline-block md:hidden">
        <ResRightSidebar />
      </div>

      <div className="h-auto hidden md:inline-block ">
        <aside
          id="default-sidebar"
          aria-label="Sidebar"
          style={{}}
          className={`h-full sidebarRight  text-[#A5B0C2] bg-white transition-transform -translate-x-full sm:translate-x-0  `}
        >
          <div className="sidebarRight-wrapper pr-8 pl-24 ">
            <div className="sidebarRight-container">
              <div className="logo-container pt-[3.27rem]">
                <Link to="/" replace>
                  <img className="logo" src={Logo}></img>
                </Link>
              </div>

              <div className="nav-container py-52">
                <ul className="nav-list flex flex-col gap-y-11 ">
                  <li>
                    <NavLink exact to={"/dashboard/home"} className={`flex`}>
                      <AccountBalanceWalletIcon
                        className={`ml-[1.77rem]`}
                        sx={{
                          color: homeMatch ? "blue" : "#A5B0C4",
                          fontSize: 20,
                        }}
                      />
                      داشبورد کاربر
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to={"/dashboard/classes"} className={`flex`}>
                      <VideocamIcon
                        className={`ml-[1.77rem]`}
                        sx={{
                          color: sessionMatch ? "blue" : "#A5B0C4",
                          fontSize: 20,
                        }}
                      />
                      جلسات
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to={"/dashboard/exams"} className={`flex`}>
                      <TextSnippetIcon
                        className={`ml-[1.77rem]`}
                        sx={{
                          color: examMatch ? "blue" : "#A5B0C4",
                          fontSize: 20,
                        }}
                      />
                      امتحانات
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to={"/dashboard/homework"}
                      className={`flex`}
                    >
                      <DriveFileRenameOutlineIcon
                        className={`ml-[1.77rem]`}
                        sx={{
                          color: homeWorkMatch ? "blue" : "#A5B0C4",
                          fontSize: 20,
                        }}
                      />
                      تکالیف
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink exact to={"/dashboard/books"} className={`flex`}>
                      <AccountCircleSharp
                        className={`ml-[1.77rem]`}
                        sx={{
                          color: booksMatch ? "blue" : "#A5B0C4",
                          fontSize: 20,
                        }}
                      />
                      جزوه
                    </NavLink>
                  </li> */}
                  <hr />
                  <li className="">
                    <NavLink exact to={"/dashboard/rules"} className={`flex`}>
                      <VerifiedUserIcon
                        className={`ml-[1.77rem]`}
                        sx={{
                          color: rulesMatch ? "blue" : "#A5B0C4",
                          fontSize: 20,
                        }}
                      />
                      قوانین
                    </NavLink>
                  </li>
                  <li onClick={logoutFunc}>
                    <a className="text-red-500">
                      <LogoutRoundedIcon
                        className="ml-[1.77rem]"
                        sx={{ color: "#EF4444", fontSize: 20 }}
                      />{" "}
                      خروج
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </>
  );
}

export default RightSidebar;
