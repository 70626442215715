import React, { useState, useEffect } from "react";
import { CloseSharp } from "@mui/icons-material";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";

const ExamInfo = ({ close, get, Id, data }) => {
  const items = [...Array(10).keys()];

  return (
    <div className="relative rounded-2xl w-2/5 bg-white">
      <CloseSharp
        className="absolute top-2 right-[6px] cursor-pointer"
        onClick={close}
      />
      <div className="flex flex-col pt-12 pb-2 px-14 overflow-scroll max-h-[80vh]">
        <div className="text-3xl border-b-2 pb-2 border-[#8300d3] flex justify-between">
          <span className="">امتحان {data.exam.ExamName}</span>
          <span className="">نمره {data.score}</span>
        </div>
        <div className="text-xl mb-4 border-b-2 border-r-2 border-l-2 py-2 border-[#8300d3] flex ">
          <span className="border-l  text-center border-[#8300d3] w-1/3">
            صحیح: {10 - (data.wrong_counts + data.none_counts)}
          </span>
          <span className="w-1/3 text-center">غلط: {data.wrong_counts}</span>
          <span className="border-r  text-center border-[#8300d3] w-1/3">
            بی‌پاسخ: {data.none_counts}
          </span>
        </div>
        {items.map((item, index) => (
          <>
            <RadioGroup
              key={item}
              className="border-b border-black pb-4 md:pb-0  !flex-row items-center justify-around"
              defaultValue={data.user_choice[index]}
              disabled={true}
            >
              <span className="text-xl">سوال {index + 1}</span>
              <FormControlLabel
                className="pointer-events-none"
                value="1"
                control={<Radio />}
                label={"1"}
              />
              <FormControlLabel
                className="pointer-events-none"
                value="2"
                control={<Radio />}
                label="2"
              />
              <FormControlLabel
                className="pointer-events-none"
                value="3"
                control={<Radio />}
                label="3"
              />
              <FormControlLabel
                className="pointer-events-none"
                value="4"
                control={<Radio />}
                label="4"
              />
            </RadioGroup>
          </>
        ))}
      </div>
    </div>
  );
};

export default ExamInfo;
