import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Avatar,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Modal,
} from "@mui/material";
import { Card } from "../../../shares/card/Card";
import useFetch from "../../../../hooks/useFetch";
import { toast } from "react-toastify";
import {
  Camera,
  CameraAlt,
  CameraAltOutlined,
  Edit,
} from "@mui/icons-material";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { BtnPrimary } from "../../../shares/button/Button";
import Cookies from "js-cookie";
import Loading from "../../../../utils/Loading";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import ShareTable from "../../../shares/tables/ShareTable";
import ShareChart from "../../../shares/chart/ShareChart";
import DirectMoney from "./modals/DirectMoney";
import SigendCheck from "./modals/SignedCheck";
import moment from "jalali-moment";
import Password from "./modals/Password";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NumberFormat from "../../../shares/NumberFormat/NumberFormat";
import BooksAssign from "./BooksAssign";
import ExamScore from "./ExamScore";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

const Profile = () => {
  const num = [5, 4, 4, 5, 6];
  const navigate = useNavigate();
  const [moneyModal, setMoneyModal] = useState("");
  const [examData, setExamData] = useState([]);
  const [id, setId] = useState("");
  const [idCheck, setIdCheck] = useState("");
  const [edit, setEdit] = useState("");
  const [editCheck, setEditCheck] = useState("");
  const [groups, setGroups] = useState();
  const [avatar, setAvatar] = useState("");
  const [profileData, setProfileData] = useState({
    id: "",
    username: "",
    first_name: "",
    last_name: "",
    groups: [],
    studentuser: {
      father_name: "",
      avatar: "",
      registration_date: "",
      phone_number: "",
      home_number: "",
      father_number: "",
      mother_number: "",
      address: "",
      student_school: "",
      registration_date: "",
      average_exam: "",
      average_assignment: "",
      student_type: "",
      student_gender: "",
      student_grade: "",
    },
    directmoney_set: [],
    signedcheck_set: [],
    examaverage: {
      examscore_set: [],
    },
  });
  const [call, setCall] = useState();
  const [newData, setNewData] = useState();
  const [newCheck, setNewCheck] = useState();
  const [fileState, setFileState] = useState();
  const [editable, setEditable] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [directMoney, setDirectMoney] = useState(false);
  const [signedCheck, setSignedCheck] = useState(false);
  const userId = null;
  const { Id } = useParams();

  const handleClose = () => {
    setDirectMoney(false);
    setSignedCheck(false);
    setPasswordModal(false);
  };
  const fetchData = useFetch({
    method: "GET",
    url: `management/users/${Id}/`,
    noHeader: false,
    trigger: true,
    setter: setProfileData,
    errMessage: () =>
      toast.error("مشکلی در دریافت اطلاعات پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
  });

  const patchData = useFetch({
    method: "PATCH",
    url: `management/users/${Id}/`,
    noHeader: false,
    trigger: false,
    data: profileData,
    errMessage: () =>
      toast.error("مشکلی در ویرایش پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
  });
  const deleteUser = useFetch({
    method: "DELETE",
    url: `management/users/${Id}/`,
    noHeader: false,
    trigger: false,
    errMessage: () =>
      toast.error("مشکلی در حذف کاربر پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
  });

  const deleteMoney = useFetch({
    method: "DELETE",
    url: `management/directmoney/${id}/`,
    noHeader: false,
    trigger: false,
    errMessage: () =>
      toast.error("مشکلی در حذف پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
    caller: fetchData,
  });
  const deleteCheck = useFetch({
    method: "DELETE",
    url: `management/signedcheck/${idCheck}/`,
    noHeader: false,
    trigger: false,
    errMessage: () =>
      toast.error("مشکلی در حذف پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
    caller: fetchData,
  });
  const download = useFetch({
    method: "GET",
    url: `management/users/${Id}/classroom_presence_summary/`,
    noHeader: false,
    trigger: false,
    setter: setFileState,
    errMessage: () =>
      toast.error("مشکلی پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
  });
  const toggleUser = useFetch({
    method: "POST",
    url: `management/users/${Id}/toggle_activation/`,
    noHeader: false,
    trigger: false,
    errMessage: () =>
      toast.error("مشکلی پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
    caller: fetchData,
  });

  const getGroup = useFetch({
    method: "GET",
    url: `management/groups/?page_size=1000`,
    noHeader: false,
    trigger: true,
    setter: setGroups,
  });

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setAvatar(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  // };
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (e) => {
    // e.preventDefault();

    const formData = new FormData();

    formData.append("first_name", profileData.first_name);
    formData.append("last_name", profileData.last_name);
    formData.append("father_name", profileData?.studentuser?.father_name);
    formData.append(
      "registration_date",
      profileData?.studentuser?.registration_date
    );
    formData.append("phone_number", profileData?.studentuser?.phone_number);
    formData.append("home_number", profileData?.studentuser?.home_number);
    formData.append("father_number", profileData?.studentuser?.father_name);
    formData.append("mother_number", profileData?.studentuser?.mother_number);
    formData.append("address", profileData?.studentuser?.address);
    formData.append("student_school", profileData?.studentuser?.student_school);

    setCall(formData);
    patchData.reFetch();
    setEditable(false);
  };

  const handleEdit = (id) => {
    setDirectMoney(true);
    setEdit(id);
  };
  const handleEditCheck = (id) => {
    setSignedCheck(true);
    setEditCheck(id);
  };

  useEffect(() => {
    setNewData(
      profileData?.directmoney_set.map((data) => {
        const newData = {
          ...data,
          amout: <NumberFormat number={data.amout} />,
          description: <div className="max-w-48">{data.description}</div>,
          payment_date: moment(data.payment_date, "YYYY/MM/DD")
            .locale("fa")
            .format("YYYY/MM/DD"),
          edit: (
            <EditIcon
              onClick={() => handleEdit(data.id)}
              className="cursor-pointer"
            />
          ),
          delete: (
            <DeleteIcon
              onClick={() => setId(data.id)}
              className="cursor-pointer"
            />
          ),
        };
        return newData;
      })
    );
    setNewCheck(
      profileData?.signedcheck_set.map((data) => {
        const newData = {
          ...data,
          amout: <NumberFormat number={data.amout} />,
          payment_date: moment(data.check_date, "YYYY/MM/DD")
            .locale("fa")
            .format("YYYY/MM/DD"),
          description: <div className="max-w-48">{data.description}</div>,
          edit: (
            <EditIcon
              onClick={() => handleEditCheck(data.id)}
              className="cursor-pointer"
            />
          ),
          delete: (
            <DeleteIcon
              onClick={() => setIdCheck(data.id)}
              className="cursor-pointer"
            />
          ),
        };
        return newData;
      })
    );
  }, [profileData]);

  useEffect(() => {
    id && deleteMoney.reFetch();
  }, [id]);
  useEffect(() => {
    idCheck && deleteCheck.reFetch();
  }, [idCheck]);

  // useEffect(() => {
  //   setProfileData(profileData);
  // }, [profileData]);
  useEffect(() => {
    profileData.examaverage?.examscore_set?.map((i) => {
      setExamData((prevState) => [...prevState, i.score]);
      // console.log(i.score);
    });
  }, [profileData]);

  useEffect(() => {
    deleteUser.status === 204 &&
      toast.error("کاربر با موفقیت حذف شد!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
        rtl: true,
      });
  }, [deleteUser.status]);

  return (
    <div className="min-h-screen flex items-center justify-center w-full mt-20">
      {profileData?.is_active ? (
        <Card className="h-full w-full">
          <form
            className="flex flex-col w-full py-12 px-10 items-center gap-x-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-row w-full items-center relative">
              <div className="flex flex-col gap-y-4 absolute right-0">
                <BtnPrimary
                  text={"غیر فعال سازی"}
                  className="py-4 px-8 bg-red-500 hover:bg-red-500 active:bg-red-200"
                  onClick={() => toggleUser.reFetch()}
                />
                <BtnPrimary
                  text={"حذف کاربر"}
                  className="py-4 px-8 bg-red-500 hover:bg-red-500 active:bg-red-200"
                  onClick={() => deleteUser.reFetch()}
                />
                <BtnPrimary
                  text={"آمار تماشای ویدیو"}
                  className="py-4 px-8 !bg-[#8200d3] !hover:bg-[#8200d3] active:bg-red-200"
                  // onClick={() => download.reFetch()}
                  url={`https://lab.mindescape.co/management/users/${Id}/classroom_presence_summary/`}
                  target="blank"
                />
              </div>

              <label
                htmlFor="avatar-upload"
                className="mx-auto shadow-xl rounded-full"
              >
                <Avatar
                  alt="Avatar"
                  src={profileData?.studentuser?.avatar}
                  sx={{
                    width: "150px",
                    height: "150px",
                  }}
                />
                {/* <input
                  id="avatar-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleAvatarChange}
                  style={{ display: "none" }}
                /> */}
              </label>
              <div className=" absolute left-0">
                <IconButton onClick={() => setEditable(!editable)}>
                  <Edit fontSize="large" />
                </IconButton>
              </div>
            </div>

            <div className="grid mt-16 grid-cols-1 md:grid-cols-4 gap-x-10 gap-y-14 w-full">
              <TextField
                // defaultValue={profileData?.first_name}
                disabled={editable ? false : true}
                label="نام"
                variant="outlined"
                onChange={(e) =>
                  setProfileData({ ...profileData, first_name: e.target.value })
                }
                value={profileData.first_name}
                className="col-span-2"
              />
              <TextField
                disabled={editable ? false : true}
                label="نام خانوادگی"
                variant="outlined"
                value={profileData?.last_name}
                onChange={(e) =>
                  setProfileData({ ...profileData, last_name: e.target.value })
                }
                className="col-span-2"
              />
              <TextField
                // defaultValue={profileData?.first_name}
                disabled={editable ? false : true}
                label="کدملی"
                variant="outlined"
                onChange={(e) =>
                  setProfileData({ ...profileData, username: e.target.value })
                }
                value={profileData.username}
              />
              <TextField
                disabled={editable ? false : true}
                label="نام پدر"
                variant="outlined"
                value={profileData.studentuser?.father_name}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    studentuser: {
                      ...profileData.studentuser,
                      father_name: e.target.value,
                    },
                  })
                }
              />
              <MobileDatePicker
                label={
                  profileData?.studentuser?.registration_date
                    ? moment(
                        profileData?.studentuser?.registration_date,
                        "YYYY/MM/DD"
                      )
                        .locale("fa")
                        .format("YYYY/MM/DD")
                    : "تاریخ ثبت نام"
                }
                onAccept={(e) =>
                  setProfileData({
                    ...profileData,
                    studentuser: {
                      ...profileData.studentuser,
                      registration_date: moment(e).format("YYYY-MM-DD"),
                    },
                  })
                }
                // onChange={}
                views={["year", "month", "day"]}
                className="!text-[5rem]"
                ampm={false}
                slotProps={{
                  field: { shouldRespectLeadingZeros: true },
                  toolbar: { hidden: true },
                  tabs: { hidden: true },
                }}
                sx={{
                  "& .PrivatePickersClockNumber-clockNumber": {
                    fontSize: 50,
                  },
                }}
                disabled={true}
              />

              <TextField
                disabled={editable ? false : true}
                label="شماره موبایل"
                variant="outlined"
                value={profileData?.studentuser?.phone_number}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    studentuser: {
                      ...profileData.studentuser,
                      phone_number: e.target.value,
                    },
                  })
                }
              />
              <TextField
                disabled={editable ? false : true}
                label="شماره ثابت"
                variant="outlined"
                value={profileData?.studentuser?.home_number}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    studentuser: {
                      ...profileData.studentuser,
                      home_number: e.target.value,
                    },
                  })
                }
              />
              <TextField
                disabled={editable ? false : true}
                label="شماره پدر"
                variant="outlined"
                value={profileData?.studentuser?.father_number}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    studentuser: {
                      ...profileData.studentuser,
                      father_number: e.target.value,
                    },
                  })
                }
              />
              <TextField
                disabled={editable ? false : true}
                label="شماره مادر"
                variant="outlined"
                value={profileData?.studentuser?.mother_number}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    studentuser: {
                      ...profileData.studentuser,
                      mother_number: e.target.value,
                    },
                  })
                }
              />
              <TextField
                defaultValue={profileData?.studentuser?.address}
                disabled={editable ? false : true}
                label="آدرس"
                variant="outlined"
                value={profileData?.studentuser?.address}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    studentuser: {
                      ...profileData.studentuser,
                      address: e.target.value,
                    },
                  })
                }
              />
              <TextField
                defaultValue={profileData?.studentuser?.student_school}
                disabled={editable ? false : true}
                label="نام مدرسه"
                variant="outlined"
                value={profileData?.studentuser?.student_school}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    studentuser: {
                      ...profileData.studentuser,
                      student_school: e.target.value,
                    },
                  })
                }
              />
              <TextField
                defaultValue={profileData?.studentuser?.average_assignment}
                disabled={true}
                label="میانگین نمره تکلیف"
                variant="outlined"
                value={profileData?.studentuser?.average_assignment}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    studentuser: {
                      ...profileData.studentuser,
                      average_assignment: e.target.value,
                    },
                  })
                }
              />
              <TextField
                defaultValue={profileData?.studentuser?.average_exam}
                disabled={true}
                label="میانگین نمره امتحان"
                variant="outlined"
                value={profileData?.studentuser?.average_exam}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    studentuser: {
                      ...profileData.studentuser,
                      average_exam: e.target.value,
                    },
                  })
                }
              />

              <Select
                defaultValue={
                  profileData?.studentuser.student_gender
                    ? profileData?.studentuser.student_gender
                    : "000"
                }
                disabled={editable ? false : true}
                label="جنسیت"
                variant="outlined"
                // value={profileData?.studentuser?.student_gender}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    studentuser: {
                      ...profileData.studentuser,
                      student_gender: e.target.value,
                    },
                  })
                }
                className="text-right"
              >
                <MenuItem disabled defaultChecked value={"000"}>
                  جنسیت
                </MenuItem>
                <MenuItem value={"دختر"}>دختر</MenuItem>
                <MenuItem value={"پسر"}>پسر</MenuItem>
              </Select>

              <Select
                defaultValue={
                  profileData?.studentuser?.student_grade
                    ? profileData?.studentuser?.student_grade
                    : "000"
                }
                disabled={editable ? false : true}
                label="مقطع"
                variant="outlined"
                // value={profileData?.studentuser?.student_gender}
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    studentuser: {
                      ...profileData.studentuser,
                      student_grade: e.target.value,
                    },
                  })
                }
                className="text-right"
              >
                <MenuItem disabled defaultChecked value={"000"}>
                  مقطع
                </MenuItem>
                <MenuItem value={"دهم"}>دهم</MenuItem>
                <MenuItem value={"یازدهم"}>یازدهم</MenuItem>
                <MenuItem value={"دوازدهم"}>دوازدهم</MenuItem>
              </Select>

              <Select
                defaultValue={
                  profileData?.studentuser?.student_type
                    ? profileData?.studentuser?.student_type
                    : "000"
                }
                disabled={editable ? false : true}
                label="رشته"
                variant="outlined"
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    studentuser: {
                      ...profileData.studentuser,
                      student_type: e.target.value,
                    },
                  })
                }
                className="text-right"
              >
                <MenuItem disabled defaultChecked value={"000"}>
                  رشته
                </MenuItem>
                <MenuItem value={"ریاضی"}>ریاضی</MenuItem>
                <MenuItem value={"تجربی"}>تجربی</MenuItem>
                <MenuItem value={null}>بدون رشته</MenuItem>
              </Select>

              <Select
                label="رشته"
                variant="outlined"
                placeholder="گروه"
                disabled={editable ? false : true}
                defaultValue={
                  profileData?.groups[0] ? profileData?.groups[0] : "000"
                }
                onChange={(e) => {
                  setProfileData({
                    ...profileData,
                    groups: [e.target.value],
                  });
                }}
                className="text-right"
              >
                <MenuItem disabled defaultChecked value={"000"}>
                  گروه‌
                </MenuItem>
                {groups?.results.map((g) => {
                  return <MenuItem value={g.id}>{g.name}</MenuItem>;
                })}
              </Select>
            </div>
            <button
              type="submit"
              className={`text-white !bg-[#8200d3] hover:bg-[#8200d3] transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] w-fit py-[1.2rem] px-24  mt-10 self-end ${
                !editable ? "pointer-events-none opacity-50 border-none" : ""
              }`}
            >
              ثبت
            </button>
            <BtnPrimary
              className="py-[1.2rem] px-24 mt-10 self-end !bg-[#8200d3] hover:bg-[#8200d3]"
              text={"تغییر رمز عبور"}
              onClick={() => setPasswordModal(true)}
            />
          </form>
          <hr />
          <BooksAssign />
          <hr />
          <ExamScore />
          <hr />
          <div className="px-10 mt-16">
            <div className="flex justify-between items-center mb-8">
              <h5 className="text-right mb-8">تراکنش‌ها</h5>
              <BtnPrimary
                text="+ تراکنش جدید"
                className="px-6 py-4 !bg-[#8200d3] hover:bg-[#8200d3]"
                onClick={() => {
                  setDirectMoney(true);
                }}
              />
            </div>
            <ShareTable
              data={newData ? newData : []}
              columns={columnsMoney}
              pageShow={false}
            />
          </div>
          <hr />
          <div className="px-10 my-16">
            <div className="flex justify-between items-center mb-8">
              <h5 className="text-right mb-8">چک‌ها</h5>
              <BtnPrimary
                text="+ چک جدید"
                className="px-6 py-4 !bg-[#8200d3] hover:bg-[#8200d3]"
                onClick={() => {
                  setSignedCheck(true);
                }}
              />
            </div>
            <ShareTable
              data={newCheck ? newCheck : []}
              columns={columnsCheck}
              pageShow={false}
            />
          </div>
          <hr />
          <div className="px-10 mt-16 flex gap-8 w-full mb-8">
            <div className="w-full">
              <span>امتحانات</span>
              <ShareChart
                data={profileData?.examaverage?.examscore_set?.map((item) => {
                  return item.score;
                })}
                names={profileData?.examaverage?.examscore_set?.map((item) => {
                  return item.exam.ExamName;
                })}
              />
            </div>
            <div className="w-full">
              <span>تکالیف</span>
              <ShareChart
                data={profileData?.assignmentaverage?.assignmentscore_set?.map(
                  (item) => {
                    return item.score;
                  }
                )}
              />
            </div>
          </div>
          {/* <ShareChart data={num} /> */}
        </Card>
      ) : (
        <div className="flex flex-col text-center gap-y-4">
          <h6>کاربر {Id} فعال نیست!</h6>
          <span>نام: {profileData.first_name}</span>
          <span>نام خانوادگی: {profileData.last_name}</span>
          <BtnPrimary
            text={"فعال سازی"}
            className="py-4 px-8 !bg-[#8200d3] hover:bg-[#8200d3] active:bg-red-200"
            onClick={() => toggleUser.reFetch()}
          />
        </div>
      )}
      <Modal
        open={directMoney}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DirectMoney close={handleClose} get={fetchData} Id={Id} edit={edit} />
      </Modal>
      <Modal
        open={signedCheck}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SigendCheck
          close={handleClose}
          get={fetchData}
          Id={Id}
          edit={editCheck}
        />
      </Modal>
      <Modal
        open={passwordModal}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Password close={handleClose} Id={profileData?.username} />
      </Modal>
    </div>
  );
};
const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export default Profile;

const columnsMoney = [
  // { id: "id", label: "id" },
  { id: "id", label: "ID" },
  { id: "amout", label: "مبلغ" },
  { id: "payment_method", label: "نحوه پرداخت" },
  { id: "bank", label: "بانک" },
  { id: "card_number", label: "شماره کارت" },
  { id: "refrence_number", label: "شماره مرجع" },
  { id: "following_number", label: "شماره پیگیری" },
  { id: "description", label: "توضیحات" },
  { id: "payment_date", label: "تاریخ" },
  { id: "edit", label: "ویرایش" },
  { id: "delete", label: "حذف" },

  // Add more columns as needed
];
const columnsCheck = [
  // { id: "id", label: "id" },
  { id: "id", label: "ID" },
  { id: "amout", label: "مبلغ" },
  { id: "check_number", label: "شماره چک" },
  { id: "bank", label: "بانک" },
  { id: "payment_date", label: "تاریخ" },
  { id: "description", label: "توضیحات" },
  { id: "edit", label: "ویرایش" },
  { id: "delete", label: "حذف" },

  // Add more columns as needed
];

const chartData = [30, 40, 45, 50, 49, 60, 70, 91, 125];
