import { CircularProgress } from "@mui/material";
import React from "react";

const Loading = ({ className, admin }) => {
  return (
    <div className={`flex place-content-center items-center ${className} `}>
      <CircularProgress
        style={{ width: "70px", height: "70px" }}
        color={admin ? "secondary" : "primary"}
      />
    </div>
  );
};

export default Loading;
