import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { CloseSharp } from "@mui/icons-material";
import useFetch from "../../../../../hooks/useFetch";
import { Input, FormControl, FormHelperText } from "@mui/material";

const AddBooks = ({ close, get, edit }) => {
  const [call, setCall] = useState({
    booksname: "",
    file: "",
  });

  const [defaultTime, setDefaultTime] = useState();

  const newGroup = useFetch({
    method: "POST",
    url: `management/books/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });
  const editGroup = useFetch({
    method: "PATCH",
    url: `management/books/${edit}/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });
  const getGroup = useFetch({
    method: "GET",
    url: `management/books/${edit}/`,
    noHeader: false,
    trigger: edit ? true : false,
    setter: setCall,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: call,
  });

  const questionRef = useRef(null);

  const handleClickQuestion = () => {
    questionRef.current.click();
  };

  const onSubmit = (e) => {
    // e.preventDefault();

    const formData = new FormData();

    formData.append("booksname", call.booksname);
    formData.append("file", call.file);

    setCall(formData);
    if (edit) {
      editGroup.reFetch();
    } else {
      newGroup.reFetch();
    }
  };
  useEffect(() => {
    newGroup.status === 201 && close();
  }, [newGroup.status]);
  useEffect(() => {
    editGroup.status === 200 && close();
  }, [editGroup.status]);

  return (
    <div className="relative rounded-2xl w-2/5 bg-white">
      <CloseSharp
        className="absolute top-2 right-[6px] cursor-pointer"
        onClick={close}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pt-16 pb-10 px-14 text-xl"
      >
        <h6 className="font-extrabold text-2xl border-b-2 w-full pb-4 mb-6">
          {edit ? "ویرایش جزوه" : "جزوه جدید"}
        </h6>
        <div className="flex gap-x-6 w-full">
          <div className="flex items-end justify-center w-full">
            <a
              className={`text-black border-2 border-[#8200d3] hover:bg-[#00000009] bg-white text-[1.4rem] rounded-[1.2rem] w-full py-[16.5px] px-8 text-center mb-4 ${
                call.assignment_file &&
                " !bg-[#8200d3] !text-white hover:!text-black"
              }`}
              onClick={handleClickQuestion}
            >
              {call.file ? "بارگزاری شده" : "بارگزاری جزوه"}
            </a>
            <input
              type="file"
              ref={questionRef}
              onChange={(e) => setCall({ ...call, file: e.target.files[0] })}
              required={edit ? false : true}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div className="mb-6">
          <label htmlFor="booksname" className="block mb-1">
            عنوان
          </label>
          <TextField
            name="booksname"
            value={call.booksname}
            placeholder="عنوان"
            {...register("booksname", {
              required: "عنوان را وارد کنید",
            })}
            error={!!errors.booksname}
            helperText={errors.booksname ? errors.booksname.message : ""}
            onChange={(e) => {
              setCall({ ...call, booksname: e.target.value });
            }}
            fullWidth
          />
        </div>

        <div className="mr-auto ml-0 w-fit">
          <button
            type="submit"
            className="text-white bg-[#8200d3] transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 "
          >
            ثبت جزوه
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddBooks;

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
