import React, { Fragment, useState } from "react";
import RightSidebar from "../../components/layouts/admin/Sidebars/rightSidebar/RightSidebar";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMomentJalaali } from "@mui/x-date-pickers/AdapterMomentJalaali";
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";

function Admin() {
  const { Id } = useParams();
  return (
    <div className="admin text-2xl flex min-h-screen">
      {/* <LocalizationProvider dateAdapter={AdapterDateFnsJalali}> */}
      <div className="min-h-screen w-1/5">
        <RightSidebar />
      </div>
      <div className="main-wrapper pb-[3rem] w-full h-auto">
        <div className=" px-16 overflow-y-auto">
          <div className="main">
            <Outlet />
          </div>
        </div>
      </div>
      {/* </LocalizationProvider> */}
    </div>
  );
}

export default Admin;
