import React from "react";

function BtnPrimary({ text, onClick, type, isDisabled, icon, url, download, target, ...props }) {
  return (
    <a
      download={download}
      href={url}
      id="btn-primary"
      type={type}
      onClick={onClick}
      className={` text-white bg-blue hover:bg-blueHover transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] ${props.className}`}
      disabled={isDisabled}
      target={target}
    >
      {icon && <img className="inline ml-2" src={icon} />} {text}
    </a>
  );
}

export { BtnPrimary };

function BtnAlternative({ text, onClick, icon, url, download, isDisabled, ...props }) {
  return (
    <a
      download={download}
      href={url}
      id="btn-alternative"
      type="button"
      // disabled={true}
      onClick={onClick}
      className={`${props.className} flex items-center bg-transparent  hover:bg-[#00000009] active:bg-[#0000001b] transition-all focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] ${isDisabled && "pointer-events-none cursor-not-allowed"}`}
    >
      {icon && <img className="inline ml-2" src={icon} />} {text}
    </a>
  );
}

export { BtnAlternative };
