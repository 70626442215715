import React, {
  createContext,
  useReducer,
  useState,
  useContext,
  useEffect,
} from "react";
import { authReducer } from "../reducers/AuthReducer";
import Cookies from "js-cookie";

const AuthContext = createContext();

const AuthProvider = (props) => {
  const [isLogin, loginDispatch] = useReducer(authReducer, false);
  const [admin, setAdmin] = useState(Cookies.get("memo_cache"));

  useEffect(() => {
    Cookies.set("memo_cache", admin);
  }, [admin]);

  return (
    <AuthContext.Provider
      value={{
        isLogin,
        loginDispatch,
        admin,
        setAdmin,
      }}
      {...props}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
