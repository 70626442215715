import React from "react";
import Chart from "react-apexcharts";

const ShareChart = (props) => {
  const { data, names } = props;

  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      width: "100%",
    },
    xaxis: {
      categories: names,
      labels: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "%";
      },
    },
    colors: ["#8200d3"],
  };

  const series = [
    {
      name: "نمره",
      data,
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="line" height={400} />
    </div>
  );
};

export default ShareChart;
