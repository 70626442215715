import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CloseSharp } from "@mui/icons-material";
import useFetch from "../../../../../hooks/useFetch";
import { FormControl, FormHelperText } from "@mui/material";
import ShareStepper from "../../../../shares/stepper/Stepper";
import moment from "jalali-moment";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

const AddHomework = ({ close, get, edit }) => {
  const [call, setCall] = useState({
    AssignmentName: "",
    assignment_headline: "",
    assignment_description: "",
    assignment_available_time_start: "",
    assignment_available_time_end: "",
    assignment_group: { id: null },
    assignment_extra_score: "",
    assignment_gender: "",
    assignment_file: "",
    assignment_answer_file: "",
  });
  const [groups, setGroups] = useState();
  const [test, setTest] = useState();
  const [activeStep, setActiveStep] = useState(0);
  // console.log(test);

  const handleNext = (event) => {
    event.preventDefault();
    setActiveStep((prevStep) => prevStep + 1);
  };

  const onSubmit = () => {
    const formData = new FormData();
    formData.append("AssignmentName", call.AssignmentName);
    formData.append("assignment_headline", call.assignment_headline);
    formData.append("assignment_description", call.assignment_description);
    formData.append(
      "assignment_available_time_start",
      call.assignment_available_time_start
    );
    formData.append("assignment_group", call.assignment_group.id);
    formData.append(
      "assignment_available_time_end",
      call.assignment_available_time_end
    );
    formData.append("assignment_extra_score", call.assignment_extra_score);
    formData.append("assignment_gender", call.assignment_gender);
    if (typeof call.assignment_file == "object") {
      formData.append("assignment_file", call.assignment_file);
    }
    if (typeof call.assignment_answer_file == "object") {
      formData.append("assignment_answer_file", call.assignment_answer_file);
    }
    setCall(formData);
    if (edit) {
      editAssignment.reFetch();
    } else {
      newAssignment.reFetch();
    }
  };

  const getGroup = useFetch({
    method: "GET",
    url: `management/groups/?page_size=1000`,
    noHeader: false,
    trigger: true,
    setter: setGroups,
  });

  const newAssignment = useFetch({
    method: "POST",
    url: `management/assignments/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });

  const editAssignment = useFetch({
    method: "PATCH",
    url: `management/assignments/${edit}/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });

  const getAssignment = useFetch({
    method: "GET",
    url: `management/assignments/${edit}/`,
    noHeader: false,
    trigger: edit ? true : false,
    setter: setCall,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: call,
  });

  const steps = ["مرحله اول", "مرحله دوم"];
  const isLastStep = activeStep === steps.length - 1;

  useEffect(() => {
    newAssignment.status === 201 && close();
  }, [newAssignment.status]);

  useEffect(() => {
    editAssignment.status === 200 && close();
  }, [editAssignment.status]);

  const questionRef = useRef(null);
  const answerRef = useRef(null);

  const handleClickQuestion = () => {
    questionRef.current.click();
  };
  const handleClickAnswer = () => {
    answerRef.current.click();
  };

  return (
    <div className="relative rounded-2xl w-2/5 bg-white pt-12">
      <CloseSharp
        className="absolute top-6 right-6 cursor-pointer"
        onClick={close}
      />
      <ShareStepper steps={steps} activeStep={activeStep} onNext={handleNext} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pt-8 pb-10 px-14 text-xl overflow-scroll max-h-[85vh]"
      >
        {activeStep === 0 && (
          <>
            <h6 className="font-extrabold text-2xl border-b-2 w-full pb-4 mb-6">
              {edit ? "ویرایش تکلیف" : "تکلیف جدید"}
            </h6>
            <div className="flex gap-x-6 w-full">
              <div className="flex items-end justify-center w-full">
                <a
                  className={`text-black border-2 border-[#8200d3] hover:bg-[#00000009] bg-white text-[1.4rem] rounded-[1.2rem] w-full py-[16.5px] px-8 ${
                    call.assignment_file &&
                    " !bg-[#8200d3] !text-white hover:!text-black"
                  }`}
                  onClick={handleClickQuestion}
                >
                  {call.assignment_file ? "بارگزاری شده" : "بارگزاری تکلیف"}
                </a>
                <input
                  type="file"
                  ref={questionRef}
                  onChange={(e) =>
                    setCall({ ...call, assignment_file: e.target.files[0] })
                  }
                  required={edit ? false : true}
                  style={{ display: "none" }}
                />
              </div>
              <div className="flex items-end justify-center w-full">
                <a
                  className={`text-black border-2 border-[#8200d3] hover:bg-[#00000009] bg-white text-[1.4rem] rounded-[1.2rem] w-full py-[16.5px] px-8 ${
                    call.assignment_answer_file &&
                    " !bg-[#8200d3] !text-white hover:!text-black"
                  }`}
                  onClick={handleClickAnswer}
                >
                  {call.assignment_answer_file
                    ? "بارگزاری شده"
                    : "بارگزاری پاسخ"}
                </a>
                <input
                  type="file"
                  ref={answerRef}
                  onChange={(e) =>
                    setCall({
                      ...call,
                      assignment_answer_file: e.target.files[0],
                    })
                  }
                  required={edit ? false : true}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            {/* <button type="button">hiiiiiiii</button> */}
            <div className="mb-6">
              <label htmlFor="AssignmentName" className="block mb-1">
                عنوان
              </label>
              <TextField
                name="AssignmentName"
                value={call.AssignmentName}
                placeholder="عنوان"
                {...register("AssignmentName", {
                  required: "عنوان را وارد کنید",
                })}
                error={!!errors.AssignmentName}
                helperText={
                  errors.AssignmentName ? errors.AssignmentName.message : ""
                }
                onChange={(e) => {
                  setCall({ ...call, AssignmentName: e.target.value });
                }}
                fullWidth
              />
            </div>
            <div className="mb-6">
              <label htmlFor="class" className="block mb-1">
                مبحث
              </label>
              <FormControl
                error={errors.assignment_headline ? true : false}
                fullWidth
              >
                <Select
                  value={call.assignment_headline}
                  {...register("assignment_headline", {
                    required: "عنوان را وارد کنید",
                  })}
                  fullWidth
                  onChange={(e) => {
                    setCall({ ...call, assignment_headline: e.target.value });
                  }}
                >
                  {lessons.map((lesson) => {
                    return <MenuItem value={lesson}>{lesson}</MenuItem>;
                  })}
                </Select>
                <FormHelperText>{errors?.group_day?.message}</FormHelperText>
              </FormControl>
            </div>
            <div className="mb-6">
              <label htmlFor="assignment_description" className="block mb-1">
                توضیحات
              </label>
              <TextField
                name="assignment_description"
                value={call.assignment_description}
                placeholder="توضیحات"
                {...register("assignment_description")}
                error={!!errors.assignment_description}
                helperText={
                  errors.assignment_description
                    ? errors.assignment_description.message
                    : ""
                }
                onChange={(e) => {
                  setCall({ ...call, assignment_description: e.target.value });
                }}
                fullWidth
              />
            </div>
            <div className="mb-6">
              <label htmlFor="start-day" className="block mb-2">
                روز شروع
              </label>
              <FormControl
                error={errors.assignment_available_time_start ? true : false}
                fullWidth
              >
                <Controller
                  name="start-day"
                  control={control}
                  render={() => (
                    <MobileDateTimePicker
                      label={
                        call?.assignment_available_time_start
                          ? moment(
                              call.assignment_available_time_start,
                              "YYYY/MM/DD HH:mm"
                            )
                              .locale("fa")
                              .format("YYYY/MM/DD HH:mm")
                          : "روز شروع"
                      }
                      onAccept={(e) =>
                        setCall({
                          ...call,
                          assignment_available_time_start:
                            moment(e).format("YYYY-MM-DD HH:mm"),
                        })
                      }
                      // onChange={}
                      views={["year", "month", "day", "hours", "minutes"]}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      className="w-full"
                      ampm={false}
                      slotProps={{
                        field: { shouldRespectLeadingZeros: true },
                        toolbar: { hidden: true },
                        tabs: { hidden: true },
                      }}
                      sx={{
                        "& .PrivatePickersClockNumber-clockNumber": {
                          fontSize: 50,
                        },
                      }}
                    />
                  )}
                />

                <FormHelperText>
                  {errors?.assignment_available_time_start?.message}
                </FormHelperText>
              </FormControl>
            </div>

            <div className="mb-6">
              <label htmlFor="end-day" className="block mb-2">
                روز پایان
              </label>
              <FormControl
                error={errors.assignment_available_time_end ? true : false}
                fullWidth
              >
                <Controller
                  name="end-day"
                  control={control}
                  render={() => (
                    <MobileDateTimePicker
                      label={
                        call?.assignment_available_time_end
                          ? moment(
                              call.assignment_available_time_end,
                              "YYYY/MM/DD HH:mm"
                            )
                              .locale("fa")
                              .format("YYYY/MM/DD HH:mm")
                          : "روز پایان"
                      }
                      onAccept={(e) =>
                        setCall({
                          ...call,
                          assignment_available_time_end:
                            moment(e).format("YYYY-MM-DD HH:mm"),
                        })
                      }
                      // onChange={}
                      views={["year", "month", "day", "hours", "minutes"]}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      className="!text-[5rem]"
                      ampm={false}
                      slotProps={{
                        field: { shouldRespectLeadingZeros: true },
                        toolbar: { hidden: true },
                        tabs: { hidden: true },
                      }}
                      sx={{
                        "& .PrivatePickersClockNumber-clockNumber": {
                          fontSize: 50,
                        },
                      }}
                    />
                  )}
                />

                <FormHelperText>
                  {errors?.assignment_available_time_end?.message}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="mb-6">
              <label htmlFor="assignment_extra_score" className="block mb-1">
                نمره اضافی
              </label>
              <TextField
                type="number"
                name="assignment_extra_score"
                value={call.assignment_extra_score}
                placeholder="نمره اضافی"
                {...register("assignment_extra_score")}
                error={!!errors.assignment_extra_score}
                helperText={
                  errors.assignment_extra_score
                    ? errors.assignment_extra_score.message
                    : ""
                }
                onChange={(e) => {
                  setCall({ ...call, assignment_extra_score: e.target.value });
                }}
                fullWidth
              />
            </div>
          </>
        )}
        {activeStep === 1 && (
          <>
            <div className="mb-6">
              <label htmlFor="class" className="block mb-1">
                گروه
              </label>
              <FormControl
                error={errors.assignment_group ? true : false}
                fullWidth
              >
                <Select
                  value={call?.assignment_group?.id}
                  {...register("assignment_group", {
                    required: "گروه را وارد کنید",
                  })}
                  fullWidth
                  onChange={(e) => {
                    setCall({
                      ...call,
                      assignment_group: { id: e.target.value },
                    });
                  }}
                >
                  {groups?.results.map((group) => {
                    return <MenuItem value={group.id}>{group.name}</MenuItem>;
                  })}
                </Select>
                <FormHelperText>
                  {errors?.assignment_group?.message}
                </FormHelperText>
              </FormControl>
            </div>
          </>
        )}
        <div className="  w-fit ml-0 mr-auto">
          {isLastStep ? (
            <button
              type="submit"
              className="text-white !bg-[#8200d3] hover:bg-[#8200d3] transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 disabled:opacity-50"
            >
              ثبت تکلیف
            </button>
          ) : (
            <a
              onClick={handleNext}
              className={`text-black border-2 border-blue bg-white text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 ${
                call.AssignmentName &&
                call.assignment_available_time_end &&
                call.assignment_available_time_start &&
                // call.assignment_gender &&
                call.assignment_headline
                  ? ""
                  : "pointer-events-none opacity-50 border-none"
              }`}
            >
              بعدی
            </a>
          )}
        </div>
      </form>
    </div>
  );
};

export default AddHomework;

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

const lessons = [
  "توابع و خواص آن ها",
  "معادله درجه دوم",
  "الگو و دنباله",
  "مثلثات",
  "توان های گویا و عبارات جبری",
  "مرور توابع و خواص آن ها",
  "آنالیز",
  "احتمالات",
  "هندسه مختصاتی",
  "معادلات و نامعادلات گویا و گنگ",
  "قدر مطلق",
  "براکت",
  "هندسه",
  "توابع نمایی و لگاریتم",
  "حد",
  "پیوستگی",
  "بخش پذیری",
  "مشتق",
  "کاربرد مشتق",
  "مجانب ها",
  "مقاطع مخروطی",
];
