import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ShortInfoCard } from "../../../shares/card/Card";
import useFetch from "../../../../hooks/useFetch";
import moment from "jalali-moment";
import VideoPlayer from "../../../shares/videoPlayer/VideoPlayer";
import { useSession } from "../../../../contexts/SessionContext";
import { toast } from "react-toastify";
import Loading from "../../../../utils/Loading";
import  ArrowBackIosNewIcon  from "@mui/icons-material/ArrowBackIosNew";

const Session = () => {
  const {
    contentId,
    sideBar,
    setsideBar,
    contentInfo,
    setContentInfo,
    sessionList,
    setSessionList,
    sessionPervPrecense,
    setContentId,
    setIsReady,
  } = useSession();
  const [progress, setProgress] = useState();
  const [session, setSession] = useState();
  const [duration, setDuration] = useState();
  const [currentTime, setCurrentTime] = useState();
  const [controlBar, setControlBar] = useState(false);
  const [percentPostRes, setPercentPostRes] = useState(null);
  //'1': 0, '2': 0, '3': 0, '4': 0, '5': 0
  const [firstSession, setFirstSession] = useState(sessionList[0] === null ? null : Number.parseInt(sessionList[0]));
  const [SecondSession, setSecondSession] = useState(sessionList[1] === null ? null : sessionList[1]);
  const [thirdSession, setThirdSession] = useState(sessionList[2] === null ? null : Number.parseInt(sessionList[2]));
  const [fourthSession, setFourthSession] = useState(sessionList[3] === null ? null : Number.parseInt(sessionList[3]));
  const [fifthSession, setFifthSession] = useState(sessionList[4] === null ? null : Number.parseInt(sessionList[4]));
  const [classroom_presence, setPercentPostNum] = useState({
    classroom_presence: sessionList,
  });
  const videoRef = useRef(null);
  let previousTime = 0;
  // const [previousTime, setPreviousTime] = useState(0);
  const { Id } = useParams();
  useEffect(() => {
    setContentId(1);
    setContentInfo(session?.Class.content1_url1);
    setSessionList(...sessionList, session?.Class?.content1_url1 === "" ? null : session?.presence?.classroom_presence_percentage);
    setsideBar(true);
  }, [session]);
  useEffect(() => {
    for (let index = 0; index < sessionList.length; index++) {
      const element = sessionList[index];
      if (element !== NaN) {
        setFirstSession(Number.parseInt(sessionList[0]));
        setSecondSession(Number.parseInt(sessionList[1]));
        setThirdSession(Number.parseInt(sessionList[2]));
        setFourthSession(Number.parseInt(sessionList[3]));
        setFifthSession(Number.parseInt(sessionList[4]));
        setPercentPostNum({ classroom_presence: sessionList });
      } else {
        setSessionList(sessionList);
      }
    }
  }, [sessionList]);
  const fetchData = useFetch({
    method: "GET",
    url: `classroom/${Id}/`,
    noHeader: false,
    trigger: true,
    setter: setSession,
    errMessage: () =>
      toast.error("مشکلی در دریافت اطلاعات این کلاس پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
  });

  const percent = useFetch({
    method: "PATCH",
    url: `classroom/${Id}/update_presence/`,
    noHeader: false,
    trigger: false,
    data: classroom_presence,
    errMessage: () => console.log("update_presence has Error"),
  });
  useEffect(() => {
    if (progress % 5 === 0 && progress !== 0) {
      switch (contentId) {
        case 1:
          setFirstSession(progress);

          break;
        case 2:
          setSecondSession(progress);
          break;
        case 3:
          setThirdSession(progress);
          break;
        case 4:
          setFourthSession(progress);
          break;
        case 5:
          setFifthSession(progress);

        default:
          break;
      }
    }
  }, [progress]);

  useEffect(() => {
    if (progress % 5 === 0 && progress !== 0) {
      setPercentPostRes({
        classroom_presence: [firstSession, SecondSession, thirdSession, fourthSession, fifthSession],
      });
    }
  }, [firstSession, SecondSession, thirdSession, fourthSession, fifthSession]);

  useEffect(() => {
    if (progress % 5 === 0 && progress !== 0) {
      percentPostRes?.classroom_presence
        ?.filter((x) => x !== 0)
        .map((x) => setPercentPostNum({ classroom_presence: [...percentPostRes?.classroom_presence] }));
    }
  }, [percentPostRes]);

  useEffect(() => {
    // if (classroom_presence == !{}) {
    // percentPostRes?.classroom_presence
    //   ?.filter((x) => x === 0)
    //   .map((x) => console.log({ classroom_presence: [...percentPostRes.classroom_presence, x] }));
    if (progress % 5 === 0 && progress !== 0) {
      percent.reFetch();
    }
    // }
  }, [classroom_presence, progress]);
  const navigate = useNavigate();
  const backBtn = () => {
    setIsReady(false);
    navigate(-1);
  };
  return (
    // <div className="pt-20">
    <>
      <div className="mb-9 pt-20 max-md:text-center max-md:text-base max-md:text-blue">
        <a onClick={backBtn}>
          لیست جلسات
          <ArrowBackIosNewIcon fontSize={"inherit"} />
        </a>
      </div>
      {fetchData.loading && !contentInfo ? (
        <Loading />
      ) : (
        <div className="flex h-[82vh] flex-col">
          {contentId ? (
            <>
              <h4 className="text-[2.4rem] font-bold text-center">{session?.Class?.ClassroomName}</h4>

              <div className="flex justify-between items-center whitespace-nowrap max-md:text-[10px] max-md:flex-col max-md:m-5 max-md:gap-5 ">
                <span className=" text-[#8E9BA9] md:hidden">سرفصل: {session?.Class.classroom_headline}</span>
                <ShortInfoCard
                  firstTxt={`جلسه ${contentId ? contentId : ""}`}
                  secondTxt={`${progress ? progress : "0"}% حضور`}
                  color="text-blue"
                />
                <span className=" text-[#8E9BA9] max-md:hidden">سرفصل: {session?.Class.classroom_headline}</span>
                <ShortInfoCard
                  firstTxt={
                    session ? moment(session.Class.classroom_creation_time, "YYYY/MM/DD").locale("fa").format("DD MMMM YYYY") : "نامشخص"
                  }
                  secondTxt={duration ? duration : ""}
                  color="text-blue"
                />
              </div>
              <div className="flex justify-center mt-10 ">
                {contentId && contentInfo ? (
                  <VideoPlayer
                    video={contentInfo}
                    presence_percentage={Number.parseInt(sessionList[0])}
                    percentSetter={setProgress}
                    durationSetter={setDuration}
                  />
                ) : (
                  ""
                )}
              </div>
            </>
          ) : contentInfo ? (
            <>
              {/* {contentInfo
                .filter((x, index) => x.content_order === 1)
                .map((x) => ( */}
              <div className="flex flex-col">
                <h4 className="text-[2.4rem] font-bold text-center">{session?.Class?.ClassroomName}</h4>

                <div className="flex justify-between items-center">
                  {/* <ShortInfoCard
                        firstTxt={`جلسه ${
                          contentId
                            ? contentId && contentInfo.filter((x) => x.id === contentId).map((x) => x.content_order)
                            : contentInfo
                            ? contentInfo.filter((x, index) => x.content_order === 1).map((x) => x.content_order)
                            : ""
                        }`}
                        secondTxt={`${progress ? progress : "0"}% حضور`}
                        color="text-blue"
                      /> */}
                  <span className=" text-[#8E9BA9]">سرفصل: {session?.Class.classroom_headline}</span>
                  {/* <ShortInfoCard
                        firstTxt={
                          session
                            ? moment(session.Class.classroom_creation_time, "YYYY/MM/DD").locale("fa").format("DD MMMM YYYY")
                            : "نامشخص"
                        }
                        secondTxt={duration ? duration : ""}
                        color="text-blue"
                      /> */}
                </div>
                <VideoPlayer
                  video={contentInfo}
                  percentSetter={setProgress}
                  presence_percentage={Number.parseInt(sessionList[0])}
                  durationSetter={setDuration}
                />
              </div>
              {/* ))} */}
            </>
          ) : (
            "مشکلی در دریافت جلسه پیش آمده"
          )}
        </div>
      )}
    </>
    // </div>
  );
};

export default Session;
