// import React, { useState, useEffect } from 'react';

// const Countdown = ({ minutes = 0, seconds = 0, onComplete }) => {
//   const [paused, setPaused] = useState(false);
//   const [over, setOver] = useState(false);
//   const [time, setTime] = useState({
//     minutes: parseInt(minutes),
//     seconds: parseInt(seconds),
//   });

//   const tick = () => {
//     if (paused || over) return;
//     if (time.minutes === 0 && time.seconds === 0) {
//       setOver(true);
//       onComplete();
//     } else if (time.seconds === 0)
//       setTime({ minutes: time.minutes - 1, seconds: 59 });
//     else setTime({ ...time, seconds: time.seconds - 1 });
//   };

//   useEffect(() => {
//     const timerId = setInterval(() => tick(), 1000);
//     return () => clearInterval(timerId);
//   });

//   const reset = () => {
//     setTime({ minutes: parseInt(minutes), seconds: parseInt(seconds) });
//     setPaused(false);
//     setOver(false);
//   };

//   return (
//     <div>
//       <p>
//         {`${time.minutes.toString().padStart(2, '0')}:${time.seconds
//           .toString()
//           .padStart(2, '0')}`}
//       </p>
//     </div>
//   );
// };

// export default Countdown;

import React, { useState, useEffect, useCallback } from "react";

function CountDown({ date, onComplete }) {
  // console.log(Date.now())
  // Set the initial target date and time
  const targetDate = new Date(date);

  // Create a state variable to store the remaining time
  const [timeLeft, setTimeLeft] = useState(targetDate - new Date());

  // Use useEffect to set up an interval that updates the state every millisecond
  useEffect(() => {
    const timer = setInterval(() => {
      // Check if the time left is less than or equal to 0
      if (targetDate - new Date() <= 0) {
        // Clear the interval
        clearInterval(timer);
        // Set the time left to 0
        setTimeLeft(0);
        // onComplete();
      } else {
        // Update the state with the new time left
        setTimeLeft(targetDate - new Date());
      }
    }, 1);

    // Clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, [targetDate, onComplete]);

  // Format the time left as hh:mm:ss:ms
  const hours = Math.floor(timeLeft / (1000 * 60 * 60))
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)
    .toString()
    .padStart(2, "0");

  useEffect(() => {
    if (hours==="00" && minutes==="00" &&seconds==="00" ) {
        onComplete()
    }
  }, [hours, minutes, seconds]);

  return (
    <div className="countdown">
      <p>
        {hours}:{minutes}:{seconds}
      </p>
    </div>
  );
}

export default CountDown;
