import React, { useState, useEffect } from "react";
import { Card } from "../../../shares/card/Card";
import ShareTable from "../../../shares/tables/ShareTable";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Search from "../../../shares/search/Search";
import { BtnPrimary } from "../../../shares/button/Button";
import { Modal, Chip } from "@mui/material";
import AddGroups from "./modals/AddHomework";
import useFetch from "../../../../hooks/useFetch";
import moment from "jalali-moment";
import { Link } from "react-router-dom";
import {
  DeleteOutline,
  EditOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const HomeworkList = () => {
  const [newModal, setNewModal] = useState(false);
  const [edit, setEdit] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [assignments, setAssignments] = useState();
  const [newAssignment, setNewAssignment] = useState();
  const [id, setId] = useState();

  const handleClose = () => {
    setNewModal(false);
    setEdit(null);
  };

  const getAssignment = useFetch({
    method: "GET",
    url: `management/assignments/`,
    noHeader: false,
    trigger: true,
    setter: setAssignments,
  });
  const deleteClassrooms = useFetch({
    method: "DELETE",
    url: `management/assignments/${id}/`,
    noHeader: false,
    trigger: false,
    caller: getAssignment,
  });

  const handleEdit = (id) => {
    setNewModal(true);
    setEdit(id);
  };

  useEffect(() => {
    setNewAssignment(
      assignments?.results.map((assignment) => {
        const newAssignment = {
          ...assignment,
          assignment_available_time_start: moment(
            assignment.assignment_available_time_start,
            "YYYY/MM/DD"
          )
            .locale("fa")
            .format("YYYY/MM/DD"),
          assignment_available_time_end: moment(
            assignment.assignment_available_time_end,
            "YYYY/MM/DD"
          )
            .locale("fa")
            .format("YYYY/MM/DD"),
          assignment_finished: assignment.assignment_finished
            ? "پایان یافته"
            : "در حال برگزاری",
          assignment_file: (
            <a href={assignment?.assignment_file} download>
              <FileDownloadOutlined />
            </a>
          ),
          assignment_group: (
            <Chip
              label={assignment.assignment_group.name}
              className="mx-1 my-1"
            />
          ),
          assignment_answer_file: (
            <a href={assignment?.assignment_answer_file} download>
              <FileDownloadOutlined />
            </a>
          ),
          edit: (
            <EditOutlined
              onClick={() => handleEdit(assignment.assignment_id)}
              className="cursor-pointer"
            />
          ),
          delete: (
            <DeleteOutline
              onClick={() => setId(assignment.assignment_id)}
              className="cursor-pointer"
            />
          ),
        };
        return newAssignment;
      })
    );
  }, [assignments]);

  useEffect(() => {
    deleteClassrooms.reFetch();
  }, [id]);

  return (
    <div className="flex mt-20">
      <Card className="p-16 w-full text-right">
        <div className="flex justify-between items-center border-b-2 border-gray-200 pb-8 mb-12">
          <h3 className="text-3xl">تکلیف‌ها</h3>
          <BtnPrimary
            text="+ افزودن تکلیف جدید"
            className="px-6 py-4 !bg-[#8200d3] hover:bg-[#8200d3]"
            onClick={() => {
              setNewModal(true);
            }}
          />
        </div>
        <div className="flex items-center mb-6">
          <Search newData={setAssignments} url="assignments" />
        </div>

        <ShareTable
          data={newAssignment ? newAssignment : []}
          columns={columns}
          next={
            assignments?.next
              ? assignments?.next?.replace(
                  /https:\/\/lab\.mindescape\.co\//,
                  ""
                )
              : null
          }
          perv={
            assignments?.previous
              ? assignments?.previous?.replace(
                  /https:\/\/lab\.mindescape\.co\//,
                  ""
                )
              : null
          }
          newData={setAssignments}
          getLoad={getAssignment.loading}
        />
      </Card>
      <Modal
        open={newModal}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AddGroups close={handleClose} get={getAssignment} edit={edit} />
      </Modal>
    </div>
  );
};

export default HomeworkList;

const columns = [
  { id: "AssignmentName", label: "نام" },
  { id: "assignment_headline", label: "مبحث" },
  { id: "assignment_available_time_start", label: "شروع" },
  { id: "assignment_available_time_end", label: "پایان" },
  { id: "assignment_finished", label: "وضعیت" },
  { id: "assignment_extra_score", label: "نمره اضافه" },
  { id: "assignment_group", label: "گروه " },
  { id: "assignment_file", label: "فایل تکلیف" },
  { id: "assignment_answer_file", label: "فایل جواب" },
  { id: "edit", label: "ویرایش" },
  { id: "delete", label: "حذف" },
  // Add more columns as needed
];
