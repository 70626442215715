import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CloseSharp } from "@mui/icons-material";
import { BtnAlternative, BtnPrimary } from "../../../../shares/button/Button";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import useFetch from "../../../../../hooks/useFetch";
import { Input, FormControl, FormHelperText } from "@mui/material";

const AddAnswer = ({ close, get, id }) => {
  const [call, setCall] = useState({
    q1_score: "",
    q2_score: "",
    q3_score: "",
    q4_score: "",
    q5_score: "",
    q6_score: "",
    q7_score: "",
    q8_score: "",
    q9_score: "",
    q10_score: "",
    q11_score: "",
    q12_score: "",
    q13_score: "",
    q14_score: "",
    q15_score: "",
    q16_score: "",
    q17_score: "",
    q18_score: "",
    q19_score: "",
    q20_score: "",
    extra_score: "",

    assignment_teacher_file: "",
  });

  const inputRef = useRef(null);

  const handleClickFile = () => {
    inputRef.current.click();
  };

  const newGroup = useFetch({
    method: "PATCH",
    url: `management/assignment-scores/${id}/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });
  const getScore = useFetch({
    method: "GET",
    url: `management/assignment-scores/${id}/`,
    noHeader: false,
    trigger: true,
    setter: setCall,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: call,
  });

  const onSubmit = (e) => {
    const formData = new FormData();

    formData.append("q1_score", call.q1_score);
    formData.append("q2_score", call.q2_score);
    formData.append("q3_score", call.q3_score);
    formData.append("q4_score", call.q4_score);
    formData.append("q5_score", call.q5_score);
    formData.append("q6_score", call.q6_score);
    formData.append("q7_score", call.q7_score);
    formData.append("q8_score", call.q8_score);
    formData.append("q9_score", call.q9_score);
    formData.append("q10_score", call.q10_score);
    formData.append("q11_score", call.q11_score);
    formData.append("q12_score", call.q12_score);
    formData.append("q13_score", call.q13_score);
    formData.append("q14_score", call.q14_score);
    formData.append("q15_score", call.q15_score);
    formData.append("q16_score", call.q16_score);
    formData.append("q17_score", call.q17_score);
    formData.append("q18_score", call.q18_score);
    formData.append("q19_score", call.q19_score);
    formData.append("q20_score", call.q20_score);
    formData.append("extra_score", call.extra_score);

    if (typeof call.assignment_teacher_file == "object") {
      formData.append("assignment_teacher_file", call.assignment_teacher_file);
    }
    formData.append("assignment_marked", true);

    setCall(formData);

    newGroup.reFetch();
  };
  useEffect(() => {
    newGroup.status === 200 && close();
  }, [newGroup.status]);

  return (
    <div className="relative rounded-2xl w-2/5 bg-white">
      <CloseSharp
        className="absolute top-2 right-[6px] cursor-pointer"
        onClick={close}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pt-8 pb-10 px-14 text-xl overflow-scroll max-h-[80vh]"
      >
        <h6 className="font-extrabold text-2xl border-b-2 w-full pb-4 mb-6">
          تصحیح
        </h6>
        <div className="grid grid-cols-5 gap-x-3">
          <div className="mb-6">
            <label htmlFor={"q1_score"} className="block mb-1">
              سوال 1
            </label>
            <TextField
              name="q1_score"
              value={call.q1_score}
              placeholder="سوال 1"
              {...register("q1_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q1_score}
              // helperText={errors.q1_score ? errors.q1_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q1_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q2_score"} className="block mb-1">
              سوال 2
            </label>
            <TextField
              name="q2_score"
              value={call.q2_score}
              placeholder="سوال 2"
              {...register("q2_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q2_score}
              // helperText={errors.q2_score ? errors.q2_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q2_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q3_score"} className="block mb-1">
              سوال 3
            </label>
            <TextField
              name="q3_score"
              value={call.q3_score}
              placeholder="سوال 3"
              {...register("q3_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q3_score}
              // helperText={errors.q3_score ? errors.q1_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q3_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q4_score"} className="block mb-1">
              سوال 4
            </label>
            <TextField
              name="q4_score"
              value={call.q4_score}
              placeholder="سوال 4"
              {...register("q4_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q4_score}
              // helperText={errors.q4_score ? errors.q1_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q4_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q5_score"} className="block mb-1">
              سوال 5
            </label>
            <TextField
              name="q5_score"
              value={call.q5_score}
              placeholder="سوال 5"
              {...register("q5_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q5_score}
              // helperText={errors.q5_score ? errors.q1_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q5_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q6_score"} className="block mb-1">
              سوال 6
            </label>
            <TextField
              name="q6_score"
              value={call.q6_score}
              placeholder="سوال 6"
              {...register("q6_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q6_score}
              // helperText={errors.q6_score ? errors.q1_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q6_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q7_score"} className="block mb-1">
              سوال 7
            </label>
            <TextField
              name="q7_score"
              value={call.q7_score}
              placeholder="سوال 7"
              {...register("q7_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q7_score}
              // helperText={errors.q7_score ? errors.q1_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q7_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q8_score"} className="block mb-1">
              سوال 8
            </label>
            <TextField
              name="q8_score"
              value={call.q8_score}
              placeholder="سوال 8"
              {...register("q8_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q8_score}
              // helperText={errors.q8_score ? errors.q1_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q8_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q9_score"} className="block mb-1">
              سوال 9
            </label>
            <TextField
              name="q9_score"
              value={call.q9_score}
              placeholder="سوال 9"
              {...register("q9_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q9_score}
              // helperText={errors.q9_score ? errors.q1_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q9_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q10_score"} className="block mb-1">
              سوال 10
            </label>
            <TextField
              name="q10_score"
              value={call.q10_score}
              placeholder="سوال 10"
              {...register("q10_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q10_score}
              // helperText={errors.q10_score ? errors.q1_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q10_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q11_score"} className="block mb-1">
              سوال 11
            </label>
            <TextField
              name="q11_score"
              value={call.q11_score}
              placeholder="سوال 11"
              {...register("q11_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q11_score}
              // helperText={errors.q11_score ? errors.q11_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q11_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q12_score"} className="block mb-1">
              سوال 12
            </label>
            <TextField
              name="q12_score"
              value={call.q12_score}
              placeholder="سوال 12"
              {...register("q12_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q12_score}
              // helperText={errors.q12_score ? errors.q12_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q12_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q13_score"} className="block mb-1">
              سوال 13
            </label>
            <TextField
              name="q13_score"
              value={call.q13_score}
              placeholder="سوال 13"
              {...register("q13_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q13_score}
              // helperText={errors.q13_score ? errors.q13_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q13_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q14_score"} className="block mb-1">
              سوال 14
            </label>
            <TextField
              name="q14_score"
              value={call.q14_score}
              placeholder="سوال 14"
              {...register("q14_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q14_score}
              // helperText={errors.q14_score ? errors.q14_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q14_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q15_score"} className="block mb-1">
              سوال 15
            </label>
            <TextField
              name="q15_score"
              value={call.q15_score}
              placeholder="سوال 15"
              {...register("q15_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q15_score}
              // helperText={errors.q15_score ? errors.q15_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q15_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q16_score"} className="block mb-1">
              سوال 16
            </label>
            <TextField
              name="q16_score"
              value={call.q16_score}
              placeholder="سوال 16"
              {...register("q16_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q16_score}
              // helperText={errors.q16_score ? errors.q16_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q16_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q17_score"} className="block mb-1">
              سوال 17
            </label>
            <TextField
              name="q17_score"
              value={call.q17_score}
              placeholder="سوال 17"
              {...register("q17_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q17_score}
              // helperText={errors.q17_score ? errors.q17_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q17_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q18_score"} className="block mb-1">
              سوال 18
            </label>
            <TextField
              name="q18_score"
              value={call.q18_score}
              placeholder="سوال 18"
              {...register("q18_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q18_score}
              // helperText={errors.q18_score ? errors.q18_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q18_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q1_score"} className="block mb-1">
              سوال 19
            </label>
            <TextField
              name="q19_score"
              value={call.q19_score}
              placeholder="سوال 19"
              {...register("q19_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q19_score}
              // helperText={errors.q19_score ? errors.q19_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q19_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6">
            <label htmlFor={"q1_score"} className="block mb-1">
              سوال 20
            </label>
            <TextField
              name="q20_score"
              value={call.q20_score}
              placeholder="سوال 20"
              {...register("q20_score", {
                required: "نمره را وارد کنید",
              })}
              error={!!errors.q20_score}
              // helperText={errors.q20_score ? errors.q20_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, q20_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6 col-span-2">
            <label htmlFor={"extra_score"} className="block mb-1">
              نمره اضافه
            </label>
            <TextField
              name="extra_score"
              value={call.extra_score}
              placeholder="نمره اضافه"
              {...register("extra_score", { required: "نمره را وارد کنید" })}
              error={!!errors.extra_score}
              // helperText={errors.extra_score ? errors.extra_score.message : ""}
              onChange={(e) => {
                setCall({ ...call, extra_score: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="mb-6 col-span-3 flex items-end justify-center">
            <a
              className="text-black border-2 border-[#8200d3] hover:bg-[#00000009] bg-white text-[1.4rem] rounded-[1.2rem] w-full py-[16.5px] px-8"
              onClick={handleClickFile}
            >
              ارسال فایل
            </a>
            <input
              type="file"
              ref={inputRef}
              onChange={(e) =>
                setCall({ ...call, assignment_teacher_file: e.target.files[0] })
              }
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div className="mr-auto ml-0 w-fit">
          <button
            type="submit"
            className="text-white !bg-[#8200d3] hover:bg-[#8200d3] transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 "
          >
            ثبت
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddAnswer;

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
