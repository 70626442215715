import React, { useState } from "react";
import { LinearProgress } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Card, ShortInfoCard } from "../../../../shares/card/Card";
import EX from "../../../../../assets/img/exam.png";
import useFetch from "../../../../../hooks/useFetch";
import moment from "jalali-moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function EXAMCard() {
  const [examInfoBox, setExamInfoBox] = useState();

  const fetchData = useFetch({
    method: "GET",
    url: "dashboard/exam-box",
    noHeader: false,
    trigger: true,
    setter: setExamInfoBox,
    // errMessage: () =>
    //   toast.error("امتحانی موجود نیست", {
    //     position: "bottom-left",
    //     rtl: true,
    //     autoClose: 2000,
    //   }),
  });

  return (
    <>
      {fetchData.loading ? (
        ""
      ) : (
        <Card className="EXAM md:px-[3.2rem] md:pt-[2.1rem] md:pb-[3.1rem] max-md:py-[2rem] max-md:px-[1.3rem]">
          <div className="">
            <h4 className="text-right">نمره امتحانات</h4>
          </div>
          <div className="flex justify-evenly pt-[2.1rem]">
            <div className="">
              <img src={EX} className="" />
            </div>
            <div className="w-full md:pr-[2.16rem] md:pl-[3.28rem] text-right">
              {examInfoBox ? (
                <>
                  <h3 className="text-[#FDAB45] text-[2.4rem] inline">
                    {" "}
                    {examInfoBox[1].average}{" "}
                  </h3>
                  <span className="inline">از 100 نمره</span>
                  <LinearProgress
                    variant="determinate"
                    className="mt-[1.99rem]"
                    value={examInfoBox && examInfoBox[1].average}
                    sx={{
                      "& .css-5xe99f-MuiLinearProgress-bar1": {
                        backgroundColor: "#FDAB45",
                      },
                    }}
                  />
                  <div className="flex flex-row justify-between pt-[1.43rem]">
                    <div className="opacity-50">
                      <ErrorOutlineIcon /> میانگین نمرات
                    </div>
                    <Link to="/dashboard/exams" className="text-[#FDAB45]">
                      بیشتر <ArrowBackIosNewIcon />
                    </Link>
                  </div>
                </>
              ) : (
                <h1 className="text-center text-red-500 text-[2rem]">
                  امتحانی موجود نیست
                </h1>
              )}
            </div>
          </div>
          <div>
            {examInfoBox ? (
              <ShortInfoCard
                className="flex flex-row justify-evenly text-[1.2rem] mt-[3.2rem]  "
                firstTxt={"امتحان بعدی"}
                secondTxt={
                  examInfoBox && examInfoBox[0].exam_duration
                  // moment(, "HH")
                  //   .locale("fa")
                  //   .format("HH:MM")
                }
                color="text-[#FDAB45]"
              >
                <span className="text-[#FDAB45]">
                  {examInfoBox &&
                    moment(
                      examInfoBox[0].exam_available_time_start,
                      "YYYY/MM/DD"
                    )
                      .locale("fa")
                      .format("DD MMMM YYYY")}
                </span>
              </ShortInfoCard>
            ) : (
              ""
            )}
          </div>
        </Card>
      )}
    </>
  );
}

export default EXAMCard;
