import React, { useState, useEffect } from "react";
import { Card } from "../../../shares/card/Card";
import ShareTable from "../../../shares/tables/ShareTable";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Approval,
  FileDownloadOutlined,
  TextFields,
  TextureRounded,
  TextureSharp,
} from "@mui/icons-material";
import { BtnPrimary } from "../../../shares/button/Button";
import AddAnswer from "./modals/AddAnswer";
import useFetch from "../../../../hooks/useFetch";
import moment from "jalali-moment";
import { Link } from "react-router-dom";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Modal,
} from "@mui/material";
import Search from "../../../shares/search/Search";

const HomeworkAnswers = () => {
  const [filterChoice, setFilterChoice] = useState("همه");
  const [classFilter, setClassFilter] = useState("");
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [assignmentScores, setAssignmentScores] = useState();
  const [newAssignment, setNewAssignment] = useState();
  const [id, setId] = useState();

  const handleClose = () => {
    setNewModal(false);
    setEditModal(false);
    setDeleteModal(false);
  };

  const handleFilter = (event) => {
    if (event.target.value === true) {
      const filteredValue = newAssignment?.filter((item) => {
        console.log(item);
        return item.assignment_marked === "تصحیح شده";
      });
      setClassFilter(filteredValue);
    } else if (event.target.value === false) {
      const filteredValue = newAssignment?.filter((item) => {
        console.log(item);
        return item.assignment_marked === "تصحیح نشده";
      });
      setClassFilter(filteredValue);
    }
  };

  const getAssignment = useFetch({
    method: "GET",
    url: `management/assignment-scores/`,
    noHeader: false,
    trigger: true,
    setter: setAssignmentScores,
  });
  // const deleteClassrooms = useFetch({
  //   method: "DELETE",
  //   url: `management/classrooms/${id}`,
  //   noHeader: false,
  //   trigger: false,
  //   caller: getClassrooms,
  // });

  const handleCorrect = (id) => {
    setNewModal(true);
    setId(id);
  };

  useEffect(() => {
    setNewAssignment(
      assignmentScores?.results.map((assignment) => {
        const newAssignment = {
          ...assignment,
          updated_file_at: moment(
            assignment.updated_file_at,
            "YYYY/MM/DD HH:mm"
          )
            .locale("fa")
            .format("YYYY/MM/DD HH:mm"),
          name:
            assignment.assignment_average_reffer.user.first_name +
            " " +
            assignment.assignment_average_reffer.user.last_name,
          assignment_name: assignment.assignment.AssignmentName,
          assignment_answer: (
            <a href={`${assignment?.assignment_student_file}`} download>
              <FileDownloadOutlined />
            </a>
          ),
          assignment_marked: assignment.assignment_marked
            ? "تصحیح شده"
            : "تصحیح نشده",
          assignment_corrected: (
            <a
              href={`${assignment?.assignment_teacher_file}`}
              className={
                assignment.assignment_marked ? "" : "pointer-events-none"
              }
            >
              <FileDownloadOutlined />
            </a>
          ),
          assignment_correct: (
            <BtnPrimary
              text="تصحیح"
              className="py-4 px-8 !bg-[#8200d3] hover:bg-[#8200d3]"
              onClick={() => handleCorrect(assignment.id)}
            />
          ),
        };
        return newAssignment;
      })
    );
  }, [assignmentScores]);

  return (
    <div className="flex mt-20">
      <Card className="p-16 w-full text-right">
        <div className="flex justify-between items-center border-b-2 border-gray-200 pb-8 mb-12">
          <h3 className="text-3xl">تصحیح تکلیف‌ها</h3>
        </div>

        <div className="flex items-center mb-6">
          {/* <span className="ml-3">نمایش گروه‌های: </span> */}
          {/* <FormControl>
            <InputLabel id="demo-simple-select-label" className="bg-white">
              وضعیت
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={handleFilter}
              //   className="w-40 !h-16"
              sx={{ width: "15rem", height: "4rem" }}
            >
              <MenuItem value={true}>تصحیح شده</MenuItem>
              <MenuItem value={false}>تصحیح نشده</MenuItem>
            </Select>
          </FormControl> */}
          <Search newData={setAssignmentScores} url="assignment-scores" />
        </div>

        <ShareTable
          data={
            newAssignment && classFilter === ""
              ? newAssignment
              : newAssignment && classFilter !== ""
              ? classFilter
              : []
          }
          columns={columns}
          next={
            assignmentScores?.next
              ? assignmentScores?.next?.replace(
                  /https:\/\/lab\.mindescape\.co\//,
                  ""
                )
              : null
          }
          perv={
            assignmentScores?.previous
              ? assignmentScores?.previous?.replace(
                  /https:\/\/lab\.mindescape\.co\//,
                  ""
                )
              : null
          }
          newData={setAssignmentScores}
          getLoad={getAssignment.loading}
        />
      </Card>
      <Modal
        open={newModal}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AddAnswer close={handleClose} get={getAssignment} id={id} />
      </Modal>
    </div>
  );
};

export default HomeworkAnswers;

const columns = [
  { id: "id", label: "Id" },
  { id: "name", label: "نام" },
  { id: "assignment_name", label: "نام تکلیف" },
  { id: "score", label: "نمره" },
  { id: "updated_file_at", label: "زمان آپلود" },
  { id: "assignment_answer", label: "جواب" },
  { id: "assignment_corrected", label: "تصحیح شده" },
  { id: "assignment_marked", label: "وضعیت" },
  { id: "assignment_marked_by", label: "مصحح" },
  { id: "assignment_correct", label: "" },
  // Add more columns as needed
];
