import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import useFetch from "../../../hooks/useFetch";
import Loading from "../../../utils/Loading";

const ShareTable = ({
  data,
  columns,
  perv,
  next,
  newData,
  getLoad,
  pageShow,
}) => {
  const [page, setPage] = useState(0);

  const handlePrevPage = () => {
    setPage(page - 1);
    getPerv.reFetch();
  };

  const handleNextPage = () => {
    setPage(page + 1);
    getNext.reFetch();
  };

  const getNext = useFetch({
    method: "GET",
    url: next,
    noHeader: false,
    trigger: false,
    setter: newData,
  });

  const getPerv = useFetch({
    method: "GET",
    url: perv,
    noHeader: false,
    trigger: false,
    setter: newData,
  });

  return (
    <>
      {getPerv.loading || getNext.loading || getLoad ? (
        <Loading admin={true} />
      ) : (
        <TableContainer className="rounded-lg">
          <Table className="">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    className="border-2 border-gray-300 !text-center !font-bold bg-violet-100"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="border-2 border-gray-300 !text-center"
                    >
                      {row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {pageShow !== false && (
            <div className="w-full">
              <div className="flex p-2 justify-end items-center">
                {/* <span className="">صفحه {page + 1}</span> */}
                <IconButton
                  onClick={handlePrevPage}
                  disabled={perv === null ? true : false}
                >
                  <NavigateNextIcon />
                </IconButton>

                <IconButton
                  onClick={handleNextPage}
                  disabled={next === null ? true : false}
                >
                  <NavigateBeforeIcon />
                </IconButton>
              </div>
            </div>
          )}
        </TableContainer>
      )}
    </>
  );
};

export default ShareTable;
