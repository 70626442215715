import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import InfoIcon from "@mui/icons-material/Info";
import { Chip, Modal } from "@mui/material";
import AddBooks from "./modals/AddBooks";
import ExamInfo from "./modals/ExamInfo";

const ExamScore = () => {
  const [exams, setExams] = useState();
  const [examData, setExamData] = useState();
  const [examInfo, setExamInfo] = useState(false);
  const { Id } = useParams();

  const handleClose = () => {
    setExamInfo(false);
  };
  const handleOpen = (exam) => {
    setExamInfo(true);
    setExamData(exam);
  };

  const getExams = useFetch({
    method: "GET",
    url: `management/exam-scores/${Id}/user_scores_list/`,
    noHeader: false,
    trigger: true,
    setter: setExams,
  });

  return (
    <div className="my-16 text-right px-10">
      <h6 className="mb-4">امتحانات</h6>
      <div className="flex flex-wrap gap-x-2">
        {exams?.length > 0 ? (
          <>
            {exams.map((exam) => {
              return (
                <Chip
                  label={exam.exam.ExamName}
                  onClick={() => {
                    handleOpen(exam);
                  }}
                />
              );
            })}
          </>
        ) : (
          <div className=" flex items-center gap-x-4">
            <span className="font-light">
              <InfoIcon color="secondary" />
              امتحان موجود نیست.
            </span>
          </div>
        )}
      </div>
      <Modal
        open={examInfo}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ExamInfo close={handleClose} data={examData} />
      </Modal>
    </div>
  );
};

export default ExamScore;
