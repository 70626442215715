import React, { useState, useEffect } from "react";
import { Card } from "../../../shares/card/Card";
import ShareTable from "../../../shares/tables/ShareTable";
import Search from "../../../shares/search/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { BtnPrimary } from "../../../shares/button/Button";
import { Modal, Chip } from "@mui/material";
import AddGroups from "./modals/AddVideos";
import useFetch from "../../../../hooks/useFetch";
import moment from "jalali-moment";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";

const Videos = () => {
  const [newModal, setNewModal] = useState(false);
  const [edit, setEdit] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [classrooms, setClassrooms] = useState();
  const [newClassrooms, setNewClassrooms] = useState();
  const [id, setId] = useState();

  const handleClose = () => {
    setNewModal(false);
    setEdit(null);
  };

  const getClassrooms = useFetch({
    method: "GET",
    url: `management/classrooms/`,
    noHeader: false,
    trigger: true,
    setter: setClassrooms,
  });

  const deleteClassrooms = useFetch({
    method: "DELETE",
    url: `management/classrooms/${id}/`,
    noHeader: false,
    trigger: false,
    caller: getClassrooms,
  });

  const handleEdit = (id) => {
    setNewModal(true);
    setEdit(id);
  };

  useEffect(() => {
    setNewClassrooms(
      classrooms?.results.map((classroom) => {
        const newClassroom = {
          ...classroom,
          classroom_available_time_end: moment(
            classroom.classroom_available_time_end,
            "YYYY/MM/DD"
          )
            .locale("fa")
            .format("YYYY/MM/DD"),
          classroom_finished:
            classroom.classroom_finished === true
              ? "پایان یافته"
              : "در حال برگزاری",
          classroom_groups: classroom.classroom_groups.map((i) => {
            return <Chip label={i.name} className="mx-1 my-1" />;
            // return i.name;
          }),
          edit: (
            <EditOutlined
              onClick={() => handleEdit(classroom.classroom_id)}
              className="cursor-pointer"
            />
          ),
          delete: (
            <DeleteOutline
              onClick={() => setId(classroom.classroom_id)}
              className="cursor-pointer"
            />
          ),
        };
        return newClassroom;
      })
    );
  }, [classrooms]);

  useEffect(() => {
    deleteClassrooms.reFetch();
  }, [id]);

  return (
    <div className="flex mt-20">
      <Card className="p-16 w-full text-right">
        <div className="flex justify-between items-center border-b-2 border-gray-200 pb-8 mb-12">
          <h3 className="text-3xl">درس‌ها و ویدیو‌ها</h3>
          <BtnPrimary
            text="+ افزودن درس جدید"
            className="px-6 py-4 !bg-[#8200d3] hover:bg-[#8200d3]"
            onClick={() => {
              setNewModal(true);
            }}
          />
        </div>
        <div className="flex items-center mb-6">
          <Search newData={setClassrooms} url="classrooms" />
        </div>

        <ShareTable
          data={newClassrooms ? newClassrooms : []}
          columns={columns}
          next={
            classrooms?.next
              ? classrooms?.next?.replace(/https:\/\/lab\.mindescape\.co\//, "")
              : null
          }
          perv={
            classrooms?.previous
              ? classrooms?.previous?.replace(
                  /https:\/\/lab\.mindescape\.co\//,
                  ""
                )
              : null
          }
          newData={setClassrooms}
          getLoad={getClassrooms.loading}
        />
      </Card>
      <Modal
        open={newModal}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AddGroups close={handleClose} get={getClassrooms} edit={edit} />
      </Modal>
    </div>
  );
};

export default Videos;

const columns = [
  // { id: "id", label: "id" },
  { id: "ClassroomName", label: "نام" },
  { id: "classroom_headline", label: "مبحث" },
  { id: "classroom_available_time_end", label: "پایان" },
  { id: "classroom_finished", label: "وضعیت" },
  { id: "classroom_groups", label: "گروه" },
  // { id: "approve", label: "اعمال به کلاس" },
  { id: "edit", label: "ویرایش" },
  { id: "delete", label: "حذف" },
  // Add more columns as needed
];
