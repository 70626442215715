import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { CloseSharp } from "@mui/icons-material";
import useFetch from "../../../../../hooks/useFetch";
import { Input, FormControl, FormHelperText, MenuItem } from "@mui/material";

const AddBooks = ({ close, get, Id }) => {
  const [booksList, setBooksList] = useState();
  const [books, setBooks] = useState();
  const [selected, setSelected] = useState();
  const [added, setAdded] = useState();
  const [call, setCall] = useState();
  const [bookIds, setBookIds] = useState({
    book_ids: [],
  });

  const getBooks = useFetch({
    method: "GET",
    url: `management/books/`,
    noHeader: false,
    trigger: true,
    setter: setBooks,
  });

  const getBooksList = useFetch({
    method: "GET",
    url: `management/users/${Id}/user_books/`,
    noHeader: false,
    trigger: true,
    setter: setBooksList,
  });

  const newBook = useFetch({
    method: "PATCH",
    url: `management/users/${Id}/user_books/`,
    noHeader: false,
    trigger: false,
    caller: get,
    data: bookIds,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // values: call,
  });

  const onSubmit = (e) => {
    // const formData = new FormData();
    // formData.append("book_ids", bookIds);
    // setCall(formData);
    newBook.reFetch();
  };

  useEffect(() => {
    setBookIds({ ...bookIds, book_ids: booksList?.map((book) => book.id) });
  }, [booksList]);

  const handleSelect = (event) => {
    if (bookIds.book_ids.length === 0) {
      setBookIds({
        book_ids: [event.target.value],
      });
    } else {
      setBookIds((prevState) => ({
        ...prevState,
        book_ids: [...prevState.book_ids, event.target.value],
      }));
    }
  };

  function updateStudent(state, newStudentId) {
    const newResults = state.results.map((result) => {
      if (result.id === selected) {
        return {
          ...result,
          student: [...result.student, newStudentId],
        };
      }

      return result;
    });

    return {
      ...state,
      results: newResults,
    };
  }
  useEffect(() => {
    newBook.status === 202 && close();
  }, [newBook.status]);

  return (
    <div className="relative rounded-2xl w-2/5 bg-white">
      <CloseSharp
        className="absolute top-2 right-[6px] cursor-pointer"
        onClick={close}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pt-16 pb-10 px-14 text-xl"
      >
        <h6 className="font-extrabold text-2xl border-b-2 w-full pb-4 mb-6">
          افزودن جزوه
        </h6>
        <div className="mb-6">
          <label htmlFor="books" className="block mb-1">
            لیست جزوات
          </label>
          <FormControl error={errors.books ? true : false} fullWidth>
            <Select
              value={selected}
              {...register("books", {
                required: "جزوه را وارد کنید",
              })}
              fullWidth
              onChange={handleSelect}
            >
              {books?.results.map((book) => {
                return <MenuItem value={book.id}>{book.booksname}</MenuItem>;
              })}
            </Select>
            <FormHelperText>{errors?.books?.message}</FormHelperText>
          </FormControl>
        </div>

        <div className="mr-auto ml-0 w-fit">
          <button
            type="submit"
            className="text-white !bg-[#8200d3] hover:bg-blueHover transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 "
          >
            ثبت
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddBooks;

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
