import React, { useCallback, useEffect, useState } from "react";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { BtnAlternative } from "../../../../../components/shares/button/Button";
import Result from "../../../../../assets/img/icons/Docs.svg";
import Play from "../../../../../assets/img/icons/Iconly-Light-Play.svg";
import Edit from "../../../../../assets/img/icons/Iconly-Light-Edit.svg";
import { Card } from "./../../../../shares/card/Card";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation, useMatch, useParams } from "react-router-dom";
import { useSession } from "../../../../../contexts/SessionContext";
import useFetch from "../../../../../hooks/useFetch";
import { toast } from "react-toastify";

function LeftSidebar({ session }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { contentId, setContentId, sessionList, setSessionList } = useSession();

  const [sessionId, setSessionId] = useState();
  const { videoSelect, setVideoSelect, sideBar, setsideBar, sessionNum, contentInfo, setContentInfo, setSessionPervPrecense ,isReady,setIsReady} =
    useSession();
  const { Id } = useParams();
  const classes = useMatch("dashboard/classes/:Id/:Id");

  const fetchData = useFetch({
    method: "GET",
    url: `classroom/${Id}/`,
    noHeader: false,
    trigger: classes ? true : false,
    // errMessage: () => toast.error("مشکلی در دریافت اطلاعات قسمت ها پیش آمده", { position: "bottom-left", rtl: true, autoClose: 2000 }),
    setter: setSessionId,
  });

  useEffect(() => {
    setSessionList([
      sessionId?.Class?.content1_url1 === "" ? null : Number.parseInt(sessionId?.Presence?.classroom_presence[0]),
      sessionId?.Class?.content2_url1 === "" ? null : Number.parseInt(sessionId?.Presence?.classroom_presence[1]),
      sessionId?.Class?.content3_url1 === "" ? null : Number.parseInt(sessionId?.Presence?.classroom_presence[2]),
      sessionId?.Class?.content4_url1 === "" ? null : Number.parseInt(sessionId?.Presence?.classroom_presence[3]),
      sessionId?.Class?.content5_url1 === "" ? null : Number.parseInt(sessionId?.Presence?.classroom_presence[4]),
    ]);
  }, [sessionId]);
  useCallback(() => {
    if (sessionList && isReady) {
    setTimeout(window.location.reload(),500)
      
    }
  }, [sessionList,isReady])
  
  return (
    <aside className={`sidebarLeft pt-[3.6rem] ${sideBar ? "max-md:w-52" : "max-md:hidden"} `}>
      <div className="sidebarLeft-wrapper">
        <div className="sidebarLeft-container flex flex-col items-center max-md:p-0  px-[4.7rem] gap-y-6">
          <div className="events max-md:text-[12px] text-[16px]  pb-[3.4rem]">
            <NotificationsOutlinedIcon fontSize="medium" />
            رویدادها
          </div>
          {sideBar ? (
            <>
              {sessionId?.Class?.content1_url1 && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setContentId(1);
                    setContentInfo(sessionId?.Class?.content1_url1);
                    setSessionPervPrecense(sessionId?.Presence?.classroom_presence_percentage);
                    setIsReady(false)
                  }}
                >
                  <Card className="px-[6.9rem] pt-[4.3rem] pb-[4.3rem] max-md:p-5  ">
                    <h2 className="text-[12px] pb-[1.8rem]">بخش</h2>
                    {/* <h1 className="text-[48px] max-md:text-[32px] pb-[1.2rem]">{sessionId?.Class?.content1_url1}</h1> */}
                    <h1 className="text-[48px] max-md:text-[32px] pb-[1.2rem]">1</h1>
                  </Card>
                </div>
              )}
              {sessionId?.Class?.content2_url1 && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setContentId(2);
                    setContentInfo(sessionId?.Class?.content2_url1);
                    setSessionPervPrecense(sessionId?.Presence?.classroom_presence_percentage);
                    setIsReady(false)

                  }}
                >
                  <Card className="px-[6.9rem] pt-[4.3rem] pb-[4.3rem] max-md:p-5  ">
                    <h2 className="text-[12px] pb-[1.8rem]">بخش</h2>
                    <h1 className="text-[48px] max-md:text-[32px] pb-[1.2rem]">2</h1>
                    {/* <h1 className="text-[48px] max-md:text-[32px] pb-[1.2rem]">{sessionId?.Class?.content2_url1}</h1> */}
                  </Card>
                </div>
              )}
              {sessionId?.Class?.content3_url1 && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setContentId(3);
                    setContentInfo(sessionId?.Class?.content3_url1);
                    setSessionPervPrecense(sessionId?.Presence?.classroom_presence_percentage);
                    setIsReady(false)

                  }}
                >
                  <Card className="px-[6.9rem] pt-[4.3rem] pb-[4.3rem] max-md:p-5  ">
                    <h2 className="text-[12px] pb-[1.8rem]">بخش</h2>
                    <h1 className="text-[48px] max-md:text-[32px] pb-[1.2rem]">3</h1>
                    {/* <h1 className="text-[48px] max-md:text-[32px] pb-[1.2rem]">{sessionId?.Class?.content3_url1}</h1> */}
                  </Card>
                </div>
              )}
              {sessionId?.Class?.content4_url1 && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setContentId(4);
                    setContentInfo(sessionId?.Class?.content4_url1);
                    setSessionPervPrecense(sessionId?.Presence?.classroom_presence_percentage);
                    setIsReady(false)

                  }}
                >
                  <Card className="px-[6.9rem] pt-[4.3rem] pb-[4.3rem] max-md:p-5  ">
                    <h2 className="text-[12px] pb-[1.8rem]">بخش</h2>
                    <h1 className="text-[48px] max-md:text-[32px] pb-[1.2rem]">4</h1>
                    {/* <h1 className="text-[48px] max-md:text-[32px] pb-[1.2rem]">{sessionId?.Class?.content4_url1}</h1> */}
                  </Card>
                </div>
              )}
              {sessionId?.Class?.content5_url1 && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setContentId(5);
                    setContentInfo(sessionId?.Class?.content5_url1);
                    setSessionPervPrecense(sessionId?.Presence?.classroom_presence_percentage);
                    setIsReady(false)
                  
                  }}
                >
                  <Card className="px-[6.9rem] pt-[4.3rem] pb-[4.3rem] max-md:p-5  ">
                    <h2 className="text-[12px] pb-[1.8rem]">بخش</h2>
                    <h1 className="text-[48px] max-md:text-[32px] pb-[1.2rem]">5</h1>
                    {/* <h1 className="text-[48px] max-md:text-[32px] pb-[1.2rem]">{sessionId?.Class?.content5_url1}</h1> */}
                  </Card>
                </div>
              )}
            </>
          ) : (
            cardArray.map((x) => (
              <Card className="px-[1.6rem] pt-[2.4rem] pb-[1.6rem]">
                <h1 className="text-[16px] pb-[1.2rem]">{x.name}</h1>
                <h2 className="text-[12px] pb-[1.8rem]">{x.name}</h2>
                <BtnAlternative
                  className="border-[#EAEBED] text-blue border-2 py-4 px-[5.25rem]"
                  url={x.path}
                  icon={x.icon}
                  text={x.btnTxt}
                />
              </Card>
            ))
          )}
          {/* <p className="opacity-40 pt-6">آخرین بازدید : 20شهریور</p> */}
        </div>
      </div>
    </aside>
  );
}

export default LeftSidebar;

const cardArray = [
  {
    id: 1,
    name: "نتیجه امتحان",
    icon: Result,
    btnTxt: "نتیجه",
    path: "/dashboard/exams",
  },
  {
    id: 2,
    name: "جلسه جدید",
    icon: Play,
    btnTxt: "تماشا",
    path: "/dashboard/classes",
  },
  {
    id: 3,
    name: "تکلیف جدید",
    icon: Edit,
    btnTxt: "انجام",
    path: "/dashboard/homework",
  },
];
// sessionId?.Class.content_set.slice(0, 3).map((x) => (
//   <div
//     style={{ cursor: "pointer" }}
//     onClick={() => {
//       setContentId(x.id);
//       // setVideoSelect(sessionId.Class.content_set[x.id]);
//     }}
//   >

//   </div>
