import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { IconButton, Popover, Stack, Tooltip } from "@mui/material";
import {
  FastRewind,
  FastRewindOutlined,
  PauseSharp,
  Repeat,
  Replay10Outlined,
  Replay30Outlined,
  ResetTvOutlined,
  VolumeDown,
  VolumeOff,
} from "@mui/icons-material";
import { FastForwardSharp } from "@mui/icons-material";
import { PlayArrowSharp } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { VolumeUp } from "@mui/icons-material";
import { Fullscreen } from "@mui/icons-material";

const ControlIcons = ({
  playandpause,
  playing,
  rewind10,
  rewind30,
  played,
  onSeek,
  onSeekMouseUp,
  playedTime,
  fullMovieTime,
  muting,
  muted,
  volume,
  volumeChange,
  volumeSeek,
  playbackRate,
  playRate,
  playerbackRate,
  fullScreenMode,
  resetVideo,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "playbackrate-popover" : undefined;
  const PrettoSlider = styled(Slider)({
    height: 5,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 16,
      width: 16,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: "#52af77",
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });
  const rates = [0.5, 1, 1.5, 2];
  const [fontSize, setFontSize] = useState("");
  useEffect(() => {
    if (window.innerWidth < 764) {
      setFontSize("medium");
    } else {
      setFontSize("large");
    }
  }, []);

  return (
    <div className="controls__div px-10" style={{ cursor: "pointer" }}>
      {/* Top Segment */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="start"
        style={{ padding: 16 }}
      >
        <Grid item>
          <Typography variant="h5" style={{ color: "white" }}></Typography>
        </Grid>
      </Grid>

      {/* Middle Segment */}
      {/* <Grid container direction="row" alignItems="center" justifyContent="center">
        <IconButton className="controls__icons" aria-label="reqind">
          <PlayArrowSharp fontSize={fontSize} style={{ color: "white" }} />
        </IconButton>
      </Grid> */}

      {/* Bottom Segment */}
      <Grid
      className="conBack"
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{borderBottomRightRadius:"0.75rem",borderBottomLeftRadius:"0.75rem",overflow:"hidden",padding: 10}}
      >
        <Grid item >
          <Grid container  alignItems="center" direction="row">
            <IconButton
              className="controls__icons"
              aria-label="reqind"
              onClick={playandpause}
            >
              {playing ? (
                <PauseSharp fontSize={fontSize} style={{ color: "white" }} />
              ) : (
                <PlayArrowSharp
                  fontSize={fontSize}
                  style={{ color: "white" }}
                />
              )}
            </IconButton>
            <Tooltip title="بازگشت به ابتدا">
              <IconButton
                className="controls__icons"
                aria-label="reqind"
                onClick={resetVideo}
              >
                <Repeat fontSize={fontSize} style={{ color: "white" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="10 ثانیه به عقب">
              <IconButton
                className="controls__icons"
                aria-label="reqind"
                onClick={rewind10}
              >
                <Replay10Outlined
                  fontSize={fontSize}
                  style={{ color: "white" }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="30 ثانیه به عقب">
              <IconButton
                className="controls__icons"
                aria-label="reqind"
                onClick={rewind30}
              >
                <Replay30Outlined
                  fontSize={fontSize}
                  style={{ color: "white" }}
                />
              </IconButton>
            </Tooltip>

            {/* <IconButton className="controls__icons " aria-label="reqind" onClick={muting}>
              {muted ? <VolumeOff fontSize={fontSize} style={{ color: "white" }} /> : <VolumeUp fontSize={fontSize} style={{ color: "white" }} />}
            </IconButton>
            <Typography style={{ color: "#fff", paddingTop: "5px" }}>
              {volume * 100}
            </Typography>

            <Slider
              min={0}
              max={100}
              value={volume * 100}
              className="volume__slider"
              style={{ marginLeft: "21rem", direction: "ltr" }}
              sx={{ direction: "ltr" }}
              onChange={volumeChange}
              onChangeCommitted={volumeSeek}
            /> */}
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="h7" style={{ color: "white" }}>
            {playedTime}
          </Typography>
          <p className="text-[#FFFF] inline"> / </p>
          <Typography variant="h7" style={{ color: "white" }}>
            {fullMovieTime}
          </Typography>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            anchorPosition={{ top: 200, left: 400 }}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            {/* <Grid direction="column-reverse">
              {rates.map((rate) => (
                <Button variant="text" onClick={() => playRate(rate)}>
                  <Typography color={rate === playerbackRate ? "secondary" : "default"}>{rate}</Typography>
                </Button>
              ))}
            </Grid> */}
          </Popover>
          {/* <Button variant="text" onClick={handlePopOver} className="bottom__icons">
            <Typography>{playerbackRate}X</Typography>
          </Button> */}

          <IconButton className="bottom__icons" onClick={fullScreenMode}>
            <Fullscreen
              style={{ zIndex: 0 }}
              fontSize={fontSize}
              className="controls__icons"
              aria-label="reqind"
            />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default ControlIcons;
