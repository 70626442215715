import React from "react";
import { Card } from "../../../shares/card/Card";
import Header from "./Header";
import ClassCard from "./ClassCard";

const Index = () => {
  return (
    <div>
      <Header />
      <div className="container ">
        <ClassCard />
      </div>
    </div>
  );
};

export default Index;
