import React, { useRef, useEffect, useState } from "react";
import { BtnPrimary } from "../../../../shares/button/Button";
import { Card } from "../../../../shares/card/Card";
import moment from "jalali-moment";
import { Link } from "react-router-dom";
import useFetch from "../../../../../hooks/useFetch";
import { toast } from "react-toastify";

const CurrentExam = ({ exam, index }) => {
  const [started, setStarted] = useState();
  const linkRef = useRef();

  const startExam = useFetch({
    method: "GET",
    url: `exam-platform/exam/${exam.score.exam}`,
    noHeader: false,
    trigger: false,
  });

  useEffect(() => {
    const now = Date.now();
    const timeToCheck = Date.parse(exam?.exam_available_time_start);

    if (now > timeToCheck) {
      setStarted(true);
    } else {
      setStarted(false);
    }
  }, []);

  const handleClick = () => {
    startExam.reFetch();
  };

  useEffect(() => {
    if (startExam.status === 200) {
      linkRef.current.click();
    } else if (startExam.status === 208) {
      toast.error("شما مجاز به شرکت در این آزمون نیستید.", {
        position: "bottom-left",
        rtl: true,
        autoClose: 5000,
        theme: "color",
      });
    }
  }, [startExam.status]);
  return (
    <Card className="grid grid-cols-10 mb-5 justify-between items-center grid-rows-2 md:grid-rows-1">
      <div className="h-full w-full flex justify-center items-center md:border-l-2 border-inherit col-span-10 md:col-span-1">
        <h1 className="text-[2.4rem]">{index + 1}</h1>
      </div>
      <div className="text-start col-span-4 pt-9 pb-8 pr-12">
        <h4 className="text-[1.6rem] flex">
          {exam?.exam_name}
          <span className=" text-[1.2rem] px-[1.5rem] opacity-50">
            {/* {moment(exam?.exam_available_time_end, "YYYY/MM/DD")
              .locale("fa")
              .format("YYYY/MM/DD")} */}
          </span>
        </h4>
        <p className="text-[1.4rem] pt-[.6rem] opacity-50 ">
          {exam?.exam_headline}
        </p>
      </div>
      <div className="text-[1.4rem] flex flex-col sm:flex-row justify-between sm:items-center md:col-span-5 sm:pl-12 col-span-6 items-stretch gap-y-4">
        <p className="  px-[1.45rem]  ">
          آخرین مهلت ورود:{" "}
          {moment(exam?.exam_maxenterance_time, "YYYY/MM/DD HH:mm")
            .locale("fa")
            .format("YYYY/MM/DD HH:mm")}
        </p>

        <p className=" px-[1.45rem] border-x-2 border-inherit ">
          {moment(exam?.exam_duration, "hh:m:s")
            .locale("fa")
            .format("mm دقیقه ss ثانیه")}{" "}
        </p>

        <p className=" px-[1.45rem] ">
          10
          <span className="opacity-50">سوال</span>
        </p>

        <button
          className={`py-[1.2rem] px-[2.2rem] text-white bg-blue hover:bg-blueHover transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] pointe ${
            !started && "pointer-events-none opacity-50 border-none"
          }`}
          onClick={handleClick}
        >
          شرکت در آزمون
        </button>
        <Link
          to={`/dashboard/exam/${exam.score.exam}`}
          target="_blank"
          className="py-[1.2rem] px-[2.2rem] text-white bg-blue hover:bg-blueHover transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] hidden"
          ref={linkRef}
        >
          شرکت در آزمون
        </Link>
      </div>
    </Card>
  );
};

export default CurrentExam;
