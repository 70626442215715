import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import InfoIcon from "@mui/icons-material/Info";
import { Chip, Modal } from "@mui/material";
import AddBooks from "./modals/AddBooks";
import { DeleteOutline } from "@mui/icons-material";

const BooksAssign = () => {
  const [books, setBooks] = useState([]);
  const [addBooks, setAddBooks] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [bookIds, setBookIds] = useState({
    book_ids: [],
  });
  const { Id } = useParams();

  useEffect(() => {
    setBookIds({ ...bookIds, book_ids: books?.map((book) => book.id) });
  }, [books]);

  const handleClose = () => {
    setAddBooks(false);
  };

  const handleDelete = (e) => {
    const idToRemove = e;
    setBookIds((prevState) => ({
      ...prevState,
      book_ids: prevState.book_ids.filter((id) => id !== idToRemove),
    }));
    deleteBooks.reFetch();
  };

  const getBooks = useFetch({
    method: "GET",
    url: `management/users/${Id}/user_books/`,
    noHeader: false,
    trigger: true,
    setter: setBooks,
  });

  const deleteBooks = useFetch({
    method: "PATCH",
    url: `management/users/${Id}/user_books/`,
    noHeader: false,
    trigger: false,
    caller: getBooks,
    data: bookIds,
  });

  return (
    <div className="my-16 text-right px-10">
      <h6 className="mb-4">جزوات</h6>
      <div className="flex flex-wrap gap-x-4">
        {books.length > 0 ? (
          <>
            {books.map((book) => {
              return (
                <Chip
                  label={book.booksname}
                  onDelete={() => handleDelete(book.id)}
                  deleteIcon={<DeleteOutline className="!m-0" />}
                />
              );
            })}
            <Chip
              label={"+ افزودن جزوه"}
              className="!bg-[#8200d3] !text-white "
              onClick={() => {
                setAddBooks(true);
              }}
            />
          </>
        ) : (
          <div className=" flex items-center gap-x-4">
            <span className="font-light">
              <InfoIcon color="secondary" /> برای این کاربر جزوه‌ای اختصاص داده
              نشده است.
            </span>
            <Chip
              label={"+ افزودن جزوه"}
              className="!bg-[#8200d3] !text-white !cursor-pointer"
              onClick={() => {
                setAddBooks(true);
              }}
            />
          </div>
        )}
      </div>
      <Modal
        open={addBooks}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AddBooks
          close={handleClose}
          get={getBooks}
          Id={Id}
          bookList={books}
          setBookList={setBooks}
        />
      </Modal>
    </div>
  );
};

export default BooksAssign;
