import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Loading } from "../utils/Loading";
// import { useAuth } from "../Context/AuthContext";
import PropTypes from "prop-types";

const useFetch = ({
  method,
  url,
  data,
  headers = null,
  trigger = false,
  noHeader = false,
  argFunc = null,
  caller = null,
  params = null,
  errMessage,
  setter = null,
}) => {
  useFetch.propTypes = {
    errMessage: PropTypes.func,
  };

  const [refetchStatus, setRefetchStatus] = useState(trigger);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState();
  const [innerTrigger, setInnerTrigger] = useState(0);
  const controllerRef = useRef(new AbortController());
  const token = Cookies.get("token");
  const isOnline = window.navigator.onLine;

  const apiCall = async () => {
    try {
      if (!isOnline) {
        toast.error("مشکل در اتصال به اینترنت", {
          position: "bottom-left",
          rtl: true,
          autoClose: 2000,
        });
      }
      setLoading(true);
      const result = await axios.request({
        params: params,
        method: method,
        url: `https://lab.mindescape.co/${url}`,
        data: data,
        headers: !noHeader
          ? {
              Authorization: `Token ${token}`,
              // "X-CSRFTOKEN": Cookies.get("csrftoken"),
            }
          : {},

        signal: controllerRef.current.signal,
      });
      //console.log(result);
      if (setter !== null) setter(result.data);
      if (caller !== null) caller.reFetch();
      if (argFunc !== null) argFunc(result.data);
      setRefetchStatus(false);
      setResponse(result.data);
      setStatus(result.status);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        toast.error("لطفا وارد شوید", {
          position: "bottom-left",
          rtl: true,
          autoClose: 2000,
        });
      } else if (error.response && error.response.status > 500) {
        toast.error("مشکلی سمت سرور پیش آمده", {
          position: "bottom-left",
          rtl: true,
          autoClose: 2000,
        });
      } else {
        // toast.error("مشکلی  پیش آمده", { position: "bottom-left", rtl: true, autoClose: 2000 });
      }
      setError(error);
      // console.log(error);
      errMessage && errMessage();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (typeof trigger === "undefined" && !innerTrigger) return;
    // if (trigger) apiCall();
    if (refetchStatus) {
      setLoading(true);
      apiCall();
    }
  }, [innerTrigger]);

  const cancelRequest = () => {
    controllerRef.current.abort();
  };
  const reFetch = (type) => {
    // console.log("re", params);
    // apiCall(nData, params);
    setInnerTrigger(+new Date());
    setRefetchStatus(true);
    // if (type !== "post") setPostReq(true);
  };

  return { response, error, loading, status, cancelRequest, reFetch };
};

export default useFetch;
