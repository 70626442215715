import React, { useState, useEffect } from "react";
import { Card } from "../../../shares/card/Card";
import ShareTable from "../../../shares/tables/ShareTable";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { TextFields, TextureRounded, TextureSharp } from "@mui/icons-material";
import { BtnPrimary } from "../../../shares/button/Button";
import { Modal, Chip } from "@mui/material";
import AddGroups from "./modals/AddVideos";
import useFetch from "../../../../hooks/useFetch";
import moment from "jalali-moment";
import { TextField } from "@mui/material";
import Search from "../../../shares/search/Search";

const Question = () => {
  const [classFilter, setClassFilter] = useState("");
  const [newModal, setNewModal] = useState(false);
  const [edit, setEdit] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [questions, setQuestion] = useState();
  const [newQuestion, setNewQuestion] = useState();
  const [id, setId] = useState();
  const [groups, setGroups] = useState();

  const handleClose = () => {
    setNewModal(false);
    setEdit(null);
    setDeleteModal(false);
  };
  const handleEdit = (id) => {
    setNewModal(true);
    setEdit(id);
  };

  const getQuestion = useFetch({
    method: "GET",
    url: `management/exams/`,
    noHeader: false,
    trigger: true,
    setter: setQuestion,
  });
  const deleteGroup = useFetch({
    method: "DELETE",
    url: `management/exams/${id}/`,
    noHeader: false,
    trigger: false,
    caller: getQuestion,
  });

  useEffect(() => {
    setNewQuestion(
      questions?.results.map((question) => {
        const newQuestion = {
          ...question,
          exam_available_time_start: moment(
            question.exam_available_time_start,
            "YYYY/MM/DD HH:mm"
          )
            .locale("fa")
            .format("YYYY/MM/DD HH:mm"),

          exam_group: (
            <Chip label={question.exam_group.name} className="mx-1 my-1" />
          ),
          question_img: <img src={question.question_img} />,
          edit: (
            <EditIcon
              onClick={() => handleEdit(question.exam_id)}
              className="cursor-pointer"
            />
          ),
          delete: (
            <DeleteIcon
              onClick={() => setId(question.exam_id)}
              className="cursor-pointer"
            />
          ),
        };

        return newQuestion;
      })
    );
  }, [questions]);

  useEffect(() => {
    deleteGroup.reFetch();
  }, [id]);

  return (
    <div className="flex mt-20">
      <Card className="p-16 w-full text-right">
        <div className="flex justify-between items-center border-b-2 border-gray-200 pb-8 mb-12">
          <h3 className="text-3xl">آزمون ها</h3>
        </div>
        <div className="flex items-center mb-6">
          <Search newData={setQuestion} url="exams" />
        </div>

        <ShareTable
          data={newQuestion ? newQuestion : []}
          columns={columns}
          next={
            questions?.next
              ? questions?.next?.replace(/https:\/\/lab\.mindescape\.co\//, "")
              : null
          }
          perv={
            questions?.previous
              ? questions?.previous?.replace(
                  /https:\/\/lab\.mindescape\.co\//,
                  ""
                )
              : null
          }
          newData={setQuestion}
          getLoad={getQuestion.loading}
        />
      </Card>
      <Modal
        open={newModal}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AddGroups close={handleClose} get={getQuestion} edit={edit} />
      </Modal>
    </div>
  );
};

export default Question;

const columns = [
  { id: "ExamName", label: "نام" },
  { id: "exam_available_time_start", label: "زمان شروع	" },
  { id: "exam_duration", label: "زمان" },
  // { id: "exam_question_bank", label: "دسته سوال	" },
  { id: "exam_group", label: "گروه " },
  { id: "edit", label: "ویرایش" },
  { id: "delete", label: "حذف" },

  // Add more columns as needed
];

const lessons = [
  "توابع و خواص آن ها",
  "معادله درجه دوم",
  "الگو و دنباله",
  "مثلثات",
  "توان های گویا و عبارات جبری",
  "مرور توابع و خواص آن ها",
  "آنالیز",
  "احتمالات",
  "هندسه مختصاتی",
  "معادلات و نامعادلات گویا و گنگ",
  "قدر مطلق",
  "براکت",
  "هندسه",
  "توابع نمایی و لگاریتم",
  "حد",
  "پیوستگی",
  "بخش پذیری",
  "مشتق",
  "کاربرد مشتق",
  "مجانب ها",
  "مقاطع مخروطی",
];
