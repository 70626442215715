import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CloseSharp } from "@mui/icons-material";
import { BtnAlternative, BtnPrimary } from "../../../../shares/button/Button";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import useFetch from "../../../../../hooks/useFetch";
import { Input, FormControl, FormHelperText } from "@mui/material";
import moment from "jalali-moment";

const AddGroups = ({ close, get, edit }) => {
  const [call, setCall] = useState({
    username: "",
    first_name: "",
    last_name: "",
    groups: [],
    password: "",
    studentuser: {
      father_name: "",
      phone_number: "",
      father_number: "",
      mother_number: "",
      home_number: "",
      address: "",
      registration_date: moment().format("YYYY-MM-DD"),
      student_school: "",
      student_type: "",
      student_gender: "",
      student_grade: "",
    },
  });

  const newGroup = useFetch({
    method: "POST",
    url: `management/users/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: call,
  });

  const onSubmit = (e) => {
    // e.preventDefault();

    newGroup.reFetch();
  };
  useEffect(() => {
    newGroup.status === 201 && close();
  }, [newGroup.status]);

  return (
    <div className="relative rounded-2xl w-2/5 bg-white">
      <CloseSharp
        className="absolute top-2 right-[6px] cursor-pointer"
        onClick={close}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pt-16 pb-10 px-14 text-xl"
      >
        <h6 className="font-extrabold text-2xl border-b-2 w-full pb-4 mb-6">
          {edit ? "ویرایش" : "دانش آموز جدید"}
        </h6>
        <div className="mb-6">
          <label htmlFor="username" className="block mb-1">
            کدملی
          </label>
          <TextField
            name="username"
            type={"number"}
            value={call.username}
            placeholder="کدملی"
            {...register("username", {
              required: "کدملی را وارد کنید",
            })}
            error={!!errors.username}
            helperText={errors.username ? errors.username.message : ""}
            onChange={(e) => {
              setCall({ ...call, username: e.target.value });
            }}
            fullWidth
          />
        </div>
        <div className="mb-6">
          <label htmlFor="first_name" className="block mb-1">
            نام
          </label>
          <TextField
            name="first_name"
            value={call.first_name}
            placeholder="نام"
            {...register("first_name", {
              required: "نام را وارد کنید",
            })}
            error={!!errors.first_name}
            helperText={errors.first_name ? errors.first_name.message : ""}
            onChange={(e) => {
              setCall({ ...call, first_name: e.target.value });
            }}
            fullWidth
          />
        </div>
        <div className="mb-6">
          <label htmlFor="last_name" className="block mb-1">
            نام خانوادگی
          </label>
          <TextField
            name="last_name"
            value={call.last_name}
            placeholder="نام خانوادگی"
            {...register("last_name", {
              required: "نام خانوادگی را وارد کنید",
            })}
            error={!!errors.last_name}
            helperText={errors.last_name ? errors.last_name.message : ""}
            onChange={(e) => {
              setCall({ ...call, last_name: e.target.value });
            }}
            fullWidth
          />
        </div>

        <div className="mb-6">
          <label htmlFor="password" className="block mb-1">
            رمز عبور
          </label>
          <TextField
            name="password"
            value={call.password}
            placeholder="رمز عبور"
            {...register("password", {
              required: "رمز عبور را وارد کنید",
            })}
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : ""}
            onChange={(e) => {
              setCall({ ...call, password: e.target.value });
            }}
            fullWidth
          />
        </div>

        <div className="mr-auto ml-0 w-fit">
          <button
            type="submit"
            className="text-white bg-[#8300d3] hover:bg-[#8300b4] transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 "
          >
            ثبت
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddGroups;

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
