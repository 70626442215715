import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CloseSharp } from "@mui/icons-material";
import { BtnAlternative, BtnPrimary } from "../../../../shares/button/Button";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import useFetch from "../../../../../hooks/useFetch";
import { Input, FormControl, FormHelperText } from "@mui/material";

const ScoreModal = ({ close, get, id, scores }) => {
  const divs = [];

  for (let i = 1; i <= 20; i++) {
    const value = scores[`q${i}_score`];
    divs.push(
      <div className="mb-6">
        <label htmlFor={`q${i}_score`} className="block mb-1">
          سوال {i}
        </label>
        <TextField
          name={`q${i}_score`}
          value={value}
          placeholder={`${i}`}
          fullWidth
          disabled
        />
      </div>
    );
  }

  return (
    <div className="relative rounded-2xl w-2/5 bg-white">
      <CloseSharp
        className="absolute top-2 right-[6px] cursor-pointer"
        onClick={close}
      />
      <form className="pt-8 pb-10 px-14 text-xl overflow-scroll max-h-[80vh] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-3">
        {divs}
      </form>
    </div>
  );
};

export default ScoreModal;

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
