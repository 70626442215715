import React from "react";
import { Routes, Route } from "react-router-dom";
import Admin from "../layout/admin/Admin";
import Groups from "../components/layouts/admin/groups/Groups";
import Videos from "../components/layouts/admin/videos/videos";
import HomeworkAnswers from "../components/layouts/admin/homework/HomeworkAnswers";
import HomeworkList from "../components/layouts/admin/homework/HomeworkList";
import Exams from "../components/layouts/admin/exams/Exams";
import QuestionCategory from "../components/layouts/admin/questionCategory/QuestionCategory";
import Questions from "../components/layouts/admin/questions/Questions";
import Notes from "../components/layouts/admin/notes/Notes";
import Students from "../components/layouts/admin/managment/Students";
import Employees from "../components/layouts/admin/managment/Employees";
import Profile from "../components/layouts/admin/managment/Profile";
import NotFoundPage from "./NotFoundPage";
import Books from "../components/layouts/admin/books/Books";

function AdminRouter(props) {
  return (
    <Routes>
      <Route element={<Admin />}>
        <Route key={"groups"} path="/groups" element={<Groups />} />
        <Route key={"videos"} path="/videos" element={<Videos />} />
        <Route key={"exams"} path="/exams" element={<Exams />} />
        <Route
          key={"question-cat"}
          path="/question-cat"
          element={<QuestionCategory />}
        />
        <Route key={"question"} path="/questions" element={<Questions />} />
        {/* <Route key={"notes"} path="/notes" element={<Notes />} /> */}
        <Route
          key={"HomeworkAnswers"}
          path="/homework/answers"
          element={<HomeworkAnswers />}
        />
        <Route
          key={"HomeworkList"}
          path="/homework/list"
          element={<HomeworkList />}
        />
        <Route
          key={"Student-management"}
          path="/management/student"
          element={<Students />}
        />
        <Route
          key={"Student-management-profile"}
          path="/management/student/:Id"
          element={<Profile />}
        />
        <Route key={"Books"} path="/books" element={<Books />} />
        {/* <Route
          key={"Employees-management"}
          path="/management/employee"
          element={<Employees />}
        /> */}
        <Route key={"*"} path="*" element={<NotFoundPage />} />
      </Route>
      ;
    </Routes>
  );
}

export default AdminRouter;
