import Wallet from "../../../../../assets/img/icons/Wallet-2.svg";
import React, { useState } from "react";
import VideocamIcon from "@mui/icons-material/Videocam";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { Box } from "@mui/system";
import { NavLink, useMatch, useNavigate } from "react-router-dom";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Logo from "../../../../../assets/img/logo.png";
import { Button, Divider, Drawer, SwipeableDrawer } from "@mui/material";
import { useAuth } from "../../../../../contexts/AuthContext";
import { AccountCircleSharp } from "@mui/icons-material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const ResRightSidebar = () => {
  const homeMatch = useMatch("/dashboard/home");
  const sessionMatch = useMatch("/dashboard/classes");
  const examMatch = useMatch("/dashboard/exams");
  const homeWorkMatch = useMatch("/dashboard/homework");
  const booksMatch = useMatch("/dashboard/books");
  const rulesMatch = useMatch("/dashboard/rules");

  const [isOpen, setIsOpen] = useState(false);
  const { loginDispatch } = useAuth();
  const navigate = useNavigate();

  const logoutFunc = () => {
    loginDispatch({
      type: "LOGOUT",
    });
    navigate("/");
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(!isOpen);
  };
  var anchor = "right";

  return (
    <>
      <div className="z-50">
        <Button
          sx={{ position: "fixed", justifyContent: "flex-start" }}
          onClick={toggleDrawer(true)}
          className={`   ${isOpen ? "" : "hidden"}`}
          color="inherit"
        >
          <WidgetsIcon
            sx={{ fontSize: "4rem", marginTop: "3rem" }}
            color="primary"
          />
        </Button>
      </div>
      <Box
        id="sidebar"
        className={`${isOpen ? "" : "hidden"}`}
        sx={{
          width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <SwipeableDrawer
          anchor={anchor}
          open={isOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <aside
            id="default-sidebar"
            aria-label="Sidebar"
            className={`h-full sidebarRight text-[#A5B0C2] bg-white text-[1.5rem] `}
          >
            <div className="sidebarRight-wrapper pr-8 pl-24">
              <div className="sidebarRight-container">
                <div className="logo-container pt-[3.27rem]">
                  <img className="logo" src={Logo}></img>
                </div>

                <div className="nav-container py-52">
                  <ul className="nav-list flex flex-col gap-y-11 ">
                    <li>
                      <NavLink exact to={"/dashboard/home"} className={`flex`}>
                        <AccountBalanceWalletIcon
                          className={`ml-[1.77rem]`}
                          sx={{
                            color: homeMatch ? "blue" : "#A5B0C4",
                            fontSize: 20,
                          }}
                        />
                        داشبورد کاربر
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to={"/dashboard/classes"}
                        className={`flex`}
                      >
                        <VideocamIcon
                          className={`ml-[1.77rem]`}
                          sx={{
                            color: sessionMatch ? "blue" : "#A5B0C4",
                            fontSize: 20,
                          }}
                        />
                        جلسات
                      </NavLink>
                    </li>
                    <li>
                      <NavLink exact to={"/dashboard/exams"} className={`flex`}>
                        <TextSnippetIcon
                          className={`ml-[1.77rem]`}
                          sx={{
                            color: examMatch ? "blue" : "#A5B0C4",
                            fontSize: 20,
                          }}
                        />
                        امتحانات
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to={"/dashboard/homework"}
                        className={`flex`}
                      >
                        <DriveFileRenameOutlineIcon
                          className={`ml-[1.77rem]`}
                          sx={{
                            color: homeWorkMatch ? "blue" : "#A5B0C4",
                            fontSize: 20,
                          }}
                        />
                        تکالیف
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink exact to={"/dashboard/books"} className={`flex`}>
                        <AccountCircleSharp
                          className={`ml-[1.77rem]`}
                          sx={{
                            color: booksMatch ? "blue" : "#A5B0C4",
                            fontSize: 20,
                          }}
                        />
                        جزوه
                      </NavLink>
                    </li> */}
                    <hr />
                    <li className="">
                      <NavLink exact to={"/dashboard/rules"} className={`flex`}>
                        <VerifiedUserIcon
                          className={`ml-[1.77rem]`}
                          sx={{
                            color: rulesMatch ? "blue" : "#A5B0C4",
                            fontSize: 20,
                          }}
                        />
                        قوانین
                      </NavLink>
                    </li>
                    <li onClick={logoutFunc}>
                      <a className="text-red-500">
                        <LogoutRoundedIcon
                          className="ml-[1.77rem]"
                          sx={{ color: "#EF4444", fontSize: 20 }}
                        />{" "}
                        خروج
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </aside>
        </SwipeableDrawer>
      </Box>
    </>
  );
};

export default ResRightSidebar;
