import React, { useState, useEffect } from "react";
import { Card } from "../../../shares/card/Card";
import useFetch from "../../../../hooks/useFetch";
import moment from "jalali-moment";
import ShareStepper from "../../../shares/stepper/Stepper";
import {
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import ShareTable from "../../../shares/tables/ShareTable";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Search from "../../../shares/search/Search";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

const QuestionCategory = () => {
  const navigate = useNavigate();

  const [selectedQuestion, setSelectedQuestion] = useState([]);

  const [call, setCall] = useState({
    ExamName: "",
    exam_headline: "",
    exam_available_time_start: "",
    exam_available_time_end: "",
    exam_maxenterance_time: "",
    exam_question_bank: "",
    questions: [],
    // exam_answer_file: Empty,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [questions, setQuestion] = useState();
  const [newQuestion, setNewQuestion] = useState();
  const [gg, setGg] = useState();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleNext = (event) => {
    event.preventDefault();
    setActiveStep((prevStep) => prevStep + 1);
  };
  const handleBack = (event) => {
    event.preventDefault();

    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleClose = () => {};

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const currentIndex = selectedQuestion.indexOf(value);
    const newChecked = selectedQuestion;

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedQuestion([...newChecked]);
  };

  useEffect(() => {
    console.log(selectedQuestion);
  }, [selectedQuestion]);
  const onSubmit = (e) => {
    newExam.reFetch();
  };

  const getQuestion = useFetch({
    method: "GET",
    url: `management/questions/`,
    noHeader: false,
    trigger: true,
    setter: setQuestion,
  });

  const getGg = useFetch({
    method: "GET",
    url: `management/groups/?page_size=1000`,
    noHeader: false,
    trigger: true,
    setter: setGg,
  });

  const newExam = useFetch({
    method: "POST",
    url: `management/exams/`,
    noHeader: false,
    trigger: false,
    data: call,
  });

  useEffect(() => {
    setNewQuestion(
      questions?.results.map((question) => {
        const newQuestion = {
          ...question,
          select: (
            <Checkbox
              value={question.question_id}
              onChange={handleCheckboxChange}
              checked={selectedQuestion.indexOf(question.question_id) !== -1}
              inputProps={{ "aria-label": "controlled" }}
            />
          ),
          question_img: (
            <img
              src={question.question_img}
              className="mx-auto w-4/5 max-h-60"
            />
          ),
        };

        return newQuestion;
      })
    );
  }, [questions, selectedQuestion]);

  useEffect(() => {
    setCall({
      ...call,
      questions: selectedQuestion,
    });
  }, [selectedQuestion]);

  useEffect(() => {
    if (newExam.status === 201)
      toast.success("آزمون با موفقیت ساخته شد!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        rtl: true,
      });
    if (newExam.status === 201) navigate("/admin/exams");
  }, [newExam.status]);

  const steps = ["مرحله اول", "مرحله دوم"];

  const [search, setSearch] = useState("");

  const filteredArray = newQuestion?.filter((item) => {
    const nameMatch = item.question_headline
      .toLowerCase()
      .includes(search.toLowerCase());
    return nameMatch;
  });

  return (
    <div className="flex mt-20">
      <Card className="p-16 w-full text-right">
        <div className="flex flex-col justify-center items-center border-b-2 border-gray-200 pb-8 mb-12">
          <h3 className="text-3xl mb-8">ساخت آزمون جدید</h3>
          <ShareStepper
            steps={steps}
            activeStep={activeStep}
            onNext={handleNext}
            onBack={handleBack}
          />
        </div>
        {activeStep === 0 ? (
          <>
            <div className="mb-6">
              <div className="flex items-center mb-6">
                <Search newData={setQuestion} url="questions" />
              </div>
              <ShareTable
                data={newQuestion ? newQuestion : []}
                columns={columns}
                next={
                  questions?.next
                    ? questions?.next?.replace(
                        /https:\/\/lab\.mindescape\.co\//,
                        ""
                      )
                    : null
                }
                perv={
                  questions?.previous
                    ? questions?.previous?.replace(
                        /https:\/\/lab\.mindescape\.co\//,
                        ""
                      )
                    : null
                }
                newData={setQuestion}
                getLoad={getQuestion.loading}
              />
            </div>
            <div>
              <a
                onClick={handleBack}
                className={`text-black border-2 border-[#8200d3] bg-white text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 inline-block ${
                  activeStep === 0
                    ? "pointer-events-none opacity-50 border-none"
                    : ""
                }`}
              >
                قبلی
              </a>
              <a
                onClick={handleNext}
                className={`text-black border-2 border-[#8200d3] bg-white text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 inline-block ${
                  selectedQuestion.length !== 10
                    ? "pointer-events-none opacity-50 border-none"
                    : ""
                }`}
              >
                بعدی
              </a>
              <span className="mr-4">
                تعداد سوال انتخابی: {selectedQuestion.length}/10
              </span>
            </div>
          </>
        ) : activeStep === 1 ? (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-6">
                <label htmlFor="ExamName" className="block mt-6 mb-1">
                  عنوان آزمون
                </label>
                <TextField
                  name="ExamName"
                  value={call.question_headline}
                  placeholder="عنوان"
                  {...register("ExamName", {
                    required: "عنوان را وارد کنید",
                  })}
                  error={!!errors.ExamName}
                  helperText={errors.ExamName ? errors.ExamName.message : ""}
                  onChange={(e) => {
                    setCall({ ...call, ExamName: e.target.value });
                  }}
                  fullWidth
                />
              </div>
              <div className="mb-6">
                <label htmlFor="exam_headline" className="block mb-1">
                  مبحث
                </label>
                <FormControl
                  error={errors.exam_headline ? true : false}
                  fullWidth
                >
                  <Select
                    value={call.exam_headline}
                    {...register("exam_headline", {
                      required: "مبحث را وارد کنید",
                    })}
                    fullWidth
                    onChange={(e) => {
                      setCall({ ...call, exam_headline: e.target.value });
                    }}
                  >
                    {lessons.map((lesson) => {
                      return <MenuItem value={lesson}>{lesson}</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText>
                    {errors?.exam_headline?.message}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="mb-6">
                <label htmlFor="exam_group" className="block mb-1">
                  گروه
                </label>
                <FormControl error={errors.exam_group ? true : false} fullWidth>
                  <Select
                    value={call.exam_group}
                    {...register("exam_group", {
                      required: "گروه را وارد کنید",
                    })}
                    fullWidth
                    onChange={(e) => {
                      setCall({ ...call, exam_group: e.target.value });
                    }}
                  >
                    {gg?.results.map((g) => {
                      return <MenuItem value={g.id}>{g.name}</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText>{errors?.exam_group?.message}</FormHelperText>
                </FormControl>
              </div>
              <div className="mb-6">
                <label htmlFor="start-day" className="block mb-2">
                  روز شروع
                </label>
                <FormControl
                  error={errors.exam_available_time_start ? true : false}
                  fullWidth
                >
                  <Controller
                    name="start-day"
                    control={control}
                    render={() => (
                      <MobileDateTimePicker
                        label={
                          call?.exam_available_time_start
                            ? moment(
                                call.exam_available_time_start,
                                "YYYY/MM/DD HH:mm"
                              )
                                .locale("fa")
                                .format("YYYY/MM/DD HH:mm")
                            : "روز شروع"
                        }
                        onAccept={(e) =>
                          setCall({
                            ...call,
                            exam_available_time_start:
                              moment(e).format("YYYY-MM-DD HH:mm"),
                          })
                        }
                        // onChange={}
                        views={["year", "month", "day", "hours", "minutes"]}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        className="w-full"
                        ampm={false}
                        slotProps={{
                          field: { shouldRespectLeadingZeros: true },
                          toolbar: { hidden: true },
                          tabs: { hidden: true },
                        }}
                        sx={{
                          "& .PrivatePickersClockNumber-clockNumber": {
                            fontSize: 50,
                          },
                        }}
                      />
                    )}
                  />
                  <FormHelperText>
                    {errors?.assignment_available_time_start?.message}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="mb-6">
                <label htmlFor="class" className="block mb-1">
                  پایان
                </label>
                <FormControl
                  error={errors.exam_available_time_end ? true : false}
                  fullWidth
                >
                  <Controller
                    name="end-day"
                    control={control}
                    render={() => (
                      <MobileDateTimePicker
                        label={
                          call?.exam_available_time_end
                            ? moment(
                                call.exam_available_time_end,
                                "YYYY/MM/DD HH:mm"
                              )
                                .locale("fa")
                                .format("YYYY/MM/DD HH:mm")
                            : "روز پایان"
                        }
                        onAccept={(e) =>
                          setCall({
                            ...call,
                            exam_available_time_end:
                              moment(e).format("YYYY-MM-DD HH:mm"),
                          })
                        }
                        // onChange={}
                        views={["year", "month", "day", "hours", "minutes"]}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        className="w-full"
                        ampm={false}
                        slotProps={{
                          field: { shouldRespectLeadingZeros: true },
                          toolbar: { hidden: true },
                          tabs: { hidden: true },
                        }}
                        sx={{
                          "& .PrivatePickersClockNumber-clockNumber": {
                            fontSize: 50,
                          },
                        }}
                      />
                    )}
                  />
                  <FormHelperText>
                    {errors?.assignment_available_time_start?.message}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="mb-6">
                <label htmlFor="exam_maxenterance_time" className="block mb-1">
                  زمان ورود مجاز آزمون
                </label>
                <FormControl
                  error={errors.exam_maxenterance_time ? true : false}
                  fullWidth
                >
                  <Controller
                    name="max-day"
                    control={control}
                    render={() => (
                      <MobileDateTimePicker
                        label={
                          call?.exam_maxenterance_time
                            ? moment(
                                call.exam_maxenterance_time,
                                "YYYY/MM/DD HH:mm"
                              )
                                .locale("fa")
                                .format("YYYY/MM/DD HH:mm")
                            : "زمان ورود مجاز آزمون"
                        }
                        onAccept={(e) =>
                          setCall({
                            ...call,
                            exam_maxenterance_time:
                              moment(e).format("YYYY-MM-DD HH:mm"),
                          })
                        }
                        // onChange={}
                        views={["year", "month", "day", "hours", "minutes"]}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        className="w-full"
                        ampm={false}
                        slotProps={{
                          field: { shouldRespectLeadingZeros: true },
                          toolbar: { hidden: true },
                          tabs: { hidden: true },
                        }}
                        sx={{
                          "& .PrivatePickersClockNumber-clockNumber": {
                            fontSize: 50,
                          },
                        }}
                      />
                    )}
                  />
                  <FormHelperText>
                    {errors?.exam_maxenterance_time?.message}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <a
                    onClick={handleBack}
                    className={`text-black border-2 border-[#8200d3] bg-white text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 inline-block`}
                  >
                    قبلی
                  </a>
                  <a
                    onClick={handleNext}
                    className={`text-black border-2 border-[#8200d3] bg-white text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 inline-block ${
                      activeStep === 1
                        ? "pointer-events-none opacity-50 border-none"
                        : ""
                    }`}
                  >
                    بعدی
                  </a>
                </div>
                <button
                  type="submit"
                  className="text-white !bg-[#8200d3] hover:bg-[#8200d3] transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] py-4 px-20"
                >
                  ثبت
                </button>
              </div>
            </form>
          </>
        ) : (
          <span>404</span>
        )}
      </Card>
    </div>
  );
};

export default QuestionCategory;

const columns = [
  { id: "select", label: "" },
  { id: "question_headline", label: "عنوان" },
  { id: "question_img", label: "تصویر سوال" },
  { id: "question_answer", label: "گزینه صحیح" },
];

const lessons = [
  "توابع و خواص آن ها",
  "معادله درجه دوم",
  "الگو و دنباله",
  "مثلثات",
  "توان های گویا و عبارات جبری",
  "مرور توابع و خواص آن ها",
  "آنالیز",
  "احتمالات",
  "هندسه مختصاتی",
  "معادلات و نامعادلات گویا و گنگ",
  "قدر مطلق",
  "براکت",
  "هندسه",
  "توابع نمایی و لگاریتم",
  "حد",
  "پیوستگی",
  "بخش پذیری",
  "مشتق",
  "کاربرد مشتق",
  "مجانب ها",
  "مقاطع مخروطی",
];

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
