import React, { useState, useEffect, useLayoutEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CloseSharp } from "@mui/icons-material";
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import useFetch from "../../../../../hooks/useFetch";
import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  FormGroup,
} from "@mui/material";
import ShareStepper from "../../../../shares/stepper/Stepper";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import moment from "jalali-moment";
import { useRef } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

const AddVideos = ({ close, get, edit }) => {
  const [call, setCall] = useState({
    ClassroomName: "",
    classroom_headline: "",
    classroom_available_time_end: "",
    classroom_presence: "",
    classroom_groups: [],
    content1_url1: "",
    content2_url1: "",
    content3_url1: "",
    content4_url1: "",
    content5_url1: "",
    assignment_info: null,
  });
  const [data, setData] = useState();
  const [groups, setGroups] = useState();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (event) => {
    event.preventDefault();
    setActiveStep((prevStep) => prevStep + 1);
  };

  const onSubmit = (data) => {
    if (edit) {
      editClassroom.reFetch();
    } else {
      newVideo.reFetch();
    }
  };

  const getGroup = useFetch({
    method: "GET",
    url: `management/groups/?page_size=1000`,
    noHeader: false,
    trigger: true,
    setter: setGroups,
  });

  const newVideo = useFetch({
    method: "POST",
    url: `management/classrooms/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });

  const editClassroom = useFetch({
    method: "PATCH",
    url: `management/classrooms/${edit}/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });
  const getClassroom = useFetch({
    method: "GET",
    url: `management/classrooms/${edit}/`,
    noHeader: false,
    trigger: edit ? true : false,
    setter: setCall,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: call,
  });

  const handleCheckboxChange = (event) => {
    const value = parseInt(event.target.value);
    const currentIndex = call?.classroom_groups.indexOf(value);
    const newChecked = call?.classroom_groups;

    if (currentIndex === -1) {
      if (newChecked.length < groups.results.length) {
        newChecked.push(value);
      }
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCall({ ...call, classroom_groups: newChecked });
  };

  const steps = ["مرحله اول", "مرحله دوم"];
  const isLastStep = activeStep === steps.length - 1;

  useEffect(() => {
    newVideo.status === 201 && close();
    editClassroom.status === 200 && close();
  }, [newVideo.status || editClassroom.status]);

  const isFirstRender = useRef(true);

  // useEffect(() => {
  //   if (edit && call.classroom_available_time_end && isFirstRender) {
  //     setCall({
  //       ...call,
  //       classroom_available_time_end: new Date(
  //         call.classroom_available_time_end
  //       ),
  //     });
  //     isFirstRender.current = false;
  //   }
  // }, [call]);
  const teest = 1693226465000;

  return (
    <div className="relative rounded-2xl w-2/5 bg-white pt-12 max-h-[85vh] overflow-scroll">
      <CloseSharp
        className="absolute top-2 right-[6px] cursor-pointer"
        onClick={close}
      />
      <ShareStepper steps={steps} activeStep={activeStep} onNext={handleNext} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pt-8 pb-10 px-14 text-xl"
      >
        {activeStep === 0 && (
          <>
            <h6 className="font-extrabold text-2xl border-b-2 w-full pb-4 mb-6">
              {edit ? "ویرایش درس" : "درس جدید"}
            </h6>
            <div className="mb-6">
              <label htmlFor="ClassroomName" className="block mb-1">
                عنوان
              </label>
              <TextField
                name="ClassroomName"
                value={call.ClassroomName}
                placeholder="عنوان"
                {...register("ClassroomName", {
                  required: "عنوان را وارد کنید",
                })}
                error={!!errors.ClassroomName}
                helperText={
                  errors.ClassroomName ? errors.ClassroomName.message : ""
                }
                onChange={(e) => {
                  setCall({ ...call, ClassroomName: e.target.value });
                }}
                fullWidth
              />
            </div>

            <div className="mb-6">
              <label htmlFor="class" className="block mb-1">
                مبحث
              </label>
              <FormControl
                error={errors.classroom_headline ? true : false}
                fullWidth
              >
                <Select
                  value={call.classroom_headline}
                  {...register("classroom_headline", {
                    required: "مبحث را وارد کنید",
                  })}
                  fullWidth
                  onChange={(e) => {
                    setCall({ ...call, classroom_headline: e.target.value });
                  }}
                >
                  {lessons.map((lesson) => {
                    return <MenuItem value={lesson}>{lesson}</MenuItem>;
                  })}
                </Select>
                <FormHelperText>{errors?.group_day?.message}</FormHelperText>
              </FormControl>
            </div>
            <div className="mb-6">
              <label htmlFor="end-day" className="block mb-2">
                روز پایان
              </label>
              <FormControl
                error={errors.classroom_available_time_end ? true : false}
                fullWidth
              >
                <Controller
                  name="end-day"
                  control={control}
                  render={() => (
                    <MobileDatePicker
                      label={
                        call?.classroom_available_time_end
                          ? moment(
                              call.classroom_available_time_end,
                              "YYYY/MM/DD"
                            )
                              .locale("fa")
                              .format("YYYY/MM/DD")
                          : "روز پایان"
                      }
                      onAccept={(e) =>
                        setCall({
                          ...call,
                          classroom_available_time_end:
                            moment(e).format("YYYY-MM-DD"),
                        })
                      }
                      // onChange={}
                      views={["year", "month", "day"]}
                      className="!text-[5rem]"
                      ampm={false}
                      slotProps={{
                        field: { shouldRespectLeadingZeros: true },
                        toolbar: { hidden: true },
                        tabs: { hidden: true },
                      }}
                      sx={{
                        "& .PrivatePickersClockNumber-clockNumber": {
                          fontSize: 50,
                        },
                      }}
                    />
                  )}
                />

                <FormHelperText>
                  {errors?.classroom_available_time_end?.message}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="mb-6">
              <label htmlFor="class" className="block mb-1">
                حضور و غیاب
              </label>
              <FormControl
                error={errors.classroom_presence ? true : false}
                fullWidth
              >
                <RadioGroup
                  value={call.classroom_presence}
                  {...register("classroom_presence", {
                    required: "حضور و غیاب را وارد کنید",
                  })}
                  id="class"
                  fullWidth
                  sx={{
                    flexDirection: "row",
                    "& .css-j204z7-MuiFormControlLabel-root": {
                      marginLeft: "0px",
                      marginRight: "0px",
                    },
                  }}
                  onChange={(e) => {
                    setCall({ ...call, classroom_presence: e.target.value });
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="داشته باشد"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="نداشته باشد"
                  />
                </RadioGroup>
                <FormHelperText>
                  {errors?.classroom_presence?.message}
                </FormHelperText>
              </FormControl>
            </div>

            <div className="mb-6">
              <label htmlFor="content1_url1" className="block mb-1">
                لینک ویدئوی اول
              </label>
              <TextField
                name="content1_url1"
                value={call.content1_url1}
                placeholder="عنوان"
                {...register("content1_url1", {
                  required: "این فیلد اجباری است",
                })}
                error={!!errors.content1_url1}
                helperText={
                  errors.content1_url1 ? errors.content1_url1.message : ""
                }
                onChange={(e) => {
                  setCall({ ...call, content1_url1: e.target.value });
                }}
                fullWidth
              />
            </div>
            <div className="mb-6">
              <label htmlFor="content2_url1" className="block mb-1">
                لینک ویدئوی دوم
              </label>
              <TextField
                name="content2_url1"
                value={call.content2_url1}
                placeholder="عنوان"
                {...register("content2_url1", {})}
                onChange={(e) => {
                  setCall({ ...call, content2_url1: e.target.value });
                }}
                fullWidth
              />
            </div>
            <div className="mb-6">
              <label htmlFor="content3_url1" className="block mb-1">
                لینک ویدئوی سوم
              </label>
              <TextField
                name="content3_url1"
                value={call.content3_url1}
                placeholder="عنوان"
                {...register("content3_url1", {})}
                onChange={(e) => {
                  setCall({ ...call, content3_url1: e.target.value });
                }}
                fullWidth
              />
            </div>
            <div className="mb-6">
              <label htmlFor="content4_url1" className="block mb-1">
                لینک ویدئوی چهارم
              </label>
              <TextField
                name="content4_url1"
                value={call.content4_url1}
                placeholder="عنوان"
                {...register("content4_url1", {
                  required: "این فیلد اجباری است",
                })}
                onChange={(e) => {
                  setCall({ ...call, content4_url1: e.target.value });
                }}
                fullWidth
              />
            </div>
            <div className="mb-6">
              <label htmlFor="content5_url1" className="block mb-1">
                لینک ویدئوی پنجم
              </label>
              <TextField
                name="content5_url1"
                value={call.content5_url1}
                placeholder="عنوان"
                {...register("content5_url1", {
                  required: "این فیلد اجباری است",
                })}
                onChange={(e) => {
                  setCall({ ...call, content5_url1: e.target.value });
                }}
                fullWidth
              />
            </div>
          </>
        )}
        {activeStep === 1 && (
          <>
            <div className="mb-6">
              <label htmlFor="classroom_groups" className="block mb-1">
                گروه
              </label>
              <FormControl
                error={errors.classroom_groups ? true : false}
                fullWidth
              >
                <FormGroup id="classroom_groups" fullWidth>
                  {groups?.results.map((group) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              call?.classroom_groups.indexOf(group.id) !== -1
                            }
                          />
                        }
                        label={group.name}
                        value={group.id}
                        onChange={handleCheckboxChange}
                      />
                    );
                  })}
                </FormGroup>
                <FormHelperText>
                  {errors?.classroom_groups?.message}
                </FormHelperText>
              </FormControl>
            </div>
          </>
        )}
        <div className="  w-fit ml-0 mr-auto">
          {isLastStep ? (
            <button
              disabled={
                call.ClassroomName &&
                call.classroom_headline &&
                call.classroom_available_time_end &&
                call.classroom_presence &&
                call.content1_url1
                  ? false
                  : true
              }
              type="submit"
              className="text-white !bg-[#8200d3] hover:bg-[#8200d3] transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 disabled:opacity-50"
            >
              ثبت
            </button>
          ) : (
            <a
              onClick={handleNext}
              className={`text-black border-2 border-[#8200d3] bg-white text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 ${
                call.ClassroomName &&
                call.classroom_headline &&
                call.classroom_available_time_end &&
                call.content1_url1
                  ? ""
                  : "pointer-events-none opacity-50 border-none"
              }`}
            >
              بعدی
            </a>
          )}
        </div>
      </form>
    </div>
  );
};

export default AddVideos;

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

const lessons = [
  "توابع و خواص آن ها",
  "معادله درجه دوم",
  "الگو و دنباله",
  "مثلثات",
  "توان های گویا و عبارات جبری",
  "مرور توابع و خواص آن ها",
  "آنالیز",
  "احتمالات",
  "هندسه مختصاتی",
  "معادلات و نامعادلات گویا و گنگ",
  "قدر مطلق",
  "براکت",
  "هندسه",
  "توابع نمایی و لگاریتم",
  "حد",
  "پیوستگی",
  "بخش پذیری",
  "مشتق",
  "کاربرد مشتق",
  "مجانب ها",
  "مقاطع مخروطی",
];
