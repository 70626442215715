import React, { useContext, useEffect, useState } from "react";
import { Card } from "../../../shares/card/Card";

const Rules = () => {
  const rules = [
    "در زمان ثبت نام، هزینه ثبت نام بدون احتساب هزینه جزوات دریافت می‌شود.",
    "پس از ثبت‌نام کلاس‌ها به هیچ عنوان امکان تغییر گروه و جابجایی بین گروه‏ها وجود نخواهد داشت. (فقط امکان انصراف وجود دارد)",
    "غیبت بیش از 5 جلسه درکلاس‌ها (مجاز یا غیرمجاز) به منزله انصراف است. ",
    "در صورتی که دانش‌آموز به مدت 5 هفته‌ی متوالی نمره‌ای زیر میانگین کلاس کسب کند، مدرس کلاس بنابر صلاح دید می‌تواند مانع از حضور دانش‌آموز در کلاس ‌شود.",
    "هر دانش‌آموز فقط و فقط در زمان مقرر گروه خود در کلاس و امتحان ‌می‌تواند شرکت کرده و جابجایی بین گروه‌ها امکان‌پذیر نیست (حتی برای یک جلسه) و غیبت در امتحانات تحت هر شرایطی نمره صفر را برای دانش‌آموز به همراه دارد و امتحانات نمره‌ی منفی دارند.",
    "فقط دانش‌آموز با میانگین درصد بالای 60% برای سال آینده ثبت‌نام خواهد شد.",
    "ارسال  تکالیف هفتگی بعد از زمان معین امکان‌پذیر نیست.",
    "در صورت عدم تحویل تکلیف برای دانش‌آموز درصد صفر لحاظ می‌شود. کسب 3 نمره صفر در تکالیف به منزله انصراف است.",
    "کلیه‌ی کلاس‌ها در روزهای تعطیل برگزار می‌شود.",
    "مدرس کلاس می‌تواند بنا به صلاح دید خود و شرایط کلاس‌ها را به صورت آنلاین یا حضوری برگزار کند.",
    "در این کلاس مطالب کتب مربوط به ریاضی  1 (دهم)، ریاضی 2، حسابان 1 (یازدهم)، ریاضی 3 و حسابان 2 (دوازدهم) پوشش داده می‌شود.",
    "درصورت غیبت در کلاس حضوری، امکان استفاده از فیلم‌های آموزشی وجود نخواهد داشت.",
    "در کلاس حضوری، استفاده از پوشش مناسب مدرسه برای حضور در کلاس‌ها الزامیست ؛ در غیر این صورت از حضور دانش‌آموز در کلاس ممانعت به عمل می‌آید.",
    "از حضور دانش‌آموز با تاخیر بیش از 10 دقیقه درکلاس جلوگیری می‌شود.",
    "در صورت ایجاد بی‌نظمی، دانش آموز از ادامه‌ی حضور در کلاس محروم  می‌شود.",
  ];

  return (
    <div className="min-h-screen flex items-center justify-center w-full">
      <Card className="h-full w-full">
        <div className="py-12 px-10">
          <h2 className="text-[2.4rem] font-bold text-right pb-7 border-b-2 border-gray-300">
            قوانین کلی کلاس‌ها:
          </h2>
          {/* <hr className="my-7" /> */}
          <ul className="list-disc list-outside text-justify pt-7">
            {rules.map((rule, index) => (
              <li
                key={index}
                className="mb-4 leading-normal text-gray-700 group hover:text-red-500 cursor-default"
              >
                {rule}
              </li>
            ))}
          </ul>
        </div>
      </Card>
    </div>
  );
};

export default Rules;
