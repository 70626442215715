import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import { BtnAlternative, BtnPrimary } from "../../../shares/button/Button";
import { Button } from "@mui/material";
// import Countdown from "react-countdown";
import moment from "jalali-moment";
import { Card } from "../../../shares/card/Card";
import { CleanHands, HourglassTop } from "@mui/icons-material";
import CountDown from "../../../../utils/CountDown";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import { Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import Loading from "../../../../utils/Loading";
import ReturnModal from "./ReturnModal";

const Exam = () => {
  const { Id } = useParams();
  const [questions, setQuestions] = useState();
  const [answer, setAnswer] = useState({
    aqn: "",
    answer_number: questions?.u_choice,
    c_id: "",
  });
  const [returnTo, setReturnTo] = useState("");
  const [call, setCall] = useState();
  const [call2, setCall2] = useState();
  const [call3, setCall3] = useState();
  const [selectedOption, setSelectedOption] = useState(
    questions?.u_choice ? questions?.u_choice : ""
  );
  const [examInfo, setExamInfo] = useState();
  const [examCurrent, setExamCurrent] = useState();
  const [studentPanel, setStudentPanel] = useState();
  const [returnModal, setReturnModal] = useState();

  const panel = useFetch({
    method: "GET",
    url: `student-panel/`,
    noHeader: false,
    trigger: true,
    setter: setStudentPanel,
  });
  const fetchExamInfo = useFetch({
    method: "GET",
    url: `exam-platform/`,
    noHeader: false,
    trigger: true,
    setter: setExamInfo,
  });
  // console.log(examInfo && examInfo[0].exam_duration , Math.floor(new Date(examInfo[0].exam_duration).getTime() / 1000))

  useEffect(() => {
    setExamCurrent(examInfo?.filter((exam) => exam.exam_permission === true));
  }, [examInfo]);

  const fetchQuestions = useFetch({
    method: "GET",
    url: `exam-platform/exam/${Id}/inchange/conf`,
    noHeader: false,
    trigger: true,
    setter: setQuestions,
    errMessage: () =>
      toast.error("مشکلی در دریافت اطلاعات پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
  });

  const nextQuestions = useFetch({
    method: "GET",
    url: `exam-platform/exam/${Id}/inchange/next`,
    noHeader: false,
    trigger: false,
    setter: setQuestions,
  });
  const finishExam = useFetch({
    method: "GET",
    url: `exam-platform/exam/${Id}/inchange/finish`,
    noHeader: false,
    trigger: false,
    // setter: setQuestions,
  });

  const setAnswerFunc = useFetch({
    method: "POST",
    url: `exam-platform/exam/${Id}/cal/set`,
    noHeader: false,
    trigger: false,
    data: call2,
  });
  const remAnswerFunc = useFetch({
    method: "POST",
    url: `exam-platform/exam/${Id}/cal/rem`,
    noHeader: false,
    trigger: false,
    data: call3,
  });

  const returnFunc = useFetch({
    method: "POST",
    url: `exam-platform/exam/${Id}/inchange/return`,
    noHeader: false,
    trigger: false,
    data: call,
    setter: setQuestions,
  });

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  // console.log(examInfo);

  const handleRadioClick = () => {
    setSelectedOption("");
    const formData3 = new FormData();

    formData3.append("aqn", questions?.active_question_number);
    setCall3(formData3);
    if (selectedOption !== "") {
      remAnswerFunc.reFetch();
    }
  };

  const handleNext = () => {
    // console.log("next");
    nextQuestions.reFetch();
    setAnswer({ aqn: "", c_id: "", answer_number: "" });
    setSelectedOption("");
  };
  const handleFinish = () => {
    finishExam.reFetch();

    setTimeout(() => {
      window.close();
    }, 5000);

    toast.info("امتحان پایان یافت. لطفا چند لحظه صبر کنید.", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      rtl: true,
    });
  };

  useEffect(() => {
    if (returnTo !== "") {
      const formData = new FormData();

      formData.append("aqn", returnTo);
      setCall(formData);
      setSelectedOption("");
      returnFunc.reFetch();
    }
  }, [returnTo]);

  useEffect(() => {
    if (returnFunc.status === 200) {
      setSelectedOption(questions.u_choice);
    }
  }, [questions?.returns]);

  useEffect(() => {
    if (selectedOption !== "") {
      if (selectedOption == 1) {
        setAnswer({
          aqn: questions.active_question_number,
          c_id: questions.c1_id,
          answer_number: selectedOption,
        });
      } else if (selectedOption == 2) {
        setAnswer({
          aqn: questions.active_question_number,
          c_id: questions.c2_id,
          answer_number: selectedOption,
        });
      } else if (selectedOption == 3) {
        setAnswer({
          aqn: questions.active_question_number,
          c_id: questions.c3_id,
          answer_number: selectedOption,
        });
      } else if (selectedOption == 4) {
        setAnswer({
          aqn: questions.active_question_number,
          c_id: questions.c4_id,
          answer_number: selectedOption,
        });
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (answer.c_id !== "") {
      const formData2 = new FormData();

      formData2.append("aqn", answer.aqn);
      formData2.append("c_id", answer.c_id);
      formData2.append("answer_number", answer.answer_number);

      setCall2(formData2);
      setAnswerFunc.reFetch();
    }
  }, [answer]);
  const [examTime, setExamTime] = useState();
  const [examTime2, setExamTime2] = useState();
  const [choice_list, setChoice_list] = useState();

  useEffect(() => {
    setExamTime(Date.parse(questions?.q_time));
  }, [questions]);
  useEffect(() => {
    setExamTime2(Date.parse(questions?.e_time));
  }, [examInfo]);

  useEffect(() => {
    questions?.u_choices_list && setChoice_list(questions?.u_choices_list);
    returnFunc.status === 200 && handleClose();
  }, [questions]);

  const handleClose = () => {
    setReturnModal(false);
  };

  return (
    <>
      {nextQuestions.loading ? (
        <Loading />
      ) : (
        <div className="dashboard">
          <div className=" relative  py-12 text-black flex items-center justify-around w-full h-fit bg-white shadow-lg overflow-auto">
            <div className="flex items-center "></div>
            <div className="flex items-center gap-5">
              <h5 className=" max-md:text-[10px] text-2xl flex flex-wrap w-full whitespace-nowrap ">{`${
                examCurrent && examCurrent[0]?.exam_permission === true
                  ? "در حال برگزاری"
                  : ""
              } -`}</h5>
              {examTime ? (
                <h5 className=" max-md:text-[10px] text-2xl flex flex-wrap w-full">
                  <CountDown
                    minutes={0}
                    // seconds={Math.floor(examTime / 1000)}
                    date={examTime}
                    onComplete={
                      questions?.u_q_list.length ===
                      questions?.active_question_number
                        ? handleFinish
                        : handleNext
                    }
                    // onComplete={()=>handleNext}
                  />
                </h5>
              ) : (
                0
              )}
            </div>
            <Button
              size="medium"
              variant="contained"
              color="warning"
              onClick={handleFinish}
            >
              پایان آزمون
            </Button>
          </div>
          {/* <Card className="p-10 max-md:p-5 max-md:text-[10px] text-2xl flex flex-wrap fixed max-md:top-[12rem] max-md:right-[27rem] top-[11.5rem] max-md:hidden right-[135.7rem] ">
            <Countdown date={Date.now() + examTime ? examTime : 0} onComplete={()=>console.log("kir")} daysInHours></Countdown>
            <HourglassTop />
            {examTime ? (
              <CountDown
                minutes={0}
                date={examTime}
                onComplete={
                  questions?.u_q_list.length ===
                  questions?.active_question_number
                    ? handleFinish
                    : handleNext
                }
              />
            ) : (
              0
            )}
          </Card> */}

          <div className="">
            <div className=" py-6 container justify-between flex items-center">
              <div className="">
                <p className="text-[1.8rem] font-extrabold">
                  سوال {questions?.active_question_number}:<br />
                </p>
                <span className="text-[1.6rem]">{questions?.q_text}</span>
                <br />
                <img
                  src={`https://lab.mindescape.co/${questions?.q_img}`}
                  className="mt-4 object-fill"
                />
              </div>
              <div></div>
            </div>
            <div className="py-6 container">
              <span className="text-[1.2rem] font-light">
                پاسخ صحیح را انتخاب کنید.
              </span>
              <form
                onSubmit={handleNext}
                className="gap-8 grid grid-cols-2 sm:grid-cols-4"
              >
                <label className="flex shadow-card bg-[#ffffff78] flex-row justify-between gap-8 p-6 items-center text-right">
                  <div className="sm:self-center  flex gap-x-4">
                    <span className=" self-center text-[1.8rem] font-normal">
                      گزینه اول
                    </span>
                  </div>
                  <input
                    type="radio"
                    name="answer"
                    value="1"
                    onChange={handleChange}
                    className="w-8"
                    checked={selectedOption == "1"}
                    onClick={handleRadioClick}
                    // defaultChecked={questions?.u_choice == "1"}
                  />
                </label>

                <label className="flex shadow-card bg-[#ffffff78] flex-row justify-between gap-8 p-6 items-center text-right">
                  <div className="sm:self-center  flex gap-x-4">
                    <span className="text-[1.8rem] font-normal self-center ">
                      گزینه دوم
                    </span>
                  </div>
                  <input
                    type="radio"
                    name="answer"
                    value="2"
                    onChange={handleChange}
                    className="w-8"
                    checked={selectedOption == "2"}
                    onClick={handleRadioClick}
                    // defaultChecked={questions?.u_choice == "2"}
                  />
                </label>

                <label className=" flex shadow-card bg-[#ffffff78] flex-row justify-between gap-8 p-6  items-center text-right">
                  <div className="sm:self-center  flex gap-x-4">
                    <span className="text-[1.8rem] font-normal self-center ">
                      گزینه سوم
                    </span>
                  </div>
                  <input
                    type="radio"
                    name="answer"
                    value="3"
                    onChange={handleChange}
                    className="w-8"
                    checked={selectedOption == "3"}
                    onClick={handleRadioClick}
                    // defaultChecked={questions?.u_choice == "3"}
                  />
                </label>

                <label className="flex shadow-card bg-[#ffffff78] flex-row justify-between gap-8 p-6  items-center text-right">
                  <div className="sm:self-center  flex gap-x-4">
                    <span className="text-[1.8rem] font-normal self-center ">
                      گزینه چهارم
                    </span>
                  </div>
                  <input
                    type="radio"
                    name="answer"
                    value="4"
                    onChange={handleChange}
                    className="w-8"
                    checked={selectedOption == "4"}
                    onClick={handleRadioClick}
                    // defaultChecked={questions?.u_choice == "4"}
                  />
                </label>

                {/* <div className="flex text-[1.2rem] gap-12 sm:items-stretch justify-between w-full flex-col sm:flex-row items-center"> */}
                <div className="w-full text-center col-span-2">
                  <BtnAlternative
                    type=""
                    text="لیست سوالات"
                    className="py-4 px-12 items-center flex text-ellipsis self-stretch justify-center sm:self-end border border-blue"
                    onClick={() => setReturnModal(true)}
                    isDisabled={false}
                  />
                  <p className="opacity-70 pt-5 text-[1.4rem]">
                    تعداد بازگشت باقی مانده: {questions?.returns}
                  </p>
                </div>
                <div className="w-full text-center col-span-2">
                  <BtnPrimary
                    type="submit"
                    text={
                      questions?.u_q_list.length ===
                      questions?.active_question_number
                        ? `پایان آزمون`
                        : "سوال بعدی"
                    }
                    className="py-4 px-12 items-center flex text-ellipsis self-stretch justify-center sm:self-end"
                    onClick={
                      questions?.u_q_list.length ===
                      questions?.active_question_number
                        ? handleFinish
                        : handleNext
                    }
                  />
                  <p className="opacity-70 pt-5 text-lg">
                    زمان امتحان :
                    <CountDown
                      minutes={0}
                      date={examTime2}
                      onComplete={
                        questions?.u_q_list.length ===
                        questions?.active_question_number
                          ? handleFinish
                          : handleNext
                      }
                    />
                  </p>

                  {/* </div> */}
                  {/* <div className="text-[1.4rem] flex flex-col gap-y-2">
                <span>تعداد بازگشت باقی مانده: {questions?.returns} </span>

                <div>
                  <span>بازگشت به سوال: </span>
                  <select
                    placeholder="سوال..."
                    name="return"
                    className="border-2 border-[#C7D9FF] bg-transparent px-6 py-4 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
                    onChange={(e) => setReturnTo(e.target.value)}
                    disabled={questions?.returns === 0 && true}
                  >
                    <option value="تجربی" disabled selected>
                      سوال...
                    </option>
                    {questions?.u_q_list.map((q, index) => (
                      <option value={index + 1}>سوال {index + 1}</option>
                    ))}
                  </select>
                </div>
              </div>
              <BtnPrimary
                type="submit"
                text="سوال بعدی"
                className="py-4 px-12 items-center flex text-ellipsis self-stretch justify-center sm:self-end"
                onClick={handleNext}
              /> */}
                </div>
              </form>
            </div>
          </div>
          <Modal
            open={returnModal}
            onClose={handleClose}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <div className="flex justify-center items-center h-full w-full">
              <div className="bg-white w-1/3 py-20 px-8 flex justify-between items-center relative">
                <div className="cursor-pointer absolute top-4 right-4">
                  <Close onClick={handleClose} sx={{ fontSize: 18 }} />
                </div>
                <span className="text-center text-xl">بازگشت به سوال: </span>
                <select
                  placeholder="سوال..."
                  name="return"
                  className="border-2 border-[#C7D9FF] bg-transparent px-6 py-4 font-light text-[1.6rem] placeholder:text-[#c7d9ff]"
                  onChange={(e) => setReturnTo(e.target.value)}
                >
                  <option value="تجربی" disabled selected>
                    سوال...
                  </option>
                  {questions?.u_q_list.map((q, index) => (
                    <option value={index + 1}>سوال {index + 1}</option>
                  ))}
                </select>
              </div>
            </div> */}
            <ReturnModal
              choices={choice_list}
              setReturnTo={setReturnTo}
              aqn={questions?.active_question_number}
              close={handleClose}
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default Exam;
