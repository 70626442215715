import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { CloseSharp } from "@mui/icons-material";
import { BtnPrimary } from "../../../../shares/button/Button";
import useFetch from "../../../../../hooks/useFetch";
import moment from "jalali-moment";
import { toast } from "react-toastify";

const Password = ({ close, get, edit, Id }) => {
  const [call, setCall] = useState({
    username: Id,
    new_password: "",
  });

  const newGroup = useFetch({
    method: "POST",
    url: `management/change-password/`,
    noHeader: false,
    trigger: false,
    data: call,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: call,
  });

  const onSubmit = (e) => {
    // e.preventDefault();

    newGroup.reFetch();
  };
  useEffect(() => {
    newGroup.status === 200 && close();
    newGroup.status === 200 &&
      toast.success("رمز عبور تغییر یافت.", {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        rtl: true,
      });
  }, [newGroup.status]);

  return (
    <div className="relative rounded-2xl w-2/5 bg-white">
      <CloseSharp
        className="absolute top-2 right-[6px] cursor-pointer"
        onClick={close}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pt-16 pb-10 px-14 text-xl overflow-scroll max-h-[85vh]"
      >
        <h6 className="font-extrabold text-2xl border-b-2 w-full pb-4 mb-6">
          تغییر رمز عبور
        </h6>
        <div className="mb-6">
          <label htmlFor="new_password" className="block mb-1">
            رمز جدید
          </label>
          <TextField
            name="new_password"
            type={"number"}
            value={call.new_password}
            placeholder="رمز"
            {...register("new_password", {
              required: "رمز را وارد کنید",
              minLength: {
                value: 4,
                message: "password should be at least 4 characters long",
              },
            })}
            error={!!errors.new_password}
            helperText={errors.new_password ? errors.new_password.message : ""}
            onChange={(e) => {
              setCall({ ...call, new_password: e.target.value });
            }}
            fullWidth
          />
        </div>

        <div className="mr-auto ml-0 w-fit">
          <button
            type="submit"
            className="text-white !bg-[#8200d3] hover:bg-[#8200d3] transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 "
          >
            ثبت
          </button>
        </div>
      </form>
    </div>
  );
};

export default Password;

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
