import React, { createContext, useContext } from "react";
import { useState } from "react";

const SessionContext = createContext();
const SessionProvider = (props) => {
  const [contentId, setContentId] = useState(1);
  const [videoSelect, setVideoSelect] = useState();
  const [classesList, setClassesList] = useState();
  const [sideBar, setsideBar] = useState(false);
  const [contentInfo, setContentInfo] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [sessionPervPrecense, setSessionPervPrecense] = useState();
  const [isReady, setIsReady] = useState(false);

  const sessionNum = [1, 2, 3, 4, 5];

  return (
    <SessionContext.Provider
      value={{
        sessionPervPrecense,
        setSessionPervPrecense,
        sessionList,
        setSessionList,
        sideBar,
        setsideBar,
        videoSelect,
        setVideoSelect,
        contentId,
        setContentId,
        classesList,
        setClassesList,
        contentInfo,
        setContentInfo,
        isReady, setIsReady
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};
const useSession = () => useContext(SessionContext);
export { useSession, SessionProvider };
