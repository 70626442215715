import React, { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import { BtnAlternative, BtnPrimary } from "../../../shares/button/Button";
import { Card } from "../../../shares/card/Card";

import CurrentHW from "./cards/CurrentHW";
import PreviousHW from "./cards/PreviousHW";
import { toast } from "react-toastify";
import Loading from "../../../../utils/Loading";

function Homeworks() {
  const [assignmentInfo, setAssignmentInfo] = useState();

  const examsFetchData = useFetch({
    method: "GET",
    url: `assignment-platform/`,
    noHeader: false,
    trigger: true,
    setter: setAssignmentInfo,
    errMessage: () =>
      toast.error("مشکلی در دریافت اطلاعات تکالیف پیش آمده", {
        position: "bottom-left",
        rtl: true,
        autoClose: 2000,
      }),
  });

  return (
    <div className="HW">
      <header className="py-[3.4rem] text-center">
        <h1 className="text-[2.4rem] font-bold ">تکالیف</h1>
        {/* <p className="text-[1.4rem] pt-[1.9rem] opacity-50">
          درصورت اعتراض به نمره تصحیح شده با پشتیبانی تماس بگیرید.(یه نکته بهتر
          میخوایم)
        </p> */}
      </header>
      {examsFetchData.loading ? (
        <Loading />
      ) : (
        <>
          <div className="current-HW">
            <CurrentHW
              assignmentInfo={assignmentInfo}
              examsFetchData={examsFetchData.reFetch}
            />
          </div>
          <div className="previous-HW mt-[4rem] h-[63vh] overflow-y-scroll ">
            <h3 className="pb-[2rem]">تکالیف قبلی</h3>
            <PreviousHW assignmentInfo={assignmentInfo} />
          </div>
        </>
      )}
    </div>
  );
}

export default Homeworks;
