import React, { useState, useEffect } from "react";
import { Card } from "../../../shares/card/Card";
import ShareTable from "../../../shares/tables/ShareTable";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { BtnPrimary } from "../../../shares/button/Button";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Modal,
} from "@mui/material";
import AddBooks from "./modals/AddBooks";
import useFetch from "../../../../hooks/useFetch";
import Search from "../../../shares/search/Search";

const Books = () => {
  const [filterChoice, setFilterChoice] = useState("همه");
  const [classFilter, setClassFilter] = useState("");
  const [newModal, setNewModal] = useState(false);
  const [edit, setEdit] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [books, setBooks] = useState();
  const [newBooks, setNewBooks] = useState();
  const [id, setId] = useState();

  const handleClose = () => {
    setNewModal(false);
    setEdit(null);
    setDeleteModal(false);
  };

  const handleEdit = (id) => {
    setNewModal(true);
    setEdit(id);
  };

  const getBooks = useFetch({
    method: "GET",
    url: `management/books/`,
    noHeader: false,
    trigger: true,
    setter: setBooks,
  });

  const deleteBooks = useFetch({
    method: "DELETE",
    url: `management/books/${id}/`,
    noHeader: false,
    trigger: false,
    caller: getBooks,
  });

  useEffect(() => {
    setNewBooks(
      books?.results.map((book) => {
        const newBook = {
          ...book,
          // name: `${group.name} - ${group.group_gender}`,

          edit: (
            <EditIcon
              onClick={() => handleEdit(book.id)}
              className="cursor-pointer"
            />
          ),
          delete: (
            <DeleteIcon
              onClick={() => setId(book.id)}
              className="cursor-pointer"
            />
          ),
        };

        return newBook;
      })
    );
  }, [books]);

  useEffect(() => {
    deleteBooks.reFetch();
  }, [id]);

  return (
    <div className="flex mt-20">
      <Card className="p-16 w-full text-right">
        <div className="flex justify-between items-center border-b-2 border-gray-200 pb-8 mb-12">
          <h3 className="text-3xl">جزوات</h3>
          <BtnPrimary
            text="+ افزودن جزوه جدید"
            className="px-6 py-4 !bg-[#8200d3] !hover:bg-[#8200d3]"
            onClick={() => {
              setNewModal(true);
            }}
          />
        </div>
        <div className="flex items-center mb-6">
          <Search newData={setBooks} url="books" />
        </div>

        <ShareTable
          data={newBooks ? newBooks : []}
          columns={columns}
          next={
            books?.next
              ? books?.next?.replace(/https:\/\/lab\.mindescape\.co\//, "")
              : null
          }
          perv={
            books?.previous
              ? books?.previous?.replace(/https:\/\/lab\.mindescape\.co\//, "")
              : null
          }
          newData={setBooks}
          getLoad={getBooks.loading}
        />
      </Card>
      <Modal
        open={newModal}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AddBooks close={handleClose} get={getBooks} edit={edit} />
      </Modal>
    </div>
  );
};

export default Books;

const data = [
  {
    id: 1,
    name: "امیر",
    age: 25,
    start: "27 بهمن 1400",
    cap: 32,
    class: "یازدهم",
    exam: <DownloadIcon onClick={() => console.log("hi")} />,
    homework: <DownloadIcon />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  {
    id: 2,
    name: "امیر2",
    age: 30,
    start: "27 بهمن 1400",
    cap: 32,
    class: "یازدهم",
    exam: <DownloadIcon onClick={() => console.log("hi")} />,
    homework: <DownloadIcon />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  },
  // Add more data objects as needed
];

const columns = [
  { id: "booksname", label: "نام" },
  { id: "edit", label: "ویرایش" },
  { id: "delete", label: "حذف" },
  // Add more columns as needed
];
