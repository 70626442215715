import React, { useState, useEffect, useRef } from "react";
import { Card } from "../../../shares/card/Card";
import ShareTable from "../../../shares/tables/ShareTable";
import Search from "../../../shares/search/Search";
import { AccountCircleTwoTone } from "@mui/icons-material";
import { BtnPrimary } from "../../../shares/button/Button";
import { Modal, Chip } from "@mui/material";
// import AddGroups from "./modals/AddQuestion";
import useFetch from "../../../../hooks/useFetch";
import moment from "jalali-moment";
import { Link } from "react-router-dom";
import AddVideos from "./modals/AddVideos";

const Students = () => {
  const [classFilter, setClassFilter] = useState("");
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [questions, setQuestion] = useState();
  const [imported, setImported] = useState();
  const [call, setCall] = useState();
  const [newQuestion, setNewQuestion] = useState();
  const [id, setId] = useState();

  const handleClose = () => {
    setNewModal(false);
    setEditModal(false);
    setDeleteModal(false);
  };

  const getQuestion = useFetch({
    method: "GET",
    url: `management/users/`,
    noHeader: false,
    trigger: true,
    setter: setQuestion,
  });

  const importReq = useFetch({
    method: "POST",
    url: `management/upload-excel/`,
    noHeader: false,
    trigger: false,
    data: imported,
  });

  useEffect(() => {
    setNewQuestion(
      questions?.results.map((question) => {
        const newQuestion = {
          ...question,
          groups: <Chip label={question.groups[0]?.name} />,
          student_school: question.studentuser?.student_school,
          profile: (
            <Link to={`${question.id}`}>
              <AccountCircleTwoTone className="cursor-pointer" />
            </Link>
          ),
        };

        return newQuestion;
      })
    );
  }, [questions]);

  const [searchFirstName, setSearchFirstName] = useState("");
  const [searchLastName, setSearchLastName] = useState("");

  const handleChangeFirstName = (event) => {
    setSearchFirstName(event.target.value);
  };

  const handleChangeLastName = (event) => {
    setSearchLastName(event.target.value);
  };

  // Filter the array based on search criteria
  const filteredArray = newQuestion?.filter((item) => {
    const firstNameMatch = item.first_name
      .toLowerCase()
      .includes(searchFirstName.toLowerCase());
    const lastNameMatch = item.last_name
      .toLowerCase()
      .includes(searchLastName.toLowerCase());

    return firstNameMatch && lastNameMatch;
  });

  const questionRef = useRef(null);

  const handleClickQuestion = () => {
    questionRef.current.click();
  };

  useEffect(() => {
    // const formData = new FormData();

    // formData.append("file", imported);
    // setCall(formData);

    imported && importReq.reFetch();
  }, [imported]);

  return (
    <div className="flex mt-20">
      <Card className="p-16 w-full text-right">
        <div className="flex justify-between items-center border-b-2 border-gray-200 pb-8 mb-12">
          <h3 className="text-3xl">دانش آموزان</h3>
          <div>
            <a
              className="text-black border-2 border-[#8200d3] hover:bg-[#00000009] bg-white text-[1.4rem] rounded-[1.2rem] w-full px-6 py-4 ml-4"
              onClick={handleClickQuestion}
            >
              بارگزاری کاربران
            </a>
            <input
              type="file"
              ref={questionRef}
              onChange={(e) => setImported(e.target.files[0])}
              required
              style={{ display: "none" }}
            />
            <BtnPrimary
              text="+ افزودن دانش آموز جدید"
              className="px-6 py-4 !bg-[#8200d3] hover:bg-[#8200d3]"
              onClick={() => {
                setNewModal(true);
              }}
            />
          </div>
        </div>
        <div className="flex items-center mb-6">
          <Search newData={setQuestion} url="users" />
        </div>

        <ShareTable
          data={newQuestion ? newQuestion : []}
          columns={columns}
          next={
            questions?.next
              ? questions?.next?.replace(/https:\/\/lab\.mindescape\.co\//, "")
              : null
          }
          perv={
            questions?.previous
              ? questions?.previous?.replace(
                  /https:\/\/lab\.mindescape\.co\//,
                  ""
                )
              : null
          }
          newData={setQuestion}
          getLoad={getQuestion.loading}
        />
      </Card>
      <Modal
        open={newModal}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AddVideos close={handleClose} get={getQuestion} />
      </Modal>
    </div>
  );
};

export default Students;

const columns = [
  { id: "username", label: "کدملی" },
  { id: "first_name", label: "نام" },
  { id: "last_name", label: "نام خانوادگی" },
  { id: "student_school", label: "مدرسه" },
  { id: "groups", label: "گروه" },
  { id: "profile", label: "پروفایل" },
  //   Add more columns as needed
];

const lessons = [
  "توابع و خواص آن ها",
  "معادله درجه دوم",
  "الگو و دنباله",
  "مثلثات",
  "توان های گویا و عبارات جبری",
  "مرور توابع و خواص آن ها",
  "آنالیز",
  "احتمالات",
  "هندسه مختصاتی",
  "معادلات و نامعادلات گویا و گنگ",
  "قدر مطلق",
  "براکت",
  "هندسه",
  "توابع نمایی و لگاریتم",
  "حد",
  "پیوستگی",
  "بخش پذیری",
  "مشتق",
  "کاربرد مشتق",
  "مجانب ها",
  "مقاطع مخروطی",
];
