import React from "react";

function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function NumberFormat({ number }) {
  const formattedNumber = formatNumberWithCommas(number);

  return <p>{formattedNumber}</p>;
}

export default NumberFormat;
