import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CloseSharp } from "@mui/icons-material";
import useFetch from "../../../../../hooks/useFetch";
import { Input, FormControl, FormHelperText } from "@mui/material";

const AddQuestion = ({ close, get, edit }) => {
  const [call, setCall] = useState({
    question_headline: "",
    question_category: "",
    question_answer: "",
    question_grade: "",
    question_book: "",
    question_time: "120",
    question_img: "",
    question_answer_img: "",
  });

  const newQuestion = useFetch({
    method: "POST",
    url: `management/questions/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });

  const editQuestion = useFetch({
    method: "PATCH",
    url: `management/questions/${edit}/`,
    noHeader: false,
    trigger: false,
    data: call,
    caller: get,
  });

  const getQuestion = useFetch({
    method: "GET",
    url: `management/questions/${edit}/`,
    noHeader: false,
    trigger: edit ? true : false,
    setter: setCall,
  });

  const questionRef = useRef(null);
  const answerRef = useRef(null);

  const handleClickQuestion = () => {
    questionRef.current.click();
  };
  const handleClickAnswer = () => {
    answerRef.current.click();
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: call,
  });

  const onSubmit = (e) => {
    const formData = new FormData();
    if (typeof call.question_img == "object") {
      formData.append("question_img", call.question_img);
    }
    formData.append("question_headline", call.question_headline);
    formData.append("question_category", call.question_category);
    formData.append("question_book", call.question_book);
    formData.append("question_grade", call.question_grade);
    formData.append("question_answer", call.question_answer);
    if (typeof call.question_answer_img == "object") {
      formData.append("question_answer_img", call.question_answer_img);
    }
    formData.append("question_time", call.question_time);

    setCall(formData);
    if (edit) {
      editQuestion.reFetch();
    } else {
      newQuestion.reFetch();
    }
  };
  useEffect(() => {
    newQuestion.status === 201 && close();
    editQuestion.status === 200 && close();
  }, [newQuestion.status || editQuestion]);

  return (
    <div className="relative rounded-2xl w-2/5 bg-white">
      <CloseSharp
        className="absolute top-2 right-[6px] cursor-pointer"
        onClick={close}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="pt-16 pb-10 px-14 text-xl overflow-scroll max-h-[85vh]"
      >
        <h6 className="font-extrabold text-2xl border-b-2 w-full pb-4 mb-6">
          {edit ? "ویرایش سوال" : "سوال جدید"}
        </h6>
        <div className="flex gap-x-6 w-full">
          <div className="flex items-end justify-center w-full">
            <a
              className={`text-black border-2 border-[#8200d3] hover:bg-[#00000009] bg-white text-[1.4rem] rounded-[1.2rem] w-full py-[16.5px] px-8 ${
                call.question_img && " !bg-[#8200d3] !text-white"
              }`}
              onClick={handleClickQuestion}
            >
              {call.question_img ? "بارگزاری شده" : "بارگزاری سوال"}
            </a>
            <input
              type="file"
              ref={questionRef}
              onChange={(e) =>
                setCall({ ...call, question_img: e.target.files[0] })
              }
              required={edit ? false : true}
              style={{ display: "none" }}
            />
          </div>
          <div className="flex items-end justify-center w-full">
            <a
              className={`text-black border-2 border-[#8200d3] hover:bg-[#00000009] bg-white text-[1.4rem] rounded-[1.2rem] w-full py-[16.5px] px-8 ${
                call.question_answer_img && " !bg-[#8200d3] !text-white"
              }`}
              onClick={handleClickAnswer}
            >
              {call.question_answer_img ? "بارگزاری شده" : "بارگزاری پاسخ"}
            </a>
            <input
              type="file"
              ref={answerRef}
              onChange={(e) =>
                setCall({ ...call, question_answer_img: e.target.files[0] })
              }
              required={edit ? false : true}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div className="mb-6">
          <label htmlFor="question_headline" className="block mt-6 mb-1">
            عنوان
          </label>
          <TextField
            name="question_headline"
            value={call.question_headline}
            placeholder="عنوان"
            {...register("question_headline", {
              required: "عنوان را وارد کنید",
            })}
            error={!!errors.question_headline}
            helperText={
              errors.question_headline ? errors.question_headline.message : ""
            }
            onChange={(e) => {
              setCall({ ...call, question_headline: e.target.value });
            }}
            fullWidth
          />
        </div>

        <div className="mb-6">
          <label htmlFor="question_category" className="block mb-1">
            مبحث
          </label>
          <FormControl
            error={errors.question_category ? true : false}
            fullWidth
          >
            <Select
              value={call.question_category}
              {...register("question_category", {
                required: "مبحث را وارد کنید",
              })}
              fullWidth
              onChange={(e) => {
                setCall({ ...call, question_category: e.target.value });
              }}
            >
              {lessons.map((lesson) => {
                return <MenuItem value={lesson}>{lesson}</MenuItem>;
              })}
            </Select>
            <FormHelperText>
              {errors?.question_category?.message}
            </FormHelperText>
          </FormControl>
        </div>
        <div className="mb-6">
          <label htmlFor="question_grade" className="block mb-1">
            مقطع
          </label>
          <FormControl error={errors.question_grade ? true : false} fullWidth>
            <Select
              value={call.question_grade}
              {...register("question_grade", {
                required: "مقطع را وارد کنید",
              })}
              fullWidth
              onChange={(e) => {
                setCall({ ...call, question_grade: e.target.value });
              }}
            >
              {grade.map((grade) => {
                return <MenuItem value={grade}>{grade}</MenuItem>;
              })}
            </Select>
            <FormHelperText>{errors?.question_grade?.message}</FormHelperText>
          </FormControl>
        </div>

        <div className="mb-6">
          <label htmlFor="question_book" className="block mb-1">
            کتاب
          </label>
          <TextField
            name="question_book"
            value={call.question_book}
            placeholder="کتاب"
            {...register("question_book", {
              required: "کتاب را وارد کنید",
            })}
            error={!!errors.question_book}
            helperText={
              errors.question_book ? errors.question_book.message : ""
            }
            onChange={(e) => {
              setCall({ ...call, question_book: e.target.value });
            }}
            fullWidth
          />
        </div>

        <div className="mb-6">
          <label htmlFor="question_answer" className="block mb-1">
            پاسخ صحیح
          </label>
          <FormControl error={errors.question_answer ? true : false} fullWidth>
            <Select
              value={call.question_answer}
              {...register("question_answer", {
                required: "پاسخ را وارد کنید",
              })}
              fullWidth
              onChange={(e) => {
                setCall({ ...call, question_answer: e.target.value });
              }}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
            </Select>
            <FormHelperText>{errors?.question_answer?.message}</FormHelperText>
          </FormControl>
        </div>
        <div className="mb-6">
          <label htmlFor="question_time" className="block mb-1">
            زمان سوال
          </label>
          <TextField
            type={"number"}
            name="question_time"
            value={call.question_time}
            placeholder="زمان سوال"
            {...register("question_time", {
              required: "زمان سوال را وارد کنید",
            })}
            error={!!errors.question_time}
            helperText={
              errors.question_time ? errors.question_time.message : ""
            }
            onChange={(e) => {
              setCall({ ...call, question_time: e.target.value });
            }}
            fullWidth
          />
        </div>
        <div className="mr-auto ml-0 w-fit">
          <button
            type="submit"
            className="text-white !bg-[#8200d3] hover:bg-[#8200d3] transition-all rounded-16 active:bg-blueActive focus:outline-none font-normal text-[1.4rem] rounded-[1.2rem] w-fit py-4 px-8 "
          >
            ثبت سوال
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddQuestion;

const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

const lessons = [
  "توابع و خواص آن ها",
  "معادله درجه دوم",
  "الگو و دنباله",
  "مثلثات",
  "توان های گویا و عبارات جبری",
  "مرور توابع و خواص آن ها",
  "آنالیز",
  "احتمالات",
  "هندسه مختصاتی",
  "معادلات و نامعادلات گویا و گنگ",
  "قدر مطلق",
  "براکت",
  "هندسه",
  "توابع نمایی و لگاریتم",
  "حد",
  "پیوستگی",
  "بخش پذیری",
  "مشتق",
  "کاربرد مشتق",
  "مجانب ها",
  "مقاطع مخروطی",
];
const grade = ["دهم", "یازدهم", "دوازدهم"];
