import React, { useState } from "react";
import { BtnAlternative } from "../../../../shares/button/Button";
import doc from "../../../../../assets/img/icons/Iconly-Light-Paper.svg";
import docDownload from "../../../../../assets/img/icons/docs-download.svg";
import { Card } from "../../../../shares/card/Card";
import useFetch from "../../../../../hooks/useFetch";
import moment from "jalali-moment";
import { timeDifference } from "../../../../../utils/timeDifference";
import { Modal } from "@mui/material";
import ScoreModal from "../modal/ScoreModal";

const PreviousHW = ({ assignmentInfo }) => {
  const [scoreModal, setScoreModal] = useState();
  const [score, setScore] = useState();

  const handleClose = () => {
    setScoreModal(false);
  };
  const handleModal = (data) => {
    setScoreModal(true);
    setScore(data);
  };

  return (
    <div className="">
      {assignmentInfo?.map((a, index) =>
        timeDifference(a.assignment_available_time_end, Date.now()) < 0 ? (
          <Card className="grid grid-row-2 sm:grid-rows-1 grid-cols-10 mb-[1.6rem]">
            <div className=" self-center text-[2.4rem] row-span-1 sm:row-span-2 col-span-10 sm:col-span-1 sm:border-l-2 h-full flex items-center justify-center py-12 sm:py-0 border-b-2 sm:border-b-0">
              <span
                className={`${a?.assignment_student_file && "cursor-pointer"}`}
                onClick={() =>
                  a?.assignment_student_file &&
                  (window.location.href = `https://lab.mindescape.co/${a.assignment_student_file}`)
                }
              >
                {index + 1}
              </span>
            </div>
            <div className="col-span-9">
              <div className="grid grid-cols-8 grid-rows-2 sm:flex flex-row justify-between items-center pt-9 pb-8 pl-12 pr-12">
                <div className="col-span-8 flex flex-row items-center">
                  <div className="text-start grow-[15]">
                    <h4 className="text-[1.6rem] flex">
                      {a.AssignmentName}
                      <span className=" text-[1.2rem] px-[1.5rem] opacity-50">
                        {moment(a.assignment_available_time_end, "YYYY/MM/DD")
                          .locale("fa")
                          .format("DD MMMM YYYY")}
                      </span>
                    </h4>
                    <p className="text-[1.4rem] pt-[.6rem] opacity-50 ">
                      {a.assignment_headline}
                    </p>
                  </div>
                </div>
                <div className="col-span-8 text-[1.4rem] flex flex-row flex-wrap sm:flex-nowrap gap-y-4 justify-between items-center ">
                  <BtnAlternative
                    className="py-[1.2rem] px-[2.2rem] border-[#EAEBED] border-2 ml-[1.55rem]"
                    text={"تکلیف"}
                    icon={doc}
                    url={`https://lab.mindescape.co/${a.assignment_file}`}
                    download
                  />
                  <BtnAlternative
                    className="py-[1.2rem] px-[2.2rem] border-[#EAEBED] text-blue border-2  ml-[1.55rem]"
                    text={"ریز نمرات"}
                    onClick={() => handleModal(a.score)}
                  />
                  <BtnAlternative
                    className={`py-[1.2rem] px-[2.2rem] border-[#EAEBED] border-2  ml-[2.93rem] ${
                      a.score.assignment_teacher_file
                        ? "text-blue"
                        : "text-gray-400 pointer-events-none"
                    }`}
                    text={"برگه تصحیح شده"}
                    icon={docDownload}
                    url={`https://lab.mindescape.co/${a.score.assignment_teacher_file}`}
                  />
                  <div className="inline-flex items-center">
                    <span className="text-[1.4rem] opacity-50 pl-[1rem]">
                      نمره
                    </span>
                    <p className="text-[2.4rem] opacity-100 text-blue">
                      {a.score.score}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        ) : (
          ""
        )
      )}
      <Modal
        open={scoreModal}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ScoreModal close={handleClose} scores={score} />
      </Modal>
    </div>
  );
};

export default PreviousHW;

const exams = [];
