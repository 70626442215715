import React from "react";
import { BtnAlternative } from "../../../shares/button/Button";
import Class from "../../../../assets/img/icons/class.svg";
import Exam from "../../../../assets/img/icons/exam.svg";
import Problem from "../../../../assets/img/icons/problem-solving.svg";
import Homework from "../../../../assets/img/icons/homework.svg";
import { Card } from "../../../shares/card/Card";

const Features = () => {
  return (
    <div className=" text-white pt-32 pb-10 flex container items-center justify-around gap-28 mb-48 flex-col md:flex-row">
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center md:items-start">
        <h3 className="text-[3.8rem] mb-8 text-center md:text-right">
          آموزشگاه ریاضی مهندس عطار
        </h3>
        <p className="text-[1.8rem] mb-14 text-center md:text-justify px-4 md:px-0">
          ما در این مجموعه تلاش داریم با فراهم‏ آوری فضایی مناسب، ریاضیات کنکور
          سراسری را به بهترین سیمای ممکن به شما عرضه کنیم. سال‏ ها سابقۀ کار در
          سطح اول آموزش کنکور تجربۀ ارزشمندی به ارمغان آورده است که امید داریم
          آن را فرا روی شما قرار دهیم. قبولی ‏های رشته ‏های برتر علوم ریاضی و
          تجربی در تمام این سال‏ ها و کسب قریب به 50 رتبۀ دورقمی تنها در 4 سال
          اخیر، گواه خوبی بر صدق این ادعاست. البته جای تأکید است که ما خود را
          «باعث» کسب رتبه ‏های برتر توسط دانش آموزان نمی‏دانیم؛ بلکه صرفاً تلاش
          می ‏کنیم فضایی را مهیا سازیم که هر دانش آموز به بیشترین رشد ممکن -دست‏
          کم در درس ریاضی- برسد. تجربۀ سالیان نشان داده است که خوشبختانه در این
          امر ناموفق نبوده‌ایم.
        </p>
        {/* <BtnAlternative
          text="ورود به سامانه"
          className="w-fit border-2 border-white text-white px-10 py-5"
        /> */}
      </div>
      <div className="text-black flex flex-col sm:grid grid-cols-2 grid-rows-2 overflow-visible gap-x-7 gap-y-6 md:-mb-96 w-full md:w-1/2 px-4 md:px-0 max-h-full">
        <Card className="p-6 text-right hover:bg-[#37c78e] border-4 border-[#37c78e] transition-all hover:border-white group md:hover:scale-150 hover:scale-100 duration-700">
          <div className="flex flex-col items-start justify-center h-full group-hover:h-0">
            <img src={Class} className="mb-6 group-hover:hidden" />
            <h5 className="mb-2 text-[1.6rem] font-bold text-black group-hover:hidden">
              کلاس‌های مجازی
            </h5>
            <span className="text-[1.6rem] font-light text-[#8E9BA9] leading-[2.2rem] group-hover:hidden">
              {/* شما میتوانید از تمام قابلیت های کلاس های حضوری ، بصورت مجازی بهره
            ببرند. */}
              آموزش کامل مفاهیم و پوشش تمامی سرفصل‏ های ریاضیات دبیرستان در...
            </span>
          </div>
          <span className="text-[1.2rem] font-light text-white leading-[1.6rem] hidden group-hover:inline">
            آموزش کامل مفاهیم و پوشش تمامی سرفصل‏ های ریاضیات دبیرستان در قالب
            فیلم های آفلاین و کلاس ‏های آنلاین صورت می ‏گیرد. مطالب آموزش داده
            شده هم در چهارچوب آزمون ‏های تشریحی و هم در قالب آزمون‏ های تستی
            قابل استفاده است. در تهیۀ فیلم ها تلاش می‏ شود که فضای یک کلاس حضوری
            با جزئیات هرچه بیشتر شبیه ‏سازی گردد.
          </span>
        </Card>
        <Card className="p-6 text-right hover:bg-[#bd5db8] border-4 border-[#bd5db8]  transition-all hover:border-white group md:hover:scale-150 hover:scale-100 duration-700">
          <div className="flex flex-col items-start justify-center h-full group-hover:h-0">
            <img src={Exam} className="mb-6 group-hover:hidden" />
            <h5 className="mb-2 text-[1.6rem] text-black group-hover:hidden">
              امتحانات هفتگی
            </h5>
            <span className="text-[1.6rem] font-light text-[#8E9BA9] group-hover:hidden">
              {/*  شما میتوانید از تمام قابلیت های کلاس های حضوری ، بصورت مجازی بهره
            ببرند. */}
              در پایان هر جلسه در ساعت مقرر بسته به سرفصل‏ های تدریس ‏شده در...
            </span>
          </div>
          <span className="text-[1.2rem] font-light text-white leading-[1.6rem] hidden group-hover:inline">
            در پایان هر جلسه در ساعت مقرر بسته به سرفصل‏ های تدریس ‏شده در جلسات
            قبل، 10 سؤال در قالب یک برگۀ امتحان برای دانش ‏آموزان بارگذاری می
            ‏شود. در پایان مهلت آزمون، پاسخ‏ ها تصحیح شده و پاسخنامه به ‏همراه
            درصد دانش ‏آموز برای ایشان ارسال می ‏گردد. نمرات آزمون ‌ها برای پایش
            عملکرد دانش‌ آموزان در طول سال و جهت ثبت ‌نام آنان در سال بعد مورد
            استفاده قرار خواهند گرفت.
          </span>
        </Card>
        <Card className="p-6 text-right hover:bg-[#3e7cf5] border-4 border-[#3e7cf5]  transition-all hover:border-white group md:hover:scale-150 hover:scale-100 duration-700">
          <div className="flex flex-col items-start justify-center h-full group-hover:h-0">
            <img src={Problem} className="mb-6 group-hover:hidden" />
            <h5 className="mb-2 text-[1.6rem] text-black group-hover:hidden">
              رفع اشکال رایگان
            </h5>
            <span className="text-[1.6rem] font-light text-[#8E9BA9] group-hover:hidden">
              {/* شما میتوانید از تمام قابلیت های کلاس های حضوری ، بصورت مجازی بهره
            ببرند. */}
              دانش‏ آموزان در تمام روزهای هفته می ‏توانند پرسش‏ های خود را...
            </span>
          </div>
          <span className="text-[1.3rem] font-light text-white leading-[1.6rem] hidden group-hover:inline">
            دانش‏ آموزان در تمام روزهای هفته می ‏توانند پرسش‏ های خود را با
            مسئولین رفع‏ اشکال در میان بگذارند و پاسخ خود را دریافت نمایند. به
            علاوه، امکان رفع اشکال تک نفرۀ حضوری با هماهنگی قبلی نیز وجود دارد
            تا در کنار آموزش مجازی و از راه دور، آموزش حضوری و ارتباط چهره ‏به
            ‏چهره نیز در حد امکان حفظ گردد.
          </span>
        </Card>
        <Card className="p-6 text-right hover:bg-[#d28f52] border-4 border-[#d28f52]  transition-all hover:border-white group md:hover:scale-150 hover:scale-100 duration-700">
          <div className="flex flex-col items-start justify-center h-full group-hover:h-0">
            <img
              src={Homework}
              className="mb-6 text-[1.6rem] group-hover:hidden"
            />
            <h5 className="mb-2 text-[1.6rem] text-black group-hover:hidden">
              تکالیف هفتگی
            </h5>
            <span className="text-[1.6rem] font-light text-[#8E9BA9] group-hover:hidden">
              {/* شما میتوانید از تمام قابلیت های کلاس های حضوری ، بصورت مجازی بهره
            ببرند. */}
              هر هفته در هماهنگی با سرفصل‏ های تدریس ‏شده 20 سؤال در قالب یک...
            </span>
          </div>
          <span className="text-[1.2rem] font-light text-white leading-[1.6rem] hidden group-hover:inline">
            هر هفته در هماهنگی با سرفصل‏ های تدریس ‏شده 20 سؤال در قالب یک برگۀ
            تکلیف برای دانش‏ آموزان بارگذاری می شود. در پایان مهلت ارسال تکالیف،
            سؤالات تصحیح شده و راه ‏حل ‎های درست به ‏همراه درصد دانش ‏آموز برای
            ایشان ارسال می ‏گردد. نمرات تکالیف برای ارزیابی عملکرد دانش ‌آموزان
            در مباحث گوناگون و مشخص‌کردن نقاط ضعف آنان جهت بهبود، به کار خواهند
            رفت.
          </span>
        </Card>
      </div>
    </div>
  );
};

export default Features;
